<template>
  <div class="lottery">
    <div v-for="(p,i) in lottery" :key="i" class="sec lotteryBoxDisaply" :style="lotteryBoxPosition(p)" v-text="p.nickname" />
  </div>
</template>

<script>

export default {
  name: 'LotteryResult',
  components: {},
  data() {
    return {
      id: this.$route.params.id,
      lottery: {}
    }
  },
  computed: {},
  mounted() {
    this.getData()
  },
  methods: {
    lotteryBoxPosition: function(idx) {
      var rt = 'top:' + idx.x + 'px; left:' + idx.y + 'px;'
      return rt
    },
    getData: function() {
      this.$store.dispatch('getData', { type: 'Lottery', credentials: this.id }).then((res) => {
        if (!res.errorCode) {
          var lo = []
          res.data.prizes.forEach((p) => {
            p.result.forEach((r) => {
              lo.push(r)
            })
          })
          this.lottery = lo
        }
      })
    }
  }
}
</script>

<style lang="scss">
$fontAddition: 1;
.lottery {
  position: relative;
  width: 100%;
  height: 100%;

  .lotteryBoxDisaply {
    position: absolute;
    cursor: pointer;
    color: #ddd;
    font-size: 64px*$fontAddition;
    line-height: 96px*$fontAddition;
    font-family: 'Hiragino Kaku Gothic Std';
  }
}
</style>
