<template>
  <div class="card card-user">
    <div class="card-body">
      <form>
        <div class="form-group row">
          <label class="col-md-2 col-form-label">Account</label>
          <div class="col-md-10">
            <input v-model="user.account" type="text" class="form-control">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-2 col-form-label">Password</label>
          <div class="col-md-10">
            <input v-model="user.password" type="text" class="form-control">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-2 col-form-label">Confrim Password</label>
          <div class="col-md-10">
            <input v-model="user.confrimPassword" type="text" class="form-control">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-2 col-form-label">Role</label>
          <div class="col-md-10">
            <select v-model="user.roleID" class="custom-select">
              <option v-for="(role,i) in roles" :key="i" :value="role.id" v-text="role.name" />
            </select>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-2 col-form-label">Name</label>
          <div class="col-md-10">
            <input v-model="user.name" type="text" class="form-control">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-2 col-form-label">Phone</label>
          <div class="col-md-10">
            <input v-model="user.phone" type="text" class="form-control">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-2 col-form-label">Email</label>
          <div class="col-md-10">
            <input v-model="user.email" type="text" class="form-control">
          </div>
        </div>
        <div class="row">
          <div class="update ml-auto mr-auto">
            <button
              type="submit"
              :disabled="!passwordCheck"
              :class="passwordCheck? 'btn-disabled':'btn-primary'"
              class="btn btn-primary btn-round"
              @click="submit"
            >Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserCreate',
  data: function() {
    return {
      user: {
        account: '',
        password: '',
        confrimPassword: '',
        roleID: '',
        name: '',
        phone: '',
        email: ''
      },
      roles: {}
    }
  },
  computed: {
    passwordCheck: function() {
      return (this.password === this.confrimPassword)
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    submit: function() {
      if (this.passwordCheck) {
        this.$store.commit('OPEN_LOADING')
        this.$store.dispatch('userCreate', { credentials: this.user }).then((res) => {
          if (!res.errorCode) {
            this.$store.commit('CLOSE_LOADING')
            this.$router.push({ name: 'User.List' })
          }
        })
      }
    },
    getData: function() {
      this.$store.commit('OPEN_LOADING')
      this.$store.dispatch('getList', { type: 'Role', action: 'list' }).then((res) => {
        if (!res.errorCode) {
          this.roles = res.data
          this.$store.commit('CLOSE_LOADING')
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
