import Root from '@/components/Root'

import SeasonDetail from '@/components/Season/Detail'
import LiveDetail from '@/components/Live/Detail'
import UserDetail from '@/components/User/Detail'
import TeamDetail from '@/components/Team/Detail'
import VoteDetail from '@/components/Vote/Detail'
import LotteryDetail from '@/components/Lottery/Detail'
import RegistrationDetail from '@/components/Registration/Detail'

const show = [
  {
    path: '/app',
    name: 'Detail',
    component: Root,
    children: [
      {
        path: 'season/:id',
        name: 'Season.Detail',
        component: SeasonDetail
      },
      {
        path: 'live/:sid/:id',
        name: 'Live.Detail',
        component: LiveDetail
      },
      {
        path: 'user/:id',
        name: 'User.Detail',
        component: UserDetail
      },
      {
        path: 'team/:id',
        name: 'Team.Detail',
        component: TeamDetail
      },
      {
        path: 'vote/:id',
        name: 'Vote.Detail',
        component: VoteDetail
      },
      {
        path: 'lottery/:id',
        name: 'Lottery.Detail',
        component: LotteryDetail
      },
      {
        path: 'Registration/:id',
        name: 'Registration.Detail',
        component: RegistrationDetail
      }
    ]
  }

]

export default show
