<template>
  <div class="content ">
    <div class="list">
      <div
        v-for="(seat, i) in this.$dataManager.getters.mahjong.usualUpdate.seats"
        :key="i"
        @click="addEye(seat)"
      >
        <div
          class="_row player-label"
          :class="eyePlayers.indexOf(seat.playerId) !== -1 ? 'disabled' : ''"
        >
          <div
            v-if="eyePlayers.indexOf(seat.playerId) !== -1"
            class="newSale-wrap"
          >
            <div class="content">
              <div class="newSale" :style="'background-color:#ff0000'">
                聽牌
              </div>
            </div>
          </div>
          <div
            class="name"
            v-text="seat.player.displayName || seat.player.name"
          />
          <div
            class="point"
            :class="$dataManager.pointColor(seat.hand_point)"
            v-text="
              eyePoint(seat.playerId) > 0
                ? '+' + eyePoint(seat.playerId)
                : '' + eyePoint(seat.playerId)
            "
          />
        </div>
      </div>
    </div>
    <div class="buttons">
      <div class="btn capitalize" @click="close">cancel</div>
      <div class="btn capitalize" @click="cleanHand">clean</div>
      <div class="btn upper" @click="confirm">ok</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SetDrawPanel',
  components: {},
  props: [],
  data() {
    return {
      eyePlayers: [],
      handsPoint: [],
      handsData: []
    }
  },
  computed: {
    eye() {
      const data = []
      this.$dataManager.getters.dialog.data.forEach((item) => {
        if (item.points > 0) {
          data.push(item.playerId)
        }
      })
      return data
    }
  },
  watch: {
    // eye: {
    //   handler: function(val) {
    //     this.eyePoint = val;
    //   },
    //   deep: true,
    // },
  },
  mounted() {
    this.eyePlayers = this.eye
    this.$bus._events['setPoint'] = null
    this.$bus._events['cleanHand'] = null
    this.$bus.$on('setPoint', (data) => {
      this.confirm(data)
    })

    this.$bus.$on('cleanHand', () => {
      this.cleanHand()
    })
  },
  methods: {
    close: function() {
      this.$store.commit('CLEAR_DIALOG')
    },
    addEye: function(seat) {
      const playerId = seat.playerId
      if (this.eyePlayers.indexOf(playerId) === -1) {
        this.eyePlayers.push(playerId)
      } else {
        this.eyePlayers.splice(this.eyePlayers.indexOf(playerId), 1)
      }
    },
    eyePoint: function(playerId) {
      let point = 0
      if (this.eyePlayers.indexOf(playerId) !== -1) {
        switch (this.eyePlayers.length) {
          case 1:
            point = 6
            break
          case 2:
            point = 3
            break
          case 3:
            point = 2
            break
        }
      } else {
        switch (this.eyePlayers.length) {
          case 1:
            point = -2
            break
          case 2:
            point = -3
            break
          case 3:
            point = -6
            break
        }
      }
      return point
    },
    cleanHand: function() {
      this.eyePlayers = []
      this.handsPoint = []
    },
    confirm: function() {
      const data = []
      if (this.eye.length) {
        this.$dataManager.getters.mahjong.usualUpdate.seats.forEach((seat) => {
          const temp = {
            playerId: seat.playerId,
            result: 5,
            bann: 0,
            points: this.eyePoint(seat.playerId),
            winTypes: []
          }
          data.push(temp)
        })
      }

      console.log(data)
      if (this.$dataManager.getters.dialog.data.length) {
        this.$bus.$emit('updateDraw', data)
      } else {
        this.$bus.$emit('setDraw', data)
      }
      this.$store.commit('CLEAR_DIALOG')
    }
  }
}
</script>

<style lang="scss" scoped>
.list {
  margin: 4px 4px 2rem;

  ._row {
    display: flex;
    margin: 12px auto;

    .name {
      flex: 3;
    }

    .point {
      flex: 2;
      font-weight: 700;
      color: #fff;
    }
  }
}
.player-label {
  position: relative;

  .newSale-wrap {
    position: absolute;
    overflow: hidden;
    z-index: 8;
    height: 4.885rem;
    width: 5.285rem;
    top: -0.257rem;
    left: -0.257rem;

    .content {
      position: relative;

      .newSale {
        position: absolute;
        font-weight: 700;
        padding: 0.357rem 0;
        text-align: center;
        width: 4.142rem;
        height: 0.786rem;
        top: 0.4rem;
        left: -17px;
        font-size: 0.7rem;
        line-height: 0.2rem;
        transform: rotate(-40deg);
        background-color: #fc4452;
        color: #fff;
      }
    }
  }
}
</style>
