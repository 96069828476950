<template>
  <div>
    <pieChart
      v-if="options.length>0"
      :animate="setting.animate"
      :datas="options"
      :diameter="setting.diameter"
      :pretext="setting.pretext"
      :bgcolor="setting.bgcolor"
      :min-text-degree="setting.minTextDegree"
      :display-min-text="setting.displayMinText"
      :font-size="setting.fontSize"
    />
  </div>
</template>

<script>
import pieChart from '@/components/majcmp/pieChart'

export default {
  name: 'PieChat',
  components: { pieChart },
  data() {
    return {
      id: this.$root.voteSetting.voteId,
      transparent: 'rgba(0,0,0,0)',
      vote: {
        title: '',
        options: []
      },
      interval: {},
      setting: {
        diameter: 450,
        pretext: '票數',
        bgcolor: 'rgba(0,0,0,0)',
        minTextDegree: 30,
        fontSize: 28,
        displayMinText: false,
        animate: true
      }
    }
  },
  computed: {
    options() {
      var res = []
      this.vote.options.forEach((opt, idx) => {
        var item = {}
        item.count = opt.count
        item.color = opt.color
        item.name = opt.title
        item.fontColor = '#fff'
        res.push(item)
      })
      // res = [
      //   {"name":"B", "count":536, "color":"#ff0000", "fontColor":"#3f303f"},
      //   {"name":"C", "count":242, "color":"#0000ff", "fontColor":"#3f3000"},
      //   {"name":"A", "count":36, "color":"#4f0000", "fontColor":"#afa0af"},
      //   {"name":"D", "count":1242, "color":"#00e0ff", "fontColor":"#4f5020"}
      // ]
      return res
    }
  },
  watch: {},
  mounted() {
    this.$bus.$on('voteSetting', content => {
      setTimeout(() => {
        this.dataInterval(content.voteId)
      }, 300)
    })
  },
  methods: {
    dataInterval: function(id) {
      clearInterval(this.interval)
      this.getData(id)
      var $this = this
      this.interval = setInterval(() => {
        $this.getData(id)
      }, 10 * 1000)
    },
    getData: function(id) {
      this.$store.dispatch('getData', { type: 'Vote', credentials: id }).then((res) => {
        if (!res.errorCode) {
          this.vote = res.data
        }
      })
    }
  }
}
</script>
<style lang="scss">

text {
  fill: #fff;
  font-family: 'Microsoft JhengHei' !important;
}

.title {
  width: 450px;
  text-align: center;
  color: #ffffff;
  font-family: '蒙納盈富體';
  font-weight: 900;
  margin-top: -40px;
}
</style>
