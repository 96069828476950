import Axios from './_axios'
export default {

  winningTypeCreate(getters, { credentials }) {
    const formdata = new FormData()

    formdata.append('gtId', credentials.gtId)
    formdata.append('name', credentials.name)
    formdata.append('bann', credentials.bann)
    formdata.append('point', credentials.point)
    formdata.append('sort', credentials.sort)
    formdata.append('isSpecial', credentials.isSpecial)
    formdata.append('isMulti', credentials.isMulti)

    const url = getters.apiUrl + 'WinningType/create'
    return Axios.post(
      url, formdata
    ).then(res => {
      return res
    })
  },

  winningTypeUpdate(getters, { credentials }) {
    const formdata = new FormData()

    formdata.append('gtId', credentials.winningType.gtId)
    formdata.append('name', credentials.winningType.name)
    formdata.append('bann', credentials.winningType.bann)
    formdata.append('point', credentials.winningType.point)
    formdata.append('sort', credentials.winningType.sort)
    formdata.append('isSpecial', credentials.winningType.isSpecial)
    formdata.append('isMulti', credentials.winningType.isMulti)
    const url = getters.apiUrl + 'WinningType/update/' + credentials.id
    return Axios.post(
      url, formdata
    ).then(res => {
      return res
    })
  },
  winningTypeListByGameType(getters, { credentials }) {
    const url = getters.apiUrl + '/WinningType/listByGtId/' + credentials
    return Axios.post(
      url
    ).then(res => {
      return res
    })
  }
}

