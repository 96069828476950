<template>
  <div class="content">
    <div
      v-for="(team, i) in $dataManager.getters.mahjong.usualUpdate.teams"
      :key="i"
      class="team"
    >
      <p v-text="team.name" />
      <div class="players">
        <div
          v-for="(player, p) in team.players"
          :key="p"
          class="player-label"
          :class="thisPlayer.id === player.id ? 'active' : ''"
          @click="choosePlayer(team, player)"
          v-text="player.displayName || player.name"
        />
      </div>
    </div>
    <div class="buttons">
      <div class="btn capitalize" @click="close">cancel</div>
      <div class="btn upper" @click="comfrim">ok</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SetPLayer',
  components: {},
  data() {
    return {
      thisTeam: {},
      thisPlayer: {
        playerId: null
      }
    }
  },
  computed: {
    playerData() {
      return 0
    }
  },
  watch: {},
  methods: {
    close: function() {
      this.$store.commit('CLEAR_DIALOG')
    },
    comfrim: function() {
      var data = {
        team: this.thisTeam,
        player: this.thisPlayer
      }
      this.$store.commit('UPDATE_SEAT_PLAYER', data)
      this.$store.commit('CLEAR_DIALOG')
    },
    choosePlayer: function(team, player) {
      this.thisTeam = team
      this.thisPlayer = player
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/layout/setPlayerPopup";
</style>
