import store from '@/store'
// import lib from './../lib.esm';
export default {
  setLogin(res) {
    var p = {
      Role: [],
      User: [],
      GameType: [],
      WinningType: [],
      Team: [],
      Player: [],
      Season: [],
      Live: [],
      Hand: [],
      Vote: [],
      Lottery: []
    }
    store.commit('UPDATE_PERMISSION', Object.assign(p, res.data.permission))
    const auth = {}
    auth.sid = res.session_id
    auth.name = res.data.name
    auth.roleID = res.data.roleID
    auth.phone = res.data.phone
    auth.email = res.data.email
    store.commit('UPDATE_AUTH', auth)
  }
}

