<template>
  <div class="season-schedule">
    <div class="main">
      <div class="title">第二階段例行賽賽程</div>
      <div class="sub-title">SECOND ROUND</div>
      <div class="tables">
        <div class="table">
          <table>
            <thead>
              <tr>
                <th>場次</th>
                <th>日期</th>
                <th colspan="4">出賽隊伍</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>
                  <span>08/04</span>
                  <span>WED.</span>
                </td>
                <td>
                  <div class="teams">
                    <div class="team-label a">A</div>
                    <div class="team-label d">D</div>
                    <div class="team-label b">B</div>
                    <div class="team-label g">G</div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>
                  <span>08/07</span>
                  <span>SAT.</span>
                </td>
                <td>
                  <div class="teams">
                    <div class="team-label h">h</div>
                    <div class="team-label b">B</div>
                    <div class="team-label g">g</div>
                    <div class="team-label c">c</div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td>
                  <span>08/11</span>
                  <span>WED.</span>
                </td>
                <td class="teams">
                  <div class="teams">
                    <div class="team-label d">d</div>
                    <div class="team-label h">h</div>
                    <div class="team-label g">g</div>
                    <div class="team-label c">c</div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>4</td>
                <td>
                  <span>08/14</span>
                  <span>SAT.</span>
                </td>
                <td>
                  <div class="teams">
                    <div class="team-label a">a</div>
                    <div class="team-label d">d</div>
                    <div class="team-label h">h</div>
                    <div class="team-label g">g</div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>5</td>
                <td>
                  <span>08/18</span>
                  <span>WED.</span>
                </td>
                <td>
                  <div class="teams">
                    <div class="team-label d">d</div>
                    <div class="team-label h">h</div>
                    <div class="team-label b">b</div>
                    <div class="team-label c">c</div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>6</td>
                <td>
                  <span>08/21</span>
                  <span>SAT.</span>
                </td>
                <td>
                  <div class="teams">
                    <div class="team-label a">a</div>
                    <div class="team-label d">d</div>
                    <div class="team-label g">g</div>
                    <div class="team-label c">c</div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>7</td>
                <td>
                  <span>08/25</span>
                  <span>WED.</span>
                </td>
                <td>
                  <div class="teams">
                    <div class="team-label a">a</div>
                    <div class="team-label d">d</div>
                    <div class="team-label h">h</div>
                    <div class="team-label b">b</div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>8</td>
                <td>
                  <span>08/28</span>
                  <span>SAT.</span>
                </td>
                <td>
                  <div class="teams">
                    <div class="team-label a">a</div>
                    <div class="team-label h">h</div>
                    <div class="team-label g">g</div>
                    <div class="team-label c">c</div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="table">
          <table>
            <thead>
              <tr>
                <th>場次</th>
                <th>日期</th>
                <th colspan="4">出賽隊伍</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>9</td>
                <td>
                  <span>09/01</span>
                  <span>WED.</span>
                </td>
                <td>
                  <div class="teams">
                    <div class="team-label a">a</div>
                    <div class="team-label d">d</div>
                    <div class="team-label b">b</div>
                    <div class="team-label c">c</div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>10</td>
                <td>
                  <span>09/04</span>
                  <span>SAT.</span>
                </td>
                <td>
                  <div class="teams">
                    <div class="team-label d">d</div>
                    <div class="team-label b">b</div>
                    <div class="team-label g">g</div>
                    <div class="team-label c">c</div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>11</td>
                <td>
                  <span>09/08</span>
                  <span>WED.</span>
                </td>
                <td>
                  <div class="teams">
                    <div class="team-label a">a</div>
                    <div class="team-label d">d</div>
                    <div class="team-label h">h</div>
                    <div class="team-label c">c</div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>12</td>
                <td>
                  <span>09/15</span>
                  <span>WED.</span>
                </td>
                <td>
                  <div class="teams">
                    <div class="team-label a">a</div>
                    <div class="team-label h">h</div>
                    <div class="team-label b">b</div>
                    <div class="team-label g">g</div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>13</td>
                <td>
                  <span>09/25</span>
                  <span>SAT.</span>
                </td>
                <td>
                  <div class="teams">
                    <div class="team-label a">a</div>
                    <div class="team-label b">b</div>
                    <div class="team-label g">g</div>
                    <div class="team-label c">c</div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>14</td>
                <td>
                  <span>10/02</span>
                  <span>SAT.</span>
                </td>
                <td>
                  <div class="teams">
                    <div class="team-label d">d</div>
                    <div class="team-label h">h</div>
                    <div class="team-label b">b</div>
                    <div class="team-label g">g</div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>15</td>
                <td>
                  <span>10/09</span>
                  <span>SAT.</span>
                </td>
                <td>
                  <div class="teams">
                    <div class="team-label a">a</div>
                    <div class="team-label h">h</div>
                    <div class="team-label b">b</div>
                    <div class="team-label c">c</div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'SeasonSchedule',
  data() {
    return {
      season: {},
      teams: [],
      n: {
        first: {
          displayName: 'A',
          color: '#ff8212'
        },
        second: {
          displayName: 'H',
          color: '#afafaf'
        },
        third: {
          displayName: 'C',
          color: '#f422e0'
        }
      },
      isLoading: false,
      liveSetting: {}
    }
  },
  computed: {
    first: function() {
      var res = {}
      this.teams.forEach((team) => {
        if (team.teamRank === 1) {
          res = team
        }
      })
      return res
    },
    second: function() {
      var res = {}
      this.teams.forEach((team) => {
        if (team.teamRank === 2) {
          res = team
        }
      })
      return res
    },
    third: function() {
      var res = {}
      this.teams.forEach((team) => {
        if (team.teamRank === 3) {
          res = team
        }
      })
      return res
    }
  },
  mounted() {
    this.$bus.$on('liveSetting', content => {
      this.liveSetting = content
      this.getData()
    })
  },
  methods: {
    getData: function() {
      this.$store.commit('CLOSE_LOADING')
      this.$store.dispatch('getData', { type: 'Season', credentials: this.liveSetting.seasonId }).then((res) => {
        if (!res.errorCode) {
          this.season = res.data
          this.teams = res.data.teams
          this.teams.sort(function(a, b) {
            return a.teamRank - b.teamRank
          })
          this.$store.commit('UPDATE_TEAM', res.data.teams)
          this.isLoading = true
        }
      })
    }
  }
}
</script>

<style lang="scss">
  @import '../../../style/show/seasonSchedule';
</style>
