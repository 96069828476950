<template>
  <div class="wrapper">
    <router-view style="width: 100%;" />
  </div>
</template>

<script>
export default {
  name: 'ShowRoot',
  created() {
    if (localStorage.getItem('store')) {
      this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(localStorage.getItem('store'))))
    } else {
      localStorage.setItem('store', JSON.stringify(this.$store.state))
    }
    if (!this.$bus._events['saveStore']) {
      this.$bus.$on('saveStore', () => {
        localStorage.setItem('store', JSON.stringify(this.$store.state))
      })
    }
  }
}
</script>

<style lang="scss">
body {
  background-color: rgba(0, 0, 0, 0) !important;
}
</style>
