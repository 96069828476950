<template>
  <div class="col-md-12">
    <div class="card">
      <div class="card-body">
        <div class="table">
          <table class="table">
            <thead class=" text-primary">
              <tr>
                <th>Live</th>
                <th>Point</th>
                <th>Experience</th>
                <th>#</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item,i) in statistics" :key="i">
                <td v-text="item.title" />
                <td v-text="item.point" />
                <td v-text="item.experience" />
                <td>
                  <router-link :to="{name:'Viewer.PointLog',params:{authorChannelId:authorChannelId,liveChatId:item.liveChatId}}">
                    <div class="btn btn-xs btn-primary  pull-right">Detail</div>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Statistics',
  data: function() {
    return {
      authorChannelId: this.$route.params.authorChannelId,
      statistics: []
    }
  },
  computed: {},
  mounted() {
    this.getData()
  },
  methods: {
    getData: function() {
      this.$store.commit('OPEN_LOADING')
      this.$store.dispatch('viewerStatistics', { credentials: this.authorChannelId }).then((res) => {
        if (!res.errorCode) {
          this.statistics = res.data
          this.$store.commit('CLOSE_LOADING')
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
