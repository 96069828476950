<template>
  <div class="showStr" v-html="showStr" />
</template>

<script>
export default {
  name: 'LiveStr',
  data() {
    return {
      showStr: ''
    }
  },
  computed: {},
  watch: {},
  mounted() {
    const $this = this
    this.$bus.$on('liveStrSetting', (content) => {
      setTimeout(() => {
        $this.showStr = content
      }, 300)
    })
  },
  methods: {}
}
</script>

<style lang="scss">
.showStr {
  padding: 2rem;
  text-align: center;
  font-size: 2.25rem;
  line-height: 46px;
  color: $color-white;
  text-shadow: #4f4b4b 0.1em 0.1em 0.2em;
  font-weight: 500;
  font-family:  'Noto Sans TC';
}
</style>
