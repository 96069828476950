<template>

  <div class="card card-user">
    <div class="card-body">
      <form>
        <div class="form-group row">
          <label class="col-md-2 col-form-label">遊戲類型</label>
          <div class="col-md-10">
            <select v-model="winningType.gtId" class="custom-select">
              <option v-for="(item,i) in gameTypes" :key="i" :value="item.id" v-text="item.name" />
            </select>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-2 col-form-label">名稱</label>
          <div class="col-md-10">
            <input v-model="winningType.name" type="text" class="form-control">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-2 col-form-label">台數</label>
          <div class="col-md-10">
            <input v-model="winningType.bann" type="text" class="form-control">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-2 col-form-label">點數</label>
          <div class="col-md-10">
            <input v-model="winningType.point" type="text" class="form-control">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-2 col-form-label">多選</label>
          <div class="col-md-10">
            <div class="form-check form-check-inline">
              <input id="isMultiTrue" v-model="winningType.isMulti" class="form-check-input" type="radio" :value="1">
              <label class=" form-check-label" for="isMultiTrue">True</label>
            </div>
            <div class="form-check form-check-inline">
              <input id="isMultiFalse" v-model="winningType.isMulti" class="form-check-input" type="radio" :value="0">
              <label class=" form-check-label" for="isMultiFalse">False</label>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-2 col-form-label">特殊</label>
          <div class="col-md-10">
            <div class="form-check form-check-inline">
              <input
                id="isSpecialTrue"
                v-model="winningType.isSpecial"
                class="form-check-input"
                type="radio"
                :value="1"
              >
              <label class=" form-check-label" for="isSpecialTrue">True</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                id="isSpecialFalse"
                v-model="winningType.isSpecial"
                class="form-check-input"
                type="radio"
                :value="0"
              >
              <label class=" form-check-label" for="isSpecialFalse">False</label>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-2 col-form-label">排序</label>
          <div class="col-md-10">
            <input v-model="winningType.sort" type="text" class="form-control">
          </div>
        </div>
        <div class="row">
          <div class="update ml-auto mr-auto">
            <button type="submit" class="btn btn-primary btn-round" @click="submit">送出</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WinningTypeUpdate',
  data: function() {
    return {
      id: this.$route.params.id,
      gameTypes: [],
      winningType: {
        gtId: '',
        name: '',
        bann: '',
        point: '',
        sort: '',
        isSpecial: '',
        isMulti: ''
      }
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData: function() {
      this.$store.commit('OPEN_LOADING')
      this.$store.dispatch('getList', { type: 'GameType', action: 'list' }).then((res) => {
        if (!res.errorCode) {
          this.gameTypes = res.data
          this.$store.dispatch('getData', { type: 'WinningType', credentials: this.id }).then((res) => {
            if (!res.errorCode) {
              this.winningType = res.data
              this.$store.commit('CLOSE_LOADING')
            }
          })
        }
      })
    },

    submit: function() {
      this.$store.commit('OPEN_LOADING')
      var data = {
        id: this.id,
        winningType: this.winningType
      }
      this.$store.dispatch('winningTypeUpdate', { credentials: data }).then((res) => {
        if (!res.errorCode) {
          this.$store.commit('CLOSE_LOADING')
          this.$router.push({ name: 'WinningType.List' })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
