<template>
  <div class="content">
    <div class="form-group">
      <label class="col-md-12 col-form-label">new password</label>
      <div class="col-md-8">
        <input v-model="password" type="text" class="form-control" :class="[passwordCheck ? '':'is-invalid']">
      </div>
    </div>
    <div class="form-group ">
      <label class="col-md-12 col-form-label">confirm password</label>
      <div class="col-md-8">
        <input v-model="confrimPassword" type="text" class="form-control" :class="[passwordCheck ? '':'is-invalid']">
      </div>
    </div>
    <div class="buttons">
      <div class="btn capitalize" @click="close">cancel</div>
      <div class="btn upper" @click="submitPass">ok</div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'ChangePassword',
  data: function() {
    return {
      id: this.$route.params.id,
      password: '',
      confrimPassword: ''
    }
  },
  computed: {
    passwordCheck: function() {
      return (this.password.length > 5 && (this.password === this.confrimPassword))
    }
  },
  methods: {
    close: function() {
      this.$store.commit('CLEAR_DIALOG')
    },
    submitPass: function() {
      if (this.passwordCheck) {
        this.$store.commit('OPEN_LOADING')
        var data = {
          id: this.id,
          password: this.password
        }
        this.$store.dispatch('passwordUpdate', { credentials: data }).then((res) => {
          if (!res.errorCode) {
            this.$store.commit('CLOSE_LOADING')
            this.$store.commit('CLEAR_DIALOG')
            this.$router.push({ name: 'User.List' })
          }
        })
      } else {
        this.passworddif = true
      }
    }
  }
}
</script>

<style scoped>

</style>
