import Axios from './_axios'

export default {
  liveDelete(getters, { credentials }) {
    const url = getters.apiUrl + 'LiveShow/delete/' + credentials
    return Axios.post(
      url
    ).then(res => {
      return res
    })
  },

  liveCreate(getters, { credentials }) {
    const formdata = new FormData()
    formdata.append('name', credentials.liveShow.name)
    formdata.append('type', credentials.liveShow.type)
    formdata.append('startDatetime', credentials.liveShow.startDatetime)

    const url = getters.apiUrl + 'LiveShow/create/' + credentials.seasonId
    return Axios.post(
      url, formdata
    ).then(res => {
      return res
    })
  },
  liveEnd(getters, { credentials }) {
    const url = getters.apiUrl + 'LiveShow/end/' + credentials
    return Axios.get(
      url
    ).then(res => {
      return res
    })
  },

  gameCreate(getters, { credentials }) {
    const formdata = new FormData()
    formdata.append('e', JSON.stringify(credentials.game.position.e))
    formdata.append('s', JSON.stringify(credentials.game.position.s))
    formdata.append('w', JSON.stringify(credentials.game.position.w))
    formdata.append('n', JSON.stringify(credentials.game.position.n))

    const url = getters.apiUrl + 'Game/create/' + credentials.liveId
    return Axios.post(
      url, formdata
    ).then(res => {
      return res
    })
  },

  roundCreate(getters, { credentials }) {
    const formdata = new FormData()
    formdata.append('dealerWind', credentials.round.dealerWind)
    formdata.append('prevailingWind', credentials.round.prevailingWind)

    const url = getters.apiUrl + 'Round/create/' + credentials.gameId
    return Axios.post(
      url, formdata
    ).then(res => {
      return res
    })
  },
  handCreate(getters, { credentials }) {
    const formdata = new FormData()
    formdata.append('result', credentials.hand.result)
    formdata.append('dealerId', credentials.hand.dealerId)
    formdata.append('isDouble', credentials.hand.comboMode)
    formdata.append('combo', credentials.hand.combo)
    formdata.append('winCard', credentials.winCard)
    formdata.append('riichis', JSON.stringify(credentials.hand.riichis))
    formdata.append('handPoints', JSON.stringify(credentials.hand.handPoints))

    const url = getters.apiUrl + 'Hand/create/' + credentials.roundId
    return Axios.post(
      url, formdata
    ).then(res => {
      return res
    })
  },
  handUpdate(getters, { credentials }) {
    const formdata = new FormData()
    formdata.append('reset', credentials.reset)
    formdata.append('result', credentials.result)
    formdata.append('dealerId', credentials.dealerId)
    formdata.append('combo', credentials.combo)
    formdata.append('riichis', '[]')
    formdata.append('handPoints', JSON.stringify(credentials.handPoints))

    const url = getters.apiUrl + 'Hand/update/' + credentials.handid
    return Axios.post(
      url, formdata
    ).then(res => {
      return res
    })
  },
  liveDetail(getters, { credentials }) {
    const url = getters.apiUrl + 'LiveShow/getDetail/' + credentials
    return Axios.post(
      url
    ).then(res => {
      return res
    })
  },

  getLastSeasonChampion(getters, { credentials }) {
    const url = getters.apiUrl + 'Player/getLastSeasonChampion/' + credentials
    return Axios.post(
      url
    ).then(res => {
      return res
    })
  },

  getTeamByLiveShow(getters, { credentials }) {
    const url = getters.apiUrl + 'Player/getTeamByLiveShow/' + credentials
    return Axios.post(
      url
    ).then(res => {
      return res
    })
  },

  getTeamBySeason(getters, { credentials }) {
    const url = getters.apiUrl + 'Player/getTeamBySeason/' + credentials
    return Axios.post(
      url
    ).then(res => {
      return res
    })
  }

}
