<template>
  <div class="col-md-12">
    <div class="card">
      <el-table v-loading="loading" :data="season" style="width: 100%">
        <el-table-column prop="gType" width="130" label="#" />
        <el-table-column prop="TypeText" width="130" label="類型" />
        <el-table-column prop="name" label="賽季名稱" />
        <el-table-column label="Detail" width="100">
          <template slot-scope="scope">
            <router-link :to="{name:'Season.Detail',params:{id:scope.row.id}}">

              <el-button
                type="success"
                icon="el-icon-arrow-right"
                circle
              />
            </router-link>

          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        v-if="total && total>pageSize"
        :page-size="pageSize"
        :pager-count="11"
        :current-page="page"
        layout="prev, pager, next"
        :total="total"
        @current-change="getData"
      />
    </div>
  </div>
</template>

<script>

export default {
  name: 'SeasonList',
  data: function() {
    return {
      loading: true,
      page: 1,
      pageSize: 20,
      total: 0,
      gameType: [],
      season: [],
      type: [
        { key: 1, name: '日間直播' },
        { key: 2, name: '一般比賽' },
        { key: 3, name: '大型季賽' },
        { key: 4, name: '日間美麻' }
      ]
    }
  },
  computed: {},
  mounted() {
    this.getData()
  },
  methods: {
    getData: function() {
      this.loading = true

      var $this = this
      this.$store.dispatch('getList', { type: 'GameType', action: 'list' }).then((res) => {
        if (!res.errorCode) {
          this.gameType = res.data
          this.$store.dispatch('getList', { type: 'Season', action: 'list' }).then((res) => {
            if (!res.errorCode) {
              this.season = res.data
              $this.gameType.forEach((gitem) => {
                $this.season.forEach((sitem) => {
                  if (gitem.id === sitem.gtId) {
                    sitem.gType = gitem.name
                  }
                })
              })
              $this.season.forEach((sitem) => {
                $this.type.forEach((item) => {
                  if (item.key === sitem.type) {
                    sitem.TypeText = item.name
                  }
                })
              })
              this.loading = false
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
