<template>

  <div class="card card-user">
    <div class="card-body">
      <router-link
        v-if="permissions.User.includes('update')"
        :to="{name:'User.Update',params:{id:id}}"
      >
        <div class="btn btn-xs btn-primary  pull-right">Update</div>
      </router-link>
      <h5>使用者資訊</h5>
      <table class="table">
        <tr>
          <td>帳號</td>
          <td v-text="user.account" />
        </tr>
        <tr v-if="user.role">
          <td>等級</td>
          <td v-text="user.role.name" />
        </tr>
        <tr>
          <td>名字</td>
          <td v-text="user.name" />
        </tr>
        <tr>
          <td>連絡電話</td>
          <td v-text="user.phone" />
        </tr>
        <tr>
          <td>信箱</td>
          <td v-text="user.email" />
        </tr>
      </table>
      <h5>連動帳號</h5>
      <table v-if="user.players" class="table">
        <tr v-for="(item,i) in user.players" :key="i">
          <td v-text="item.name" />
        </tr>
      </table>
      <router-link
        v-if="permissions.Player.includes('create')"
        :to="{name:'Player.Create',params:{userId:id}}"
      >
        <div class="btn btn-xs btn-primary btn-round">新增選手</div>
      </router-link>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'UserDetail',
  data: function() {
    return {
      id: this.$route.params.id,
      user: {
        account: '',
        roleID: '',
        name: '',
        phone: '',
        email: ''
      },
      password: '',
      confirmpassword: '',
      passworddif: false,
      roles: {},
      dialogVisible: false
    }
  },
  computed: {
    ...mapGetters([
      'permissions'
    ])
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData: function() {
      var $this = this
      this.$store.commit('OPEN_LOADING')
      this.$store.dispatch('getList', { type: 'Role', action: 'list' }).then((res) => {
        if (!res.errorCode) {
          this.roles = res.data
          this.$store.dispatch('getData', { type: 'User', credentials: this.id }).then((res) => {
            if (!res.errorCode) {
              this.user = res.data
              this.roles.forEach((item) => {
                if (item.id === $this.user.roleID) {
                  $this.user.role = item
                }
              })
              this.$store.commit('CLOSE_LOADING')
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
