<template>
  <div v-if="nowId" class="userPost">
    <!-- :style="{backgroundImage: 'url(' + VIP + ')'}"-->
    <!-- <transition name="slide-fade"> -->
    <div v-if="post.id" class="content">
      <span :class="post.data.q2=='男生'?'boy':'girl'">{{ post.data.q1 }} {{ post.data.q3 }}</span> 想對 <span
        :class="post.data.q6=='男生'?'boy':'girl'"
      >{{ post.data.q4 }} {{ post.data.q5 }}</span> 說:<br>
      <span class="text">{{ post.data.q7 }}</span>
      <span class="postID">#{{ post.id }}</span>
    </div>

    <!-- </transition> -->
  </div>
</template>

<script>

export default {
  name: 'UserPostResult',
  data() {
    return {
      playing: false,
      replay: false,
      nowId: 0,
      lastId: 0,
      post: {}
    }
  },
  computed: {
  },
  watch: {
    nowId(val) {
      if (val) {
        this.getDetail()
      }
    }
  },
  mounted() {
    const $this = this
    this.$bus.$on('userPostSetting', content => {
      setTimeout(() => {
        $this.playing = content.playing
        $this.replay = content.replay
        $this.nowId = content.nowId
        $this.lastId = content.lastId
      }, 300)
    })
  },
  methods: {
    getDetail: function() {
      if (this.nowId) {
        this.$store.dispatch('getData', { type: 'UserPost', credentials: this.nowId }).then((res) => {
          if (!res.errorCode) {
            this.post = res.data
          }
        })
      }
    },
    setAni: function() {
      var $this = this

      $this.pointChangeTimeOut = setTimeout(function() {
        $this.pointChange = false
        $this.leaderBoardChangeTrueTimeOut = setTimeout(function() {
          $this.leaderBoardChange = true
          $this.leaderBoardChangeFalseTimeOut = setTimeout(function() {
            $this.leaderBoardChange = false
            $this.detailChangeTrueTimeOut = setTimeout(function() {
              $this.detailChange = true
              $this.detailChangeFalseTimeOut = setTimeout(function() {
                $this.detailChange = false
              }, 30 * 1000)
            }, 5000)
          }, 30 * 1000)
        }, 2000)
      }, 3000)
    },
    getRandom: function(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min
    }
  }
}
</script>

<style lang="scss">
.userPost {
  width: 100%;
  height: 100%;
  font-family: 新細明體;

  .content {
    position: relative;
    --border-width: 1px;
    margin: calc(var(--border-width)*6);
    height: 225px;
    width: 900px;
    padding: 0.5rem;
    font-size: 2.7rem;
    color: #a3e6ff;
    background-color: #335277;
    border: var(--border-width) solid #a5e8db;

    &::before {
      position: absolute;
      content: "";
      top: calc(-6 * var(--border-width));
      left: calc(-6 * var(--border-width));
      z-index: -1;
      width: calc(100% + var(--border-width) * 12);
      height: calc(100% + var(--border-width) * 12);
      background: linear-gradient(60deg,
          hsl(224, 85%, 66%),
          hsl(269, 85%, 66%),
          hsl(314, 85%, 66%),
          hsl(359, 85%, 66%),
          hsl(44, 85%, 66%),
          hsl(89, 85%, 66%),
          hsl(134, 85%, 66%),
          hsl(179, 85%, 66%));
      background-size: 300% 300%;
      background-position: 0 50%;
      // border-radius: calc(2 * var(--border-width));
      animation: moveGradient 2s alternate infinite;
    }

    span {
      padding: 0 .5rem;

      &.boy {
        color: #00a2ff
      }

      &.girl {
        color: #ff8cf3
      }
    }
    .postID{
      position: absolute;
      bottom: 0;
      right: 0;
    font-size: 2rem;

    }
  }
}

@keyframes moveGradient {
  0% {
    background-position: 0% 0%;
  }

  25% {
    background-position: 100% 50%;
  }

  50% {
    background-position: 0% 0%;
  }

  75% {
    background-position: 50% 100%;
  }

  100% {
    background-position: 0% 0%;
  }
}
</style>
