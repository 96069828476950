<template>
  <div>
    <columnChart :width="800" :height="500" :cfgs="configs" :xtags="xtags" :bgcolor="transparent" :line-color="'#000'" :datas="datas" />
    <div>
      <ul class="items">
        <li v-for="(player,i) in datas" :key="i" :style="'color:'+player.color" v-text="$dataManager.findPlayer(player.playerId).player.name" />
      </ul>
    </div>
  </div>
</template>

<script>
import columnChart from '@/components/majcmp/columnChart'

export default {
  name: 'PlayerSeasonPointHistory',
  components: { columnChart },
  data() {
    return {
      seasonId: this.$route.params.seasonId,
      liveShowId: this.$route.params.liveshowId,
      transparent: 'rgba(0,0,0,0)',
      history: [],
      configs: {
        'axisColor': '#a9a9a9', 'gridColor': '#c2c2c2',
        'dotSize': 0, 'lineWidth': 0,
        'fontSize': 12, 'fontColor': '#000000',
        'showAxisX': false, 'showAxisY': false,
        'showAxisXGrid': false, 'showAxisYGrid': false,
        'showAxisTop': false, 'showAxisRight': false,
        'showYNumber': true, 'showXTag': true,
        'maxXNumber': 5
      },
      colorList: [
        {
          line: 'rgba(255, 169, 16,1)'
        },
        {
          line: 'rgba(2, 103, 189,1)'
        },
        {
          line: 'rgba(114, 45, 224,1)'
        },
        {
          line: 'rgba(245, 34, 3,1)'
        }
      ]
    }
  },
  computed: {
    datas() {
      var res = []
      if (this.history.length > 0) {
        this.history.forEach((row, idx) => {
          var data = []
          var item = {}
          item.playerId = row.playerId
          item.color = this.colorList[idx].line
          this.xtags.forEach((d) => {
            var played = false
            row.list.forEach((i) => {
              var date = new Date(i.createdTime).getDate()
              if (d === date) {
                played = true
                data.push(i.points)
              }
            })
            if (!played) {
              data.push('')
            }
          })
          item.data = data
          res.push(item)
        })
      }
      return res
    },
    xtags() {
      var res = []
      if (this.history.length > 0) {
        var year = new Date(this.history[0].list[0].createdTime).getFullYear()
        var month = new Date(this.history[0].list[0].createdTime).getMonth() + 1
        var date = new Date(year, month, 0).getDate()
        for (var i = 1; i <= date; i++) {
          res.push(i)
        }
      }
      return res
    }
  },
  watch: {},
  mounted() {
    this.getData()
    this.$dataManager.getSeasonData(this.seasonId)
  },
  methods: {
    dataInterval: function(id) {
      clearInterval(this.interval)
      this.getData(id)
      var $this = this
      setInterval(() => {
        $this.getData(id)
      }, 10 * 1010)
    },
    getData: function() {
      var credentials = {
        seasonId: this.seasonId,
        liveShowId: this.liveShowId
      }
      this.$store.dispatch('getPointByShow', { credentials }).then((res) => {
        if (!res.errorCode) {
          this.history = res.data
        }
      })
    },
    getRandom: function(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min
    }
  }
}
</script>

<style lang="scss">
.items{
  display: flex;
  list-style: none;
  width: 800px;
  li{
    flex: 1;
    font-size: 28px;
    font-weight: 700;
  }
}
</style>
