<template>
  <div v-if="$dataManager.getters.dialog.visible" class="overlay fixed" @click.self="close()">
    <div class="dialog">
      <div :class="$dataManager.getters.dialog.type !== 'liveDetail'?'main':'detail'">
        <div class="header">
          <span class="upper" v-text="$dataManager.getters.dialog.title" />
        </div>
        <div class="body">
          <changePassword v-if="$dataManager.getters.dialog.type === 'ChangePassword'" />
          <setDraw v-if="$dataManager.getters.dialog.type === 'setDraw'" />
          <setPlayer v-if="$dataManager.getters.dialog.type === 'setPLayer'" />
          <SetPoint v-if="$dataManager.getters.dialog.type === 'setPoint'" />
          <setWin v-if="$dataManager.getters.dialog.type === 'setWin'" />
          <setCard v-if="$dataManager.getters.dialog.type === 'setCard'" :ok-btn="true" />
          <liveDetail v-if="$dataManager.getters.dialog.type === 'liveDetail'" />
          <forceStatus v-if="$dataManager.getters.dialog.type === 'forceStatus'" />
        </div>
      </div>

      <div class="icon-close-div" @click="close()">X
      </div>
    </div>
  </div>
</template>

<script>
import changePassword from '@/components/Layout/Dialog/ChangePassword'
import setPlayer from '@/components/Layout/Dialog/SetPlayer'
import SetDraw from '@/components/Layout/Dialog/SetDraw'
import SetPoint from '@/components/Layout/Dialog/SetPoint'
import setWin from '@/components/Layout/Dialog/setWin'
import setCard from '@/components/Layout/Dialog/setCards'
import liveDetail from '@/components/Layout/Dialog/liveDetail'
import forceStatus from '@/components/Layout/Dialog/forceStatus'

export default {
  name: 'Vdialog',
  components: { changePassword, SetDraw, setPlayer, SetPoint, setWin, setCard, liveDetail, forceStatus },
  data: function() {
    return {
      d: this.$dataManager.getters.dialog
    }
  },
  computed: {},
  methods: {
    close: function() {
      this.$store.commit('CLEAR_DIALOG')
    },
    comfrim: function() {
      this.$store.commit('CLEAR_DIALOG')
    }
  }

}
</script>

<style lang="scss">
@import '../../style/layout/dialog';
</style>
