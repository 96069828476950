import Axios from './_axios'

export default {
  register(getters, { credentials }) {
    const formdata = new FormData()
    formdata.append('account', credentials.account)
    formdata.append('password', credentials.password)
    formdata.append('name', credentials.name)
    formdata.append('phone', credentials.phone)
    formdata.append('email', credentials.email)

    const url = getters.apiUrl + 'Register'
    return Axios.post(
      url,
      formdata
    ).then(res => {
      return res
    })
  },

  GoogleOAuth(getters) {
    const url = getters.apiUrl + 'GoogleOAuth/url'
    return Axios.post(
      url
    ).then(res => {
      return res
    })
  },

  YoutubeInfo(getters) {
    const url = getters.apiUrl + 'Youtube/getStreamerstatus'
    return Axios.post(
      url
    ).then(res => {
      return res
    })
  },

  YoutubeSet(getters, { credentials }) {
    const formdata = new FormData()
    formdata.append('IsOpen', credentials)
    const url = getters.apiUrl + 'Youtube/setStreamerStatus'
    return Axios.post(
      url,
      formdata
    ).then(res => {
      return res
    })
  },

  login(getters, { credentials }) {
    const formdata = new FormData()
    formdata.append('account', credentials.account)
    formdata.append('password', credentials.password)

    const url = getters.apiUrl + 'Login/check'
    return Axios.post(
      url,
      formdata
    ).then(res => {
      return res
    })
  },
  logout(getters) {
    const url = getters.apiUrl + 'Login/logout'
    return Axios.post(
      url
    ).then(res => {
      localStorage.removeItem('store')
      return res
    })
  },
  isLogin(getters) {
    const url = getters.apiUrl + 'Login/isLogin'
    return Axios.post(
      url, { withCredentials: true }
    ).then(res => {
      return res
    })
  },
  userCreate(getters, { credentials }) {
    const formdata = new FormData()
    formdata.append('account', credentials.account)
    formdata.append('password', credentials.password)
    formdata.append('roleID', credentials.roleID)
    formdata.append('name', credentials.name)
    formdata.append('phone', credentials.phone)
    formdata.append('email', credentials.email)

    const url = getters.apiUrl + 'user/create'
    return Axios.post(
      url,
      formdata
    ).then(res => {
      return res
    })
  },
  userUpdate(getters, { credentials }) {
    const formdata = new FormData()

    formdata.append('account', credentials.user.account)
    formdata.append('name', credentials.user.name)
    formdata.append('phone', credentials.user.phone)
    formdata.append('email', credentials.user.email)
    formdata.append('roleID', credentials.user.roleID)
    const url = getters.apiUrl + 'User/update/' + credentials.id
    return Axios.post(
      url, formdata
    ).then(res => {
      return res
    })
  },
  passwordUpdate(getters, { credentials }) {
    const formdata = new FormData()

    formdata.append('password', credentials.password)
    const url = getters.apiUrl + 'User/changePass/' + credentials.id
    return Axios.post(
      url, formdata
    ).then(res => {
      return res
    })
  }
}

