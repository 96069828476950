<template>
  <div class="content ">
    <div class="list">
      <card
        v-for="(item, c) in cardList"
        :key="c"
        :card="item"
        :choose-card="chooseCard"
        @setCard="setCard(item)"
      />
    </div>
    <div v-if="okBtn" class="buttons">
      <div class="btn upper" @click="close">ok</div>
    </div>
  </div>
</template>

<script>
import card from './cardsComponents/getCard'
export default {
  name: 'SetCardsPanel',
  components: { card },
  props: ['okBtn'],
  data() {
    return {
      chooseCard: this.$dataManager.getters.mahjong.liveUpdate.setWinCard
    }
  },
  computed: {
    cardList() {
      return [
        {
          id: 1,
          content: '一萬'
        },
        {
          id: 2,
          content: '二萬'
        },
        {
          id: 3,
          content: '三萬'
        },
        {
          id: 4,
          content: '四萬'
        },
        {
          id: 5,
          content: '伍萬'
        },
        {
          id: 6,
          content: '六萬'
        },
        {
          id: 7,
          content: '七萬'
        },
        {
          id: 8,
          content: '八萬'
        },
        {
          id: 9,
          content: '九萬'
        },
        {
          id: 11,
          content: '一索'
        },
        {
          id: 12,
          content: '二索'
        },
        {
          id: 13,
          content: '三索'
        },
        {
          id: 14,
          content: '四索'
        },
        {
          id: 15,
          content: '伍索'
        },
        {
          id: 16,
          content: '六索'
        },
        {
          id: 17,
          content: '七索'
        },
        {
          id: 18,
          content: '八索'
        },
        {
          id: 19,
          content: '九索'
        },
        {
          id: 21,
          content: '一筒'
        },
        {
          id: 22,
          content: '二筒'
        },
        {
          id: 23,
          content: '三筒'
        },
        {
          id: 24,
          content: '四筒'
        },
        {
          id: 25,
          content: '伍筒'
        },
        {
          id: 26,
          content: '六筒'
        },
        {
          id: 27,
          content: '七筒'
        },
        {
          id: 28,
          content: '八筒'
        },
        {
          id: 29,
          content: '九筒'
        },
        {
          id: 101,
          content: '東'
        },
        {
          id: 201,
          content: '南'
        },
        {
          id: 301,
          content: '西'
        },
        {
          id: 401,
          content: '北'
        },
        {
          id: 501,
          content: '中'
        },
        {
          id: 601,
          content: '發'
        },
        {
          id: 701,
          content: '白'
        }
      ]
    }
  },
  watch: {},
  mounted() {},
  methods: {
    close: function() {
      this.$store.commit('CLEAR_DIALOG')
    },
    setCard(card) {
      this.chooseCard = card
    }
  }
}
</script>

<style lang="scss" scoped>
.list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}
</style>
