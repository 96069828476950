import Axios from './apis/_axios'

import users from './apis/users'
import userPost from './apis/userPost'
import roles from './apis/roles'
import gameType from './apis/gameTypes'
import season from './apis/season'
import winningType from './apis/winningTypes'
import team from './apis/teams'
import player from './apis/player'
import mahjong from './apis/mahjong'
import voteLottery from './apis/voteLottery'
import registration from './apis/registration'
import viewer from './apis/viewer'
import bingo from './apis/bingo'
import bingoLiveEvent from './apis/bingoLiveEvent'

export default {
  ...users,
  ...userPost,
  ...roles,
  ...gameType,
  ...season,
  ...winningType,
  ...team,
  ...player,
  ...mahjong,
  ...voteLottery,
  ...viewer,
  ...registration,
  ...bingo,
  ...bingoLiveEvent,

  getData(getters, { type, credentials }) {
    const url = getters.apiUrl + type + '/get/' + credentials
    return Axios.get(
      url
    ).then(res => {
      return res
    })
  },
  getList(getters, { action, type, param, search }) {
    let searchStr = ''
    if (search) {
      searchStr = '?'

      Object.keys(search).map((item) => {
        searchStr += '&' + item + '=' + search[item]
      })
    }
    const url = getters.apiUrl + type + '/' + action + ((param) ? '/' + param : '') + ((search) ? searchStr : '')
    return Axios.post(
      url
    ).then(res => {
      return res
    })
  },
  recordLog(getters, { credentials }) {
    const formdata = new FormData()

    formdata.append('row', JSON.stringify(credentials))
    const url = getters.apiUrl + 'Log'
    return Axios.post(
      url, formdata
    ).then(res => {
      return res
    })
  }
}

