<template>
  <div class="col-md-12">
    <div class="card">
      <div class="card-body">
        <div class="table">
          <table class="table">
            <thead class=" text-primary">
              <tr>
                <th>
                  遊戲類型
                </th>
                <th>
                  名稱
                </th>
                <th>
                  台數
                </th>
                <th>
                  點數
                </th>
                <th>
                  多選
                </th>
                <th>
                  特殊
                </th>
                <th>
                  排序順位
                </th>
                <th>
                  <router-link
                    v-if="$dataManager.getters.permissions.WinningType.includes('create')"
                    :to="{name:'WinningType.Create'}"
                  >
                    <div class="btn btn-xs btn-success btn-round pull-right">Create</div>
                  </router-link>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item,i) in winningType" :key="i">
                <td v-text="item.gType" />
                <td v-text="item.name" />
                <td v-text="item.bann" />
                <td v-text="item.point" />
                <td v-text="(item.isMulti)? '是':'否'" />
                <td v-text="(item.isSpecial)? '是':'否'" />
                <td v-text="item.sort" />
                <td>
                  <router-link
                    v-if="$dataManager.getters.permissions.WinningType.includes('update')"
                    :to="{name:'WinningType.Update',params:{id:item.id}}"
                  >
                    <div class="btn btn-xs btn-primary  pull-right">Update</div>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'WinningTypeList',
  data: function() {
    return {
      gameType: [],
      winningType: []
    }
  },
  computed: {},
  mounted() {
    this.getData()
  },
  methods: {
    getData: function() {
      this.$store.commit('OPEN_LOADING')
      var $this = this
      this.$store.dispatch('getList', { type: 'GameType', action: 'list' }).then((res) => {
        if (!res.errorCode) {
          this.gameType = res.data
          this.$store.dispatch('getList', { type: 'WinningType', action: 'list' }).then((res) => {
            if (!res.errorCode) {
              this.winningType = res.data
              this.winningType.forEach((witem) => {
                $this.gameType.forEach((gitem) => {
                  if (gitem.id === witem.gtId) {
                    witem.gType = gitem.name
                  }
                })
              })
              this.winningType.sort((a, b) => {
                return a.sort - b.sort
              })
              this.$store.commit('CLOSE_LOADING')
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
