<template>
  <div class="col-md-12">
    <div class="card">
      <div class="card-body">
        <userPostController :page-size="pageSize" />
        <el-table v-loading="loading" :data="post" style="width: 100%" :row-class-name="tableRowClassName">
          <el-table-column prop="id" label="#" width="50" />
          <el-table-column prop="content" label="Content" />
          <el-table-column prop="discordMessageID" label="Message Id" width="180" />
          <el-table-column label="Status" width="78">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.rejectReason" placement="top-end">
                <el-tag v-if="scope.row.status===2" type="danger">已打槍</el-tag>
              </el-tooltip>
              <el-tag v-if="scope.row.status===0" type="warning" effect="plain">待審核</el-tag>
              <el-button
                v-if="scope.row.status===1"
                type="success"
                icon="el-icon-video-play"
                circle
                @click="playUserPost(scope.row.id)"
              />
              <el-button
                v-if="scope.row.status===3"
                type="warning"
                icon="el-icon-video-play"
                circle
                @click="playUserPost(scope.row.id)"
              />
            </template>
          </el-table-column>
          <el-table-column label="Action" width="75">
            <template slot-scope="scope">
              <el-button type="success" icon="el-icon-edit" circle @click="handleOpen(scope.row)" />
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          v-if="total && total>pageSize"
          :page-size="pageSize"
          :pager-count="11"
          :current-page="page"
          layout="prev, pager, next"
          :total="total"
          @current-change="getData"
        />
      </div>
    </div>
    <el-dialog title="Edit User Post" :visible.sync="dialogVisible" width="50%" :before-close="handleClose">
      <el-form v-if="edit.id" ref="form" :model="edit" label-width="80px">
        <p>
          <span :class="edit.data.q2=='男生'?'boy':'girl'">{{ edit.data.q1 }} {{ edit.data.q3 }}</span> 想對 <span
            :class="edit.data.q5=='男生'?'boy':'girl'"
          >{{ edit.data.q4 }} {{ edit.data.q6 }}</span> 説:<br>
          <span class="text">{{ edit.data.q7 }}</span>
        </p>
        <el-form-item label="審核狀態">
          <el-switch
            v-model="edit.status"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-value="1"
            inactive-value="2"
          />
        </el-form-item>
        <el-form-item v-if="edit.status==2" label="是否公告">
          <el-switch
            v-model="edit.isShow"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-value="1"
            inactive-value="0"
          />
        </el-form-item>
        <el-form-item v-if="edit.status==2" label="打槍原因">
          <el-input v-model="edit.rejectReason" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dataSubmit()">送出</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import userPostController from '@/components/UserPost/userPostController'

export default {
  name: 'UserPostList',
  components: {
    userPostController
  },
  data: function() {
    return {
      dialogVisible: false,
      loading: true,
      page: 1,
      pageSize: 20,
      total: 0,
      post: [],
      nowId: 0,
      lastId: 0,
      edit: {}
    }
  },
  computed: {},
  mounted() {
    this.getData(this.page)
    const $this = this
    if (!this.$bus._events['changeUserPostPage']) {
      this.$bus.$on('changeUserPostPage', (val) => {
        if ($this.page !== val) {
          $this.page = val
          $this.getData(val)
        }
      })
    }
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      return (row.id === this.nowId) ? 'warning-row' : ''
    },
    getData(val) {
      this.loading = true
      this.$store
        .dispatch('getList', { type: 'UserPost', action: 'list', search: { page: val, pageSize: this.pageSize }})
        .then((res) => {
          if (!res.errorCode) {
            this.loading = false
            this.post = res.data.items
            this.total = res.data.total
          }
        })
    },
    dataSubmit() {
      const credentials = { id: this.edit.id, status: this.edit.status, isShow: this.edit.isShow, rejectReason: this.edit.rejectReason }
      this.$store
        .dispatch('updateUserPost', { credentials: credentials })
        .then((res) => {
          if (!res.errorCode) {
            this.dialogVisible = false
            this.getData(this.page)
          }
        })
    },
    playUserPost(id) {
      const setting = {
        nowId: id,
        lastId: this.nowId,
        playing: true,
        replay: false
      }
      Object.assign(this.$root.userPostSetting, setting)
    },
    handleOpen(data) {
      console.log(data)
      this.dialogVisible = true
      this.edit = {
        id: data.id,
        data: data.data,
        status: JSON.stringify(data.status),
        isShow: (data.isShow) ? '1' : '0',
        rejectReason: data.rejectReason
      }
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => { })
    }
  }
}
</script>

<style lang="scss">
.el-table .warning-row {
  background: oldlace;
}

.cell {

  .el-tag--danger {
    cursor: help;
  }
}
</style>
