<template>
  <div class="card card-user">
    <div class="card-body">
      <div v-if="flag" class="alert alert-warning">
        <p style="margin: 0;" v-text="'抓取留言中.....'" />
      </div>
      <div v-if="liveChatId==''" class="btn-group ml-1">
        <!--        <div class="btn btn-sm btn-warning " @click="GoogleOAuth()">Google Check</div>-->
        <!--        <div class="btn btn-sm btn-primary disabled" @click="$dataManager.authenticate().then($dataManager.loadClient)">登入</div>-->
        <!--        <div class="btn btn-sm btn-primary disabled" @click="$dataManager.execute()">抓取直播資料</div>-->
      </div>
      <ul v-if="liveChatId==''" class="list-group">
        <li
          v-for="(live,i) in $dataManager.getters.allLiveshowList"
          :key="i"
          class="list-group-item"
          @click="getLiveData(live)"
          v-text="live.snippet.title"
        />
      </ul>
      <h5 v-if="title !== ''" v-text="title" />
    </div>
    <!--    <div class="row">-->
    <!--      <div class="update ml-auto mr-auto">-->
    <!--        <div class="btn btn-primary btn-round" v-if="!flag" @click="startGetMsg()">開始</div>-->
    <!--        <div class="btn btn-primary btn-round" v-if="flag" @click="stopGetMsg()">停止</div>-->
    <!--      </div>-->
    <!--    </div>-->

  </div>
</template>

<script>

export default {
  name: 'Dashboard',
  components: {},
  data() {
    return {
      vid: this.$root.voteSetting.voteId,
      title: '',
      liveChatId: '',
      part: ['snippet', 'id', 'authorDetails'],
      maxResults: 2000,
      allLiveshowList: [],
      nextPageToken: '',
      Interval: 30,
      filter: '',
      result: [],
      formatResult: [],
      msgInterval: 0,
      flag: false
    }
  },
  mounted() {
    // this.getStatusInterval()
    // var $this = this
    // this.$bus.$on('voteSetting', content => {
    //   setTimeout(() => {
    //     console.log(content)
    //     $this.vid = content.voteId;
    //   }, 300)
    // })
  },
  methods: {
    GoogleOAuth: function() {
      this.$store.dispatch('GoogleOAuth').then((res) => {
        if (!res.errorCode) {
          const url = res.data.url
          const _config = 'height=660,width=600,toolbar=no'
          window.open(url, 'Google Auth Login', _config)
        }
      })
    },
    getStatusInterval: function() {
      this.getStatus()
      this.msgInterval = setInterval(this.getStatus, this.Interval * 1000)
    },
    getStatus: function() {
      this.$store.dispatch('YoutubeInfo').then((res) => {
        if (!res.errorCode) {
          if (res.data.online) {
            this.flag = true
          } else {
            this.flag = false
          }
        }
      })
    },
    startGetMsg: function() {
      this.$store.dispatch('YoutubeStart').then((res) => {
        if (!res.errorCode) {
          this.flag = true
        }
      })
    },
    stopGetMsg: function() {
      this.$store.dispatch('YoutubeEnd').then((res) => {
        if (!res.errorCode) {
          this.flag = false
        }
      })
    },
    _startPoll: function() {
      this.stopPoll()
      this.getMsgAction()
      this.msgInterval = setInterval(this.getMsgAction, this.Interval * 1000)
    },
    _stopPoll: function() {
      clearInterval(this.msgInterval)
      this.msgInterval = 0
    },
    getMsgAction: function() {
      this.$dataManager.getMessage(this.liveChatId, this.part, this.nextPageToken).then((res) => {
        if (!res.errorCode) {
          this.nextPageToken = res.nextPageToken
          if (res.items.length > 0) {
            this.pollAction(res.items)
          }
          this.$store.commit('CLOSE_LOADING')
        }
        this.$store.commit('CLOSE_LOADING')
      })
      // }
      // var credentials = {
      //   liveChatId: this.liveChatId,
      //   pageToken: this.nextPageToken
      // }
      // this.$store.dispatch('getMsg', {credentials}).then((res) => {
      //   if (!res.errorCode) {
      //     this.nextPageToken = res.nextPageToken
      //     if (res.items.length > 0) {
      //       this.pollAction(res.items)
      //     }
      //     this.$store.commit('CLOSE_LOADING')
      //   }
      // })
      //   this.$store.dispatch('VotePoll', {credentials}).then((res) => {
      //     if (!res.errorCode) {
      //       this.$store.commit('CLOSE_LOADING')
      //     }
      //   })
    },
    pollAction: function(items) {
      var credentials = {
        id: this.vid,
        datas: this.formatMsg(items)
      }
      this.$store.dispatch('VotePoll', { credentials }).then((res) => {
        if (!res.errorCode) {
          this.$store.commit('CLOSE_LOADING')
        }
      })
    },
    formatMsg: function(items) {
      var res = []
      items.forEach((item, i) => {
        var data = {}
        data.type = item.snippet.type
        data.authorChannelId = item.authorDetails.channelId
        data.channelUrl = item.authorDetails.channelUrl
        data.avatar = item.authorDetails.profileImageUrl
        data.avatar = item.authorDetails.profileImageUrl
        data.nickname = item.authorDetails.displayName
        data.content = item.snippet.displayMessage
        data.publishedAt = item.snippet.publishedAt
        res.push(data)
      })
      return res
    },
    getLiveData: function(live) {
      this.liveChatId = live.snippet.liveChatId
      this.title = live.snippet.title
    }
  }
}
</script>

<style lang="scss" scoped>
.option {
  margin: 4px;
}

.list-group-horizontal {
  .list-group-item {
    padding: 4px;
    min-width: 3rem;
    text-align: center;
  }
}

</style>
