<template>
  <div>
    <barChart
      v-if="options.length>0"
      :datas="options"
      :width="450"
      :height="350"
      :bgcolor="transparent"
      :axis-color="transparent"
      :font-size="32"
      :font-color="'#ffffff'"
      :font-family="'Microsoft JhengHei'"
      :flex-bar="3"
      :flex-gap="1"
    />
    <h3 class="title" v-text="vote.title" />
  </div>
</template>

<script>
import barChart from '@/components/majcmp/barChart'

export default {
  name: 'BarChat',
  components: { barChart },
  data() {
    return {
      id: this.$root.voteSetting.voteId,
      transparent: 'rgba(0,0,0,0)',
      vote: {
        title: '',
        options: []
      },
      interval: {}
    }
  },
  computed: {
    options() {
      var res = []
      this.vote.options.forEach((opt, idx) => {
        var item = {}
        item.count = opt.count
        item.color = opt.color
        item.name = opt.title
        res.push(item)
      })
      return res
    }
  },
  watch: {},
  mounted() {
    this.$bus.$on('voteSetting', content => {
      setTimeout(() => {
        this.dataInterval(content.voteId)
      }, 300)
    })
  },
  methods: {
    dataInterval: function(id) {
      clearInterval(this.interval)
      this.getData(id)
      var $this = this
      setInterval(() => {
        $this.getData(id)
      }, 10 * 1010)
    },
    getData: function(id) {
      this.$store.dispatch('getData', { type: 'Vote', credentials: id }).then((res) => {
        if (!res.errorCode) {
          this.vote = res.data
        }
      })
    }
  }
}
</script>

<style lang="scss">
  text {
    fill: #fff;
    font-family: 'Microsoft JhengHei' !important;
  }

  .title {
    width: 450px;
    text-align: center;
    color: #ffffff;
    font-family: '蒙納盈富體';
    font-weight: 900;
  }
</style>
