<template>
  <div>
    <div class="card card-user">
      <div class="card-body">
        <div class="form-group row">
          <label class="col-md-2 col-form-label">抽獎標題</label>
          <div class="col-md-10">
            <input v-model="lottery.title" type="text" class="form-control">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-2 col-form-label">綁定投票</label>
          <div class="col-md-10">
            <input v-model="vote.title" type="text" disabled class="form-control">
          </div>
        </div>
      </div>
    </div>
    <div class="control">
      <h5>獎品</h5>
      <div class="btns">
        <div class="btn btn-sm btn-success btn-icon" @click="minusRows"><i class="ti-minus" /></div>
        <div class="btn btn-sm btn-success btn-icon" v-text="rowsCount" />
        <div class="btn btn-sm btn-success btn-icon" @click="addRows"><i class="ti-plus" /></div>
      </div>
    </div>
    <div v-for="(row,i) in lottery.prizes" :key="i" class="card card-user">
      <div class="card-body">
        <div class="form-group row">
          <label class="col-md-1 col-form-label">獎品</label>
          <div class="col-md-10">
            <input v-model="row.title" type="text" class="form-control">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-1 col-form-label">數量</label>
          <div class="col-md-10">
            <input v-model="row.count" type="text" class="form-control">
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="update ml-auto mr-auto">
        <div class="btn btn-primary btn-round" @click="submit">送出</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Create',
  data: function() {
    return {
      lottery: {
        voteId: this.$route.params.voteId,
        title: '',
        prizes: [{
          title: '',
          count: ''
        }]
      },
      vote: {}
    }
  },
  computed: {
    rowsCount() {
      return this.lottery.prizes.length
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    addRows: function() {
      var row = {
        title: '',
        count: ''
      }
      if (this.lottery.prizes.length < 25) {
        this.lottery.prizes.push(row)
      }
    },
    minusRows: function() {
      if (this.lottery.prizes.length > 1) {
        this.lottery.prizes.pop()
      }
    },
    addRow: function(row) {
      var r = ''
      if (row.keys.length < 25) {
        row.keys.push(r)
      }
    },
    minusRow: function(row) {
      if (row.keys.length > 1) {
        row.keys.pop()
      }
    },
    submit: function() {
      this.$store.commit('CLOSE_LOADING')

      this.$store.dispatch('LotteryCreate', { credentials: this.lottery }).then((res) => {
        if (!res.errorCode) {
          this.$store.commit('CLOSE_LOADING')
          this.$router.push({ name: 'Lottery.Detail', params: { id: res.data.id }})
        }
      })
    },
    getData: function() {
      this.$store.commit('CLOSE_LOADING')

      this.$store.dispatch('getData', { type: 'Vote', credentials: this.lottery.voteId }).then((res) => {
        if (!res.errorCode) {
          this.vote = res.data
          this.$store.commit('CLOSE_LOADING')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .control {
    display: flex;
    align-items: center;

    h5 {
      margin: 0;
    }

    .btns {
      margin-left: 3rem;
    }
  }

  .colorList {
    width: 100%;
    height: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

    .color {
      height: 30px;
      color: #ffffff;
      padding: 4px;
      position: relative;
      cursor: pointer;

      span {
        position: absolute;
        opacity: .4;
        font-size: .5rem;
      }

      i {
        position: absolute;
        right: 50%;
        font-size: 1rem;
        margin-right: -.5rem;
      }
    }
  }

  .btns {
    display: flex;
  }
</style>
