<template>
  <div class="playerData">
    <div class="left">
      <div class="item">
        <div class="label">
          <playerData :type="'title06'" />
        </div>
        <span
          :class="team+'-text'"
          v-text="roundDecimal(((detail.tsumoCount) / detail.allHands)*100,2)"
        />
      </div>
      <div class="item">
        <div class="label">
          <playerData :type="'title07'" />
        </div>
        <span
          :class="team+'-text'"
          v-text="roundDecimal(((detail.winCount) / detail.allHands)*100,2)"
        />
      </div>
      <div class="item">
        <div class="label">
          <playerData :type="'title08'" />
        </div>
        <span
          :class="team+'-text'"
          v-text="roundDecimal(((detail.loseCount) / detail.allHands)*100,2)"
        />
      </div>
      <div class="item">
        <div class="label">
          <playerData :type="'title09'" />
        </div>
        <span :class="team+'-text'" v-text="detail.maxCombo" />
      </div>
      <div class="item">
        <div class="label">
          <playerData :type="'title10'" />
        </div>
        <span :class="team+'-text'" v-text="comboDecimal(detail.allCombo / (detail.allGames*4),2)" />
      </div>
    </div>
    <div class="right">

      <div class="draw">
        <div class="tsumo">
          <playerData :type="'tsumo'" />
        </div>
        <trigonScore
          :linelength="trigonLineLength"
          :dotwidth="trigonDotWidth"
          :borderwidth="trigonBorderWidth"
          :bordercolor="'#FFA910'"
          :areacolor="trigonAreaColor"
          :rates="trigonRates"
          :delaytime="trigonDelay"
          :animationtime="trigonAnimation"
          :bordercover="flagBorderCover"
        />
        <div class="self">
          <playerData :type="'self_win'" />
          <playerData :type="'self_lose'" />
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import playerData from '@/components/Show/playerData'
import trigonScore from '@/components/majcmp/trigonScore'

export default {
  name: 'PlayerTotalData',
  components: { playerData, trigonScore },
  data() {
    return {
      id: this.$route.params.id,
      detail: {},
      flagBorderCover: true,
      trigonLineLength: 420,
      trigonBorderWidth: 3,
      trigonDotWidth: 4,
      trigonBorderColor: '#daaa20',
      trigonAreaColor: 'rgba(200,200,200,0.6)',
      trigonDelay: 500,
      trigonAnimation: 500
    }
  },
  computed: {
    trigonRates() {
      var tsumo = this.getRange(((this.detail.tsumoCount) / this.detail.allHands) * 100)
      var self_lose = this.getRange(((this.detail.loseCount) / this.detail.allHands) * 100)
      var self_win = this.getRange(((this.detail.winCount) / this.detail.allHands) * 100)
      var data = [tsumo, self_win, self_lose]
      return data
    }
  },
  mounted() {
    this.$bus.$on('liveSetting', content => {
      setTimeout(() => {
        this.getDetail()
      }, 300)
    })
  },
  methods: {
    roundDecimal: function(val, precision) {
      var data = Math.round(Math.round(val * Math.pow(10, (precision || 0) + 1)) / 10) / Math.pow(10, (precision || 0)) || '-'
      return (data !== '-') ? data + '%' : data
    },
    comboDecimal: function(val, precision) {
      console.log(val)
      var data = Math.round(Math.round(val * Math.pow(10, (precision || 0) + 1)) / 10) / Math.pow(10, (precision || 0))
      return data
    },
    getRange(data) {
      var count = data
      var i = count * 2
      return i
    },
    getDetail() {
      var data = this.id
      this.$store.dispatch('playerDetail', { credentials: data }).then((res) => {
        if (!res.errorCode) {
          this.detail = res.data
        }
      })
    }
  }
}
</script>

<style lang="scss">
.playerData {
  display: flex;

  .left {
    margin-top: 150px;
    width: 435px;

    .item {
      display: flex;
      height: 150px;

      .label {
        font-size: 48px;
        font-weight: 700;
        height: 100%;
        flex: 0 230px;
        min-width: 230px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #FFA910;
      }

      span {
        flex: 1;
        margin-left: 50px;
        display: flex;
        align-items: center;
        font-size: 56px;
        font-weight: 700;
        padding-top: 10px;
        color: #FFA910;
      }
    }
  }

  .right {
    width: 420px;
    margin-left: 450px;

    .player {
      .team-label {
        color: #000;
        display: flex;
        justify-content: center;

        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 124px;

          svg {
            z-index: 2;
            width: 124px;
            height: 116px;

          }

          span {
            position: absolute;
            z-index: 4;
            font-weight: 600;
            font-size: 64px;
          }
        }
      }

      .name-img {
        display: flex;
        justify-content: center;
        margin-top: 30px;
      }
    }

    .draw {
      margin-top: 240px;
      position: relative;
      width: 100%;
      height: 50vh;

      svg {
        fill: #FFA910;
      }

      .tsumo {
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
      }

      .self {
        display: flex;
        margin-top: 10px;
        justify-content: space-between;

        .icon {
          &:nth-child(1) {
            margin-left: -75px;
          }

          &:nth-child(2) {
            margin-right: -75px;
          }
        }
      }

    }
  }

}
</style>
