<template>
  <div>
    <div class="card card-user">
      <div class="card-body">
        <div class="form-group row">
          <label class="col-md-2 col-form-label">顯示文字</label>
          <div class="col-md-10">
            <textarea v-model="showStr" type="text" class="form-control" @keyup="handleChange" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Create',
  data: function() {
    return {
      showStr: ''
    }
  },
  mounted() {
    this.$bus.$on('liveStrSetting', (data) => {
      this.showStr = data
    })
  },
  methods: {
    handleChange: function(e) {
      this.$root.liveStr = this.showStr
    }
  }
}
</script>

<style lang="scss" scoped>
  textarea {
    height: 20rem;
  }
</style>
