<template>
  <div class="winCard">
    <img :src="require('./images/'+ card +'.png')" alt="">
  </div>
</template>

<script>
export default {
  name: 'GetCard',
  components: {},
  props: ['card'],
  data() {
    return {}
  },
  computed: {},
  methods: {}
}
</script>

<style lang="scss" scoped>

  .winCard {
    width: 143px;
    height: 218px;
    background-size: contain;
    transition:  all .3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  }

</style>
