import Axios from './_axios'

export default {

  VoteCreate(getters, { credentials }) {
    const formdata = new FormData()

    formdata.append('title', credentials.title)
    formdata.append('options', JSON.stringify(credentials.options))

    const url = getters.apiUrl + 'Vote/create'
    return Axios.post(
      url, formdata
    ).then(res => {
      return res
    })
  },

  VoteStart(getters, { credentials }) {
    const url = getters.apiUrl + 'Vote/start/' + credentials.id
    return Axios.get(
      url
    ).then(res => {
      return res
    })
  },
  VoteEnd(getters, { credentials }) {
    const url = getters.apiUrl + 'Vote/end/' + credentials.id
    return Axios.get(
      url
    ).then(res => {
      return res
    })
  },

  LotteryList(getters, { credentials }) {
    const url = getters.apiUrl + 'Lottery/list/' + credentials
    return Axios.get(
      url
    ).then(res => {
      return res
    })
  },
  LotteryGetResult(getters, { credentials }) {
    const url = getters.apiUrl + 'Lottery/getResult/' + credentials
    return Axios.get(
      url
    ).then(res => {
      return res
    })
  },
  LotterySetPosition(getters, { credentials }) {
    const url = getters.apiUrl + 'Lottery/setPosition'
    const formdata = new FormData()
    formdata.append('positions', JSON.stringify(credentials))

    return Axios.post(
      url, formdata
    ).then(res => {
      return res
    })
  },
  LotteryCreate(getters, { credentials }) {
    const formdata = new FormData()

    formdata.append('vId', credentials.voteId)
    formdata.append('title', credentials.title)
    formdata.append('prizes', JSON.stringify(credentials.prizes)) // vote option id

    const url = getters.apiUrl + 'Lottery/create'
    return Axios.post(
      url, formdata
    ).then(res => {
      return res
    })
  },
  LotteryDraw(getters, { credentials }) {
    const formdata = new FormData()

    formdata.append('voId', credentials.voId)

    const url = getters.apiUrl + 'Lottery/draw/' + credentials.lotteryId
    return Axios.post(
      url, formdata
    ).then(res => {
      return res
    })
  },
  LotteryReDraw(getters, { credentials }) {
    const url = getters.apiUrl + 'Lottery/redraw/' + credentials.lotteryResultId
    return Axios.post(
      url
    ).then(res => {
      return res
    })
  }
}
