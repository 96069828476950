import Axios from './_axios'

export default {

  viewerGet(getters, { credentials }) {
    const formdata = new FormData()
    formdata.append('authorChannelId', credentials.authorChannelId)

    const url = getters.apiUrl + 'Viewer/get'
    return Axios.post(
      url, formdata
    ).then(res => {
      return res
    })
  },
  viewerList(getters, { credentials }) {
    const formdata = new FormData()
    formdata.append('pageSize', credentials.pageSize)
    formdata.append('page', credentials.page)
    formdata.append('pointSort', '1')

    const url = getters.apiUrl + 'Viewer/list'
    return Axios.post(
      url, formdata
    ).then(res => {
      return res
    })
  },
  viewerStatistics(getters, { credentials }) {
    const formdata = new FormData()
    formdata.append('authorChannelId', credentials)

    const url = getters.apiUrl + 'Viewer/statistics'
    return Axios.post(
      url, formdata
    ).then(res => {
      return res
    })
  },
  viewerPointLog(getters, { credentials }) {
    const formdata = new FormData()
    formdata.append('authorChannelId', credentials.authorChannelId)
    formdata.append('liveChatId', credentials.liveChatId)

    const url = getters.apiUrl + 'Viewer/pointLog'
    return Axios.post(
      url, formdata
    ).then(res => {
      return res
    })
  }
}
