<template>
  <div class="col-md-12">
    <div class="card">
      <div class="card-body bingoLiveEvent">
        <div class="top">
          <el-tooltip class="item" effect="dark" content="列印" placement="bottom">
            <el-button type="success" icon="el-icon-printer" circle @click="handleOpenPrint()" />
          </el-tooltip>
        </div>
        <el-table v-loading="loading" :data="list" style="width: 100%" :row-class-name="tableRowClassName" @selection-change="handleSelectionChange">
          <el-table-column label="Action" width="75" type="selection" />
          <el-table-column prop="id" label="#" width="50" />
          <el-table-column label="狀態" width="78">
            <template slot-scope="scope">
              <el-tag v-if="!scope.row.isPrint" type="warning">未列印</el-tag>
              <el-tag v-else type="success">已列印</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="version" label="場次" width="50" />
          <el-table-column prop="name" label="姓名" />
          <el-table-column prop="identity" label="身分證字號" />
          <el-table-column prop="birthday" label="出生年月日" />
          <el-table-column prop="createdTime" label="創建時間" width="180" />
        </el-table>
        <el-pagination
          v-if="total && total>pageSize"
          :page-size="pageSize"
          :pager-count="11"
          :current-page="page"
          layout="prev, pager, next"
          :total="total"
          @current-change="getData"
        />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'BingoLiveEventList',
  components: {},
  data: function() {
    return {
      dialogVisible: false,
      loading: true,
      page: 1,
      pageSize: 20,
      total: 0,
      list: [],
      multipleSelection: []
    }
  },
  computed: {},
  mounted() {
    this.getData(this.page)
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      return (row.id === this.nowId) ? 'warning-row' : ''
    },
    getData(val) {
      this.loading = true
      this.$store
        .dispatch('getList', { type: 'BingoLiveEvent', action: 'list', search: { page: val, pageSize: this.pageSize }})
        .then((res) => {
          if (!res.errorCode) {
            this.loading = false
            this.list = res.data.items
            this.total = res.data.total
          }
        })
    },
    updatePrintStatus() {
      this.loading = true
      let ids = ''
      ids += this.multipleSelection.map((item) => { return item.id })
      this.$store
        .dispatch('updatePrintStatus', { credentials: ids })
        .then((res) => {
          if (!res.errorCode) {
            this.loading = false
            this.getData(this.page)
            window.open('#/show/bingoPrint/' + ids, '_blank')
          }
        })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    handleOpenPrint(val) {
      this.updatePrintStatus()
    }
  }
}
</script>

<style lang="scss">
.bingoLiveEvent{
  display: flex;
  flex-direction: column;

    .top {
        height: 2rem;
        margin: .5rem 0;
    }
}
.el-table .warning-row {
  background: oldlace;
}

.cell {

  .el-tag--danger {
    cursor: help;
  }
}
</style>
