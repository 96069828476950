<template>
  <div class="col-md-12">
    <div class="card">
      <div class="card-header" />
      <div class="card-body">
        <div class="table">
          <table class="table">
            <thead class=" text-primary">
              <tr>
                <th>Name</th>
                <th>Role</th>
                <th>User</th>
                <th>GameType</th>
                <th>WinningType</th>
                <th>Team</th>
                <th>Player</th>
                <th>Season</th>
                <th>Live</th>
                <th>Hand</th>
                <th>Vote</th>
                <th>Lottery</th>
                <th>Registration</th>
                <th>Manager</th>
                <th>
                  <router-link v-if="$dataManager.getters.permissions.Role.includes('create')" :to="{name:'Role.Create'}">
                    <div class="btn btn-xs btn-success btn-round pull-right">Create</div>
                  </router-link>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(role,i) in roles"
                :key="i"
              >
                <td v-text="role.name" />
                <td>
                  <span
                    v-for="(r,ix) in role.permission.Role"
                    :key="ix"
                    class="badge badge-light"
                    v-text="getPermission(r)"
                  />
                </td>
                <td>
                  <span
                    v-for="(r,ix) in role.permission.User"
                    :key="ix"
                    class="badge badge-light"
                    v-text="getPermission(r)"
                  />
                </td>
                <td>
                  <span
                    v-for="(r,ix) in role.permission.GameType"
                    :key="ix"
                    class="badge badge-light"
                    v-text="getPermission(r)"
                  />
                </td>
                <td>
                  <span
                    v-for="(r,ix) in role.permission.WinningType"
                    :key="ix"
                    class="badge badge-light"
                    v-text="getPermission(r)"
                  />
                </td>
                <td>
                  <span
                    v-for="(r,ix) in role.permission.Team"
                    :key="ix"
                    class="badge badge-light"
                    v-text="getPermission(r)"
                  />
                </td>
                <td>
                  <span
                    v-for="(r,ix) in role.permission.Player"
                    :key="ix"
                    class="badge badge-light"
                    v-text="getPermission(r)"
                  />
                </td>
                <td>
                  <span
                    v-for="(r,ix) in role.permission.Season"
                    :key="ix"
                    class="badge badge-light"
                    v-text="getPermission(r)"
                  />
                </td>
                <td>
                  <span
                    v-for="(r,ix) in role.permission.Live"
                    :key="ix"
                    class="badge badge-light"
                    v-text="getPermission(r)"
                  />
                </td>
                <td>
                  <span
                    v-for="(r,ix) in role.permission.Hand"
                    :key="ix"
                    class="badge badge-light"
                    v-text="getPermission(r)"
                  />
                </td>
                <td>
                  <span
                    v-for="(r,ix) in role.permission.Vote"
                    :key="ix"
                    class="badge badge-light"
                    v-text="getPermission(r)"
                  />
                </td>
                <td>
                  <span
                    v-for="(r,ix) in role.permission.Lottery"
                    :key="ix"
                    class="badge badge-light"
                    v-text="getPermission(r)"
                  />
                </td>
                <td>
                  <span
                    v-for="(r,ix) in role.permission.Registration"
                    :key="ix"
                    class="badge badge-light"
                    v-text="getPermission(r)"
                  />
                </td>
                <td>
                  <span
                    v-for="(r,ix) in role.permission.Manager"
                    :key="ix"
                    class="badge badge-light"
                    v-text="getPermission(r)"
                  />
                </td>
                <td>
                  <router-link
                    v-if="$dataManager.getters.permissions.Role.includes('update')"
                    :to="{name:'Role.Update',params:{id:role.id}}"
                  >
                    <div class="btn btn-xs btn-primary  pull-right">Update</div>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'RoleList',
  data: function() {
    return {
      roles: []
    }
  },
  computed: {
    ...mapGetters([
      'permissions'
    ])
  },
  mounted() {
    this.getData()
  },
  methods: {
    getPermission: function(role) {
      var data = role
      switch (role) {
        case true:
        case 'true':
          data = 'O'
          break
        case false:
        case 'false':
          data = 'X'
          break
        case 'list':
          data = '列表'
          break
        case 'create':
          data = '建立'
          break
        case 'update':
          data = '更新'
          break
        case 'delete':
          data = '刪除'
          break
        default:
          data = '-'
          break
      }
      return data
    },
    getData: function() {
      this.$store.commit('CLOSE_LOADING')
      this.$store.dispatch('getList', { type: 'Role', action: 'list' }).then((res) => {
        if (!res.errorCode) {
          this.roles = res.data
          this.$store.commit('CLOSE_LOADING')
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
