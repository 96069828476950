<template>
  <div class="card card-user">
    <div class="card-body">
      <div v-if="flag" class="alert alert-warning">
        <p style="margin: 0;" v-text="'抓取留言中.....'" />
      </div>
      <span v-text="vote.title" />
      <div class="form-group row">
        <label class="col-md-1 col-form-label">複製內容</label>
        <div class="col-md-11">
          <input
            id="copy"
            type="text"
            class="form-control"
            :value="vote.title+'，大家在聊天室投票吧！'+optionsText"
            @click="copy()"
          >
        </div>
      </div>
      <div v-for="(option,i) in vote.options" :key="i" class="group">
        <p class="option" :style="'color:'+option.color" v-text="'選項'+option.title" />
        <ul class="list-group list-group-horizontal">
          <li
            v-for="(text,j) in option.key"
            :key="j"
            class="list-group-item"
            :style="'color:'+option.color"
            v-text="text"
          />
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="update ml-auto mr-auto">
        <div class="btn btn-primary btn-round" @click="startPoll">開始</div>
        <div class="btn btn-primary btn-round" @click="stopPoll">停止</div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'Detail',
  components: { },
  data() {
    return {
      id: this.$route.params.id,
      gapi: {},
      vote: {
        title: '',
        options: []
      },
      colorList: [],
      transparent: 'rgba(0,0,0,0)',
      liveChatId: '',
      part: ['snippet', 'id', 'authorDetails'],
      maxResults: 2000,
      allLiveshowList: [],
      nextPageToken: '',
      Interval: 180,
      filter: '',
      result: [],
      formatResult: [],
      msgInterval: {},
      flag: false
    }
  },
  computed: {
    options() {
      var res = []
      this.vote.options.forEach((opt, idx) => {
        var item = {}
        item.count = opt.count
        item.color = opt.color
        opt.key.forEach((t, i) => {
          if (opt.key.length - 1 === i) {
            item.name = t
          }
        })
        res.push(item)
      })
      return res
    },
    optionsText() {
      var str = ''
      this.vote.options.forEach((opt, idx) => {
        var op = ''
        opt.key.forEach((t, i) => {
          if (opt.key.length - 1 === i) {
            op += t
          } else {
            op += t + '、'
          }
        })
        str += '投選項' + parseInt(idx + 1)
        if (this.vote.options.length - 1 === idx) {
          str += '請輸入' + op + '！'
        } else {
          str += '請輸入' + op + '，'
        }
      })
      return str
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    copy() {
      const testingCodeToCopy = document.querySelector('#copy')
      testingCodeToCopy.setAttribute('type', 'text') // 不是 hidden 才能複製
      testingCodeToCopy.select()
      document.execCommand('copy')
      /* unselect the range */
      window.getSelection().removeAllRanges()
    },
    startPoll: function() {
      this.$store.commit('OPEN_LOADING')
      this.$root.voteSetting.voteId = parseInt(this.id)
      var credentials = {
        id: this.id
      }
      this.$store.dispatch('VoteStart', { credentials }).then((res) => {
        if (!res.errorCode) {
          this.$alert({ type: 'success', msg: 'Success vote counting.', duration: 3 * 1000 })
          this.flag = true
          this.$store.commit('CLOSE_LOADING')
        }
      })
    },
    stopPoll: function() {
      this.$store.commit('OPEN_LOADING')
      var credentials = {
        id: this.id
      }
      this.$store.dispatch('VoteEnd', { credentials }).then((res) => {
        if (!res.errorCode) {
          this.$alert({ type: 'success', msg: 'Success stop counting.', duration: 3 * 1000 })
          this.flag = false
          this.$store.commit('CLOSE_LOADING')
        }
      })
    },
    getData: function() {
      this.$store.commit('OPEN_LOADING')

      this.$store.dispatch('getData', { type: 'Vote', credentials: this.id }).then((res) => {
        if (!res.errorCode) {
          this.vote = res.data
          this.$store.commit('CLOSE_LOADING')
        }
      })
    },
    getLiveData: function(live) {
      this.liveChatId = live.snippet.liveChatId
    }
  }
}
</script>

<style lang="scss" scoped>
  .option {
    margin: 4px;
  }

  .list-group-horizontal {
    .list-group-item {
      padding: 4px;
      min-width: 3rem;
      text-align: center;
    }
  }

</style>
