// export const apiUrl = state => location.protocol + '//' +state.apiUrl
export const sid = state => state.auth.sid
export const username = state => state.auth.username
export const permissions = state => state.auth.permissions
export const dialog = state => state.dialog
export const loading = state => state.loading
export const mahjong = state => state.mahjong
export const allLiveshowList = state => state.allLiveshowList
export const location = state => state.location
export const playerKey = state => state.playerKey
export const teamKey = state => state.teamKey
export const colorList = state => state.colorList

export const apiUrl = (state) => {
  if (state.apiUrl.indexOf('http') === -1) {
    return window.location.protocol + '//' + state.apiUrl
  } else {
    return state.apiUrl
  }
  // return state.apiUrl
}

