import Root from '@/components/Root'

import ShowList from '@/components/Show/List'
import RoleList from '@/components/Role/List'
import UserList from '@/components/User/List'
import GameTypeList from '@/components/GameType/List'
import WinningTypeList from '@/components/WinningType/List'
import TeamList from '@/components/Team/List'
import PlayerList from '@/components/Player/List'
import SeasonList from '@/components/Season/List'
import RegistrationList from '@/components/Registration/List'
import ViewerList from '@/components/Viewer/List'
import ViewerStatistics from '@/components/Viewer/Statistics'
import ViewerPointLog from '@/components/Viewer/PointLog'
import showPlayerList from '@/components/Show/PlayerList'
import LiveList from '@/components/Live/List'
import VoteList from '@/components/Vote/List'
import LotteryList from '@/components/Lottery/List'
import UserPostList from '@/components/UserPost/List'
import BingoLiveEventList from '@/components/BingoLiveEvent/List'

const list = [
  {
    path: '/app',
    name: 'List',
    component: Root,
    children: [
      {
        path: 'roles',
        name: 'Role.List',
        component: RoleList
      },
      {
        path: 'users',
        name: 'User.List',
        component: UserList
      },
      {
        path: 'gameTypes',
        name: 'GameType.List',
        component: GameTypeList
      },
      {
        path: 'winningTypes',
        name: 'WinningType.List',
        component: WinningTypeList
      },

      {
        path: 'seasons',
        name: 'Season.List',
        component: SeasonList
      },
      {
        path: 'shows',
        name: 'Show.List',
        component: ShowList
      },
      {
        path: 'showsPlayer/:seasonId/:id',
        name: 'Show.PlayerList',
        component: showPlayerList
      },
      {
        path: 'teams',
        name: 'Team.List',
        component: TeamList
      },
      {
        path: 'players',
        name: 'Player.List',
        component: PlayerList
      },

      {
        path: 'lives',
        name: 'Live.List',
        component: LiveList
      },
      {
        path: 'votes',
        name: 'Vote.List',
        component: VoteList
      },
      {
        path: 'lotteries/:voteId',
        name: 'Lottery.List',
        component: LotteryList
      },
      {
        path: 'Registrations',
        name: 'Registration.List',
        component: RegistrationList
      },
      {
        path: 'UserPost',
        name: 'UserPost.List',
        component: UserPostList
      },
      {
        path: 'BingoLiveEvent',
        name: 'BingoLiveEvent.List',
        component: BingoLiveEventList
      },
      {
        path: 'viewers',
        name: 'Viewer.List',
        component: ViewerList
      },
      {
        path: 'viewer/:authorChannelId',
        name: 'Viewer.Statistics',
        component: ViewerStatistics
      },
      {
        path: 'viewer/:authorChannelId/:liveChatId',
        name: 'Viewer.PointLog',
        component: ViewerPointLog
      }
    ]
  }
]
export default list
