import api from '@/utils/_api'
import auth from '@/utils/_auth'
// import popup from '@/components/popup/index.js'

export const login = ({ getters }, credentials) => {
  return api.login(getters, credentials)
    .then((res) => {
      auth.setLogin(res.data)
      return res.data
    })
}
export const logout = ({ getters }, credentials) => {
  return api.logout(getters, credentials)
    .then((res) => {
      return res.data
    })
}
export const isLogin = ({ getters }, credentials) => {
  return api.isLogin(getters, credentials)
    .then((res) => {
      return res.data
    })
}
export const recordLog = ({ getters }, credentials) => {
  return api.recordLog(getters, credentials)
    .then((res) => {
      return res.data
    })
}

export const GoogleOAuth = ({ getters }, type, credentials) => {
  return api.GoogleOAuth(getters, type, credentials)
    .then((res) => {
      return res.data
    })
}
export const getData = ({ getters }, type, credentials) => {
  return api.getData(getters, type, credentials)
    .then((res) => {
      return res.data
    })
}
export const getList = ({ getters }, action, type, param = null, search = null) => {
  return api.getList(getters, action, type, param, search)
    .then((res) => {
      return res.data
    })
}

export const roleCreate = ({ getters }, credentials) => {
  return api.roleCreate(getters, credentials)
    .then((res) => {
      return res.data
    })
}
export const roleUpdate = ({ getters }, credentials) => {
  return api.roleUpdate(getters, credentials)
    .then((res) => {
      return res.data
    })
}

export const register = ({ getters }, credentials) => {
  return api.register(getters, credentials)
    .then((res) => {
      auth.setLogin(res.data)
      return res.data
    })
}
export const userCreate = ({ getters }, credentials) => {
  return api.userCreate(getters, credentials)
    .then((res) => {
      return res.data
    })
}

export const userUpdate = ({ getters }, credentials) => {
  return api.userUpdate(getters, credentials)
    .then((res) => {
      return res.data
    })
}
export const passwordUpdate = ({ getters }, credentials) => {
  return api.passwordUpdate(getters, credentials)
    .then((res) => {
      return res.data
    })
}

export const gameTypeCreate = ({ getters }, credentials) => {
  return api.gameTypeCreate(getters, credentials)
    .then((res) => {
      return res.data
    })
}
export const gameTypeUpdate = ({ getters }, credentials) => {
  return api.gameTypeUpdate(getters, credentials)
    .then((res) => {
      return res.data
    })
}

export const seasonCreate = ({ getters }, credentials) => {
  return api.seasonCreate(getters, credentials)
    .then((res) => {
      return res.data
    })
}
export const seasonUpdate = ({ getters }, credentials) => {
  return api.seasonUpdate(getters, credentials)
    .then((res) => {
      return res.data
    })
}
export const seasonCopy = ({ getters }, credentials) => {
  return api.seasonCopy(getters, credentials)
    .then((res) => {
      return res.data
    })
}
export const getLastSeason = ({ getters }, credentials) => {
  return api.getLastSeason(getters, credentials)
    .then((res) => {
      return res.data
    })
}

export const winningTypeCreate = ({ getters }, credentials) => {
  return api.winningTypeCreate(getters, credentials)
    .then((res) => {
      return res.data
    })
}
export const winningTypeUpdate = ({ getters }, credentials) => {
  return api.winningTypeUpdate(getters, credentials)
    .then((res) => {
      return res.data
    })
}
export const winningTypeListByGameType = ({ getters }, credentials) => {
  return api.winningTypeListByGameType(getters, credentials)
    .then((res) => {
      return res.data
    })
}

export const teamCreate = ({ getters }, credentials) => {
  return api.teamCreate(getters, credentials)
    .then((res) => {
      return res.data
    })
}
export const teamUpdate = ({ getters }, credentials) => {
  return api.teamUpdate(getters, credentials)
    .then((res) => {
      return res.data
    })
}

export const playerCreate = ({ getters }, credentials) => {
  return api.playerCreate(getters, credentials)
    .then((res) => {
      return res.data
    })
}
export const playerUpdate = ({ getters }, credentials) => {
  return api.playerUpdate(getters, credentials)
    .then((res) => {
      return res.data
    })
}
export const playerAddTeam = ({ getters }, credentials) => {
  return api.addTeam(getters, credentials)
    .then((res) => {
      return res.data
    })
}
export const playerDetail = ({ getters }, credentials) => {
  return api.playerDetail(getters, credentials)
    .then((res) => {
      return res.data
    })
}
export const playerDetailBySeason = ({ getters }, credentials) => {
  return api.playerDetailBySeason(getters, credentials)
    .then((res) => {
      return res.data
    })
}
export const playerSeasonDetail = ({ getters }, credentials) => {
  return api.playerSeasonDetail(getters, credentials)
    .then((res) => {
      return res.data
    })
}
export const teamDetailBySeason = ({ getters }, credentials) => {
  return api.teamDetailBySeason(getters, credentials)
    .then((res) => {
      return res.data
    })
}
export const teamDetailByLiveshow = ({ getters }, credentials) => {
  return api.teamDetailByLiveshow(getters, credentials)
    .then((res) => {
      return res.data
    })
}

export const liveCreate = ({ getters }, credentials) => {
  return api.liveCreate(getters, credentials)
    .then((res) => {
      return res.data
    })
}
export const liveEnd = ({ getters }, credentials) => {
  return api.liveEnd(getters, credentials)
    .then((res) => {
      return res.data
    })
}
export const liveDetail = ({ getters }, credentials) => {
  return api.liveDetail(getters, credentials)
    .then((res) => {
      return res.data
    })
}
export const gameCreate = ({ getters }, credentials) => {
  return api.gameCreate(getters, credentials)
    .then((res) => {
      return res.data
    })
}
export const roundCreate = ({ getters }, credentials) => {
  return api.roundCreate(getters, credentials)
    .then((res) => {
      return res.data
    })
}
export const handCreate = ({ getters }, credentials) => {
  return api.handCreate(getters, credentials)
    .then((res) => {
      return res.data
    })
}
export const handUpdate = ({ getters }, credentials) => {
  return api.handUpdate(getters, credentials)
    .then((res) => {
      return res.data
    })
}

export const VoteCreate = ({ getters }, credentials) => {
  return api.VoteCreate(getters, credentials)
    .then((res) => {
      return res.data
    })
}
export const VoteStart = ({ getters }, credentials) => {
  return api.VoteStart(getters, credentials)
    .then((res) => {
      return res.data
    })
}
export const VoteEnd = ({ getters }, credentials) => {
  return api.VoteEnd(getters, credentials)
    .then((res) => {
      return res.data
    })
}

export const LotteryList = ({ getters }, credentials) => {
  return api.LotteryList(getters, credentials)
    .then((res) => {
      return res.data
    })
}
export const LotteryCreate = ({ getters }, credentials) => {
  return api.LotteryCreate(getters, credentials)
    .then((res) => {
      return res.data
    })
}
export const LotteryGetResult = ({ getters }, credentials) => {
  return api.LotteryGetResult(getters, credentials)
    .then((res) => {
      return res.data
    })
}
export const LotterySetPosition = ({ getters }, credentials) => {
  return api.LotterySetPosition(getters, credentials)
    .then((res) => {
      return res.data
    })
}
export const LotteryDraw = ({ getters }, credentials) => {
  return api.LotteryDraw(getters, credentials)
    .then((res) => {
      return res.data
    })
}
export const LotteryReDraw = ({ getters }, credentials) => {
  return api.LotteryReDraw(getters, credentials)
    .then((res) => {
      return res.data
    })
}

export const getTeamBySeason = ({ getters }, credentials) => {
  return api.getTeamBySeason(getters, credentials)
    .then((res) => {
      return res.data
    })
}
export const getLastSeasonChampion = ({ getters }, credentials) => {
  return api.getLastSeasonChampion(getters, credentials)
    .then((res) => {
      return res.data
    })
}
export const getTeamByLiveShow = ({ getters }, credentials) => {
  return api.getTeamByLiveShow(getters, credentials)
    .then((res) => {
      return res.data
    })
}

export const getPointByPlayer = ({ getters }, credentials) => {
  return api.getPointByPlayer(getters, credentials)
    .then((res) => {
      return res.data
    })
}

export const viewerGet = ({ getters }, credentials) => {
  return api.viewerGet(getters, credentials)
    .then((res) => {
      return res.data
    })
}
export const viewerList = ({ getters }, credentials) => {
  return api.viewerList(getters, credentials)
    .then((res) => {
      return res.data
    })
}
export const viewerStatistics = ({ getters }, credentials) => {
  return api.viewerStatistics(getters, credentials)
    .then((res) => {
      return res.data
    })
}
export const viewerPointLog = ({ getters }, credentials) => {
  return api.viewerPointLog(getters, credentials)
    .then((res) => {
      return res.data
    })
}

export const liveDelete = ({ getters }, credentials) => {
  return api.liveDelete(getters, credentials)
    .then((res) => {
      return res.data
    })
}

export const YoutubeInfo = ({ getters }, credentials) => {
  return api.YoutubeInfo(getters, credentials)
    .then((res) => {
      return res.data
    })
}
export const YoutubeSet = ({ getters }, credentials) => {
  return api.YoutubeSet(getters, credentials)
    .then((res) => {
      return res.data
    })
}
export const YoutubeEnd = ({ getters }, credentials) => {
  return api.YoutubeEnd(getters, credentials)
    .then((res) => {
      return res.data
    })
}
export const RegistrationList = ({ getters }, credentials) => {
  return api.RegistrationList(getters, credentials)
    .then((res) => {
      return res.data
    })
}
export const disableData = ({ getters }, credentials) => {
  return api.disableData(getters, credentials)
    .then((res) => {
      return res.data
    })
}
export const highlightData = ({ getters }, credentials) => {
  return api.highlightData(getters, credentials)
    .then((res) => {
      return res.data
    })
}
export const getUserPostCurrentIndex = ({ getters }, credentials) => {
  return api.getUserPostCurrentIndex(getters, credentials)
    .then((res) => {
      return res.data
    })
}
export const updateUserPost = ({ getters }, credentials) => {
  return api.updateUserPost(getters, credentials)
    .then((res) => {
      return res.data
    })
}

export const bingoBind = ({ getters }, credentials) => {
  return api.bingoBind(getters, credentials)
    .then((res) => {
      return res.data
    })
}

export const updatePrintStatus = ({ getters }, credentials) => {
  return api.updatePrintStatus(getters, credentials)
    .then((res) => {
      return res.data
    })
}
export const getPrintIndex = ({ getters }, credentials) => {
  return api.getPrintIndex(getters, credentials)
    .then((res) => {
      return res.data
    })
}
