export const UPDATE_PERMISSION = (state, param) => {
  state.auth.permissions = param
}

export const OPEN_LOADING = (state) => {
  state.loading = true
  // state.loading = false
}
export const CLOSE_LOADING = (state) => {
  state.loading = false
}

export const UPDATE_AUTH = (state, param) => {
  state.auth.sid = param.sid
  state.auth.username = param.name
  state.auth.roleID = param.roleID
  state.auth.phone = param.phone
  state.auth.email = param.email
}

export const UPDATE_TEAM = (state, param) => {
  state.mahjong.usualUpdate.teams = param
}

export const UPDATE_LIVESHOWLISTS = (state, param) => {
  const data = []
  param.forEach((opt, idx) => {
    if (idx < 3) {
      data.push(opt)
    }
  })
  state.allLiveshowList = data
}

export const UPDATE_WINTYPE = (state, param) => {
  state.mahjong.liveUpdate.wintype = param
}

export const UPDATE_SEAT_PLAYER = (state, param) => {
  state.mahjong.usualUpdate.seats.forEach((seat) => {
    if (state.dialog.data.position === seat.position) {
      seat.setPlayer = true
      seat.team = param.team
      seat.player = param.player
      seat.teamid = param.team.id
      seat.playerId = param.player.id
    }
  })
}

export const UPDATE_LIVEDETAIL = (state, param) => {
  state.mahjong.liveDetail = param
}

export const UPDATE_DIALOG = (state, param) => {
  Object.keys(state.dialog).forEach((item) => {
    Object.keys(param).forEach((data) => {
      if (item === data) {
        state.dialog[item] = param[data]
      }
    })
  })
}

export const UPDATE_CHAMPION = (state, param) => {
  state.mahjong.liveUpdate.lastChampion = param
}
export const UPDATE_WINCARD = (state, param) => {
  state.mahjong.liveUpdate.setWinCard = param
}

export const UPDATE_SEAT_POINT = (state, param) => {
  param.forEach((team) => {
    team.players.forEach((player) => {
      state.mahjong.usualUpdate.seats.forEach((seat) => {
        if (player.playerId === seat.playerId) {
          seat.point = player.detail.points
        }
      })
    })
  })
}

export const CLEAR_SEAT_PLAYER = (state, param) => {
  state.mahjong.usualUpdate.seats.forEach((seat) => {
    if (param.position === seat.position) {
      seat.setPlayer = false
      seat.team = null
      seat.player = null
      seat.teamid = null
      seat.playerId = null
    }
  })
}

export const SET_RIICHIED = (state, param) => {
  state.mahjong.usualUpdate.riichied = param
}

export const CLEAR_RIICHI = (state) => {
  state.mahjong.usualUpdate.seats.forEach((seat) => {
    seat.riichi = false
  })
}

export const CLEAR_EYEEVENT = (state) => {
  state.mahjong.usualUpdate.seats.forEach((seat) => {
    seat.earEvent = false
  })
}

export const CLEAR_SEAT_HAND_POINT = (state, param = false) => {
  state.mahjong.usualUpdate.seats.forEach((seat) => {
    seat.hand_point = 0
    if (seat.riichi) {
      (param) ? seat.hand_point -= 2 : seat.hand_point -= 1
    }
  })
}

export const CLEAR_SEAT_TEMP_POINT = (state) => {
  state.mahjong.usualUpdate.seats.forEach((seat) => {
    seat.temp_point = 0
  })
}

export const CLEAR_STATUS = (state) => {
  state.mahjong.liveUpdate.wintype = []
  state.mahjong.liveUpdate.season = {}
  state.mahjong.liveUpdate.liveShow = {}
  state.mahjong.liveUpdate.game = {}
  state.mahjong.liveUpdate.round = {}
}

export const CLEAR_DIALOG = (state) => {
  state.dialog.visible = false
  Object.keys(state.dialog).forEach((item) => {
    if (item !== 'visible') {
      state.dialog[item] = ''
    }
  })
}

export const ASSIGN_STATE_VALUE = (state, param) => {
  const aryPath = param.path.split('.')
  let tmpState = state
  aryPath.forEach((c, i) => {
    if (i === aryPath.length - 1) {
      tmpState[c] = param.value
    } else {
      tmpState = tmpState[c]
    }
  })
}

export const COMBO_DEALER = (state) => {
  state.mahjong.usualUpdate.seats.forEach((seat) => {
    seat.temp_point = 0
    if (seat.isHost) {
      seat.combo++
    }
  })
}

export const ROLLBACK_DEALER = (state, param) => {
  state.mahjong.usualUpdate.dealer = param.dealer
  state.mahjong.usualUpdate.prevailing = param.prevailing
}

export const TRUN_DEALER = (state) => {
  var dealer = state.mahjong.usualUpdate.dealer
  var prevailing = state.mahjong.usualUpdate.prevailing
  var next_dealer = findNext(dealer, 'hand')
  var next_prevailing = findNext(prevailing, 'round')

  state.mahjong.usualUpdate.seats.forEach((seat) => {
    seat.temp_point = 0
    seat.combo = 0
    if (seat.position === next_dealer) {
      seat.isHost = true
    } else {
      seat.isHost = false
    }
  })
  state.mahjong.usualUpdate.dealer = next_dealer
  state.mahjong.liveUpdate.setNewGame = false
  state.mahjong.liveUpdate.setNewRound = false
  if (next_dealer === 'e') {
    state.mahjong.usualUpdate.prevailing = next_prevailing
    if (next_prevailing !== 'e') {
      state.mahjong.liveUpdate.setNewRound = true
    }
  }
  if (next_dealer === 'e' && next_prevailing === 'e') {
    state.mahjong.liveUpdate.setNewGame = true
  }

  function findNext($this) {
    var next
    switch ($this) {
      case 'e':
        next = 's'
        break
      case 's':
        next = 'w'
        break
      case 'w':
        next = 'n'
        break
      case 'n':
        next = 'e' // create new rounds
        break
    }
    return next
  }
}

export const RESET_EDITDATA = (state, param) => {
  state.mahjong.usualUpdate.dealer = param.dealer
  state.mahjong.usualUpdate.prevailing = param.prevailing
  state.mahjong.usualUpdate.seats = param.tempSeats
}

export const UPDATE_STATUS = (state, param) => {
  Object.keys(state.mahjong.liveUpdate).forEach((item) => {
    Object.keys(param).forEach((data) => {
      if (item === data) {
        state.mahjong.liveUpdate[data] = param[data]
      }
    })
  })
}

