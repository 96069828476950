<template>
  <div>
    <div class="row">
      <div class="col-md-3">
        <div class="input-group col-mb-3">
          <input v-model="realName" type="text" class="form-control" :disabled="mail !== '' || phone !== ''" placeholder="真實姓名">
          <span class="input-group-text search" @click="search"><span class="ti-search" /></span>
        </div>
      </div>
      <div class="col-md-3">
        <div class="input-group col-mb-3">
          <input v-model="phone" type="text" class="form-control" :disabled="realName !== '' || mail !== ''" placeholder="電話號碼">
          <span class="input-group-text search" @click="search"><span class="ti-search" /></span>
        </div>
      </div>
      <div class="col-md-3">
        <div class="input-group col-mb-3">
          <input v-model="mail" type="text" class="form-control" :disabled="realName !== '' || phone !== ''" placeholder="EMAIL">
          <span class="input-group-text search" @click="search"><span class="ti-search" /></span>
        </div>
      </div>
      <div class="col-md-3">
        <div class="btn btn-xs btn-danger" style="margin-top: 0;" @click="clean()">clean</div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="table">
            <table class="table">
              <thead class=" text-primary">
                <tr>
                  <th>
                    <div class="btn-group" role="group" aria-label="Basic example">
                      <div class="btn btn-xs btn-primary" @click="sort('highlight')"><span class="ti-star" /></div>
                      <div class="btn btn-xs btn-light" @click="sort('disable')"><span class="ti-trash" /></div>
                    </div>
                  </th>
                  <th>ID</th>
                  <th>真實姓名</th>
                  <th @click="sort('gender')">性別</th>
                  <th>異性隊友</th>
                  <th @click="sort('birthday')">生日</th>
                  <th>電話號碼</th>
                  <th>Email</th>
                  <th>職業</th>
                  <th>牌齡</th>
                  <th @click="sort('experience')">麻將節目/比賽</th>
                  <th>Instagram</th>
                  <th>Facebook</th>
                  <th>Youtube</th>
                  <th>Line</th>
                  <th @click="sort('isOutbound')">出境紀錄</th>
                  <th @click="sort('withPhotogenic')">配合上鏡</th>
                  <th @click="sort('haveTime')">時間配合</th>
                  <th>個人照</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item,i) in viewers" :key="i" :class="{'table-secondary':item.disable,'table-warning':item.highlight}">
                  <td>
                    <div class="btn-group" role="group" aria-label="Basic example">
                      <div class="btn btn-xs btn-primary" @click="highlightThis(item.id)"><span class="ti-star" /></div>
                      <div class="btn btn-xs btn-light" @click="disableThis(item.id)"><span class="ti-trash" /></div>
                    </div>
                  </td>
                  <td v-text="item.id" />
                  <td style="width:4rem" v-text="item.realName" />
                  <td v-text="(item.gender == 1)?'男':'女'" />
                  <td style="width:4rem" v-text="item.partnerName" />
                  <td v-text="item.birthday" />
                  <td v-text="item.phone" />
                  <td v-text="item.mail" />
                  <td v-text="item.profession" />
                  <td v-text="item.mahjongAge" />
                  <td v-text="item.experience" />
                  <td>
                    <a :href="'https://www.instagram.com/'+item.ig" target="_blank">Instagram</a>
                  </td>
                  <td v-text="item.fb" />
                  <td v-text="item.yt" />
                  <td v-text="item.line" />
                  <td v-text="(item.isOutbound == 1)?'是':'否'" />
                  <td v-text="(item.withPhotogenic == 1)?'是':'否'" />
                  <td v-text="(item.haveTime == 1)?'是':'否'" />

                  <td>
                    <a :href="item.img" target="_blank">
                      <img style="width: 250px" :src="item.img">
                    </a>
                  </td>

                </tr>
              </tbody>
            </table>
          </div>
          <nav v-show="totalPage>1" aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item">
                <a class="page-link" aria-label="Previous" @click="changePage(1)">
                  <span aria-hidden="true">&laquo;</span>
                </a>
              </li>
              <li v-for="(page,i) in pages" :key="i" class="page-item" :class="(currentPage ==page)?'active':''"><a class="page-link" @click="changePage(page)" v-text="page" /></li>
              <li class="page-item">
                <a class="page-link" aria-label="Next" @click="changePage(totalPage)">
                  <span aria-hidden="true">&raquo;</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
export default {
  name: 'RegistrationList',
  data: function() {
    return {
      viewers: [],
      authorChannelId: '',
      realName: '',
      phone: '',
      mail: '',
      sortBy: '',
      orderBy: 'ASC',
      currentPage: 1,
      totalPage: 1
    }
  },
  computed: {
    pages: function() {
      var data = []
      if (this.currentPage > 1) {
        data.push(this.currentPage - 1)
        data.reverse()
        for (let i = 0; i < 9; i++) {
          if (this.currentPage + i < this.totalPage) {
            data.push(this.currentPage + i)
          }
        }
      } else {
        for (let i = 0; i < 10; i++) {
          if (this.currentPage + i <= this.totalPage) {
            data.push(this.currentPage + i)
          }
        }
      }
      return data
    }
  },
  watch: {},
  mounted() {
    this.getData()
  },
  methods: {
    search: function() {
      this.$store.commit('OPEN_LOADING')
      var data = { pageSize: 50, page: 1, phone: this.phone, mail: this.mail, realName: this.realName, sortBy: this.sortBy, orderBy: this.orderBy }
      this.$store.dispatch('RegistrationList', { credentials: data }).then((res) => {
        if (!res.errorCode) {
          this.viewers = res.data.items
          this.totalPage = res.data.totalPage
          this.$store.commit('CLOSE_LOADING')
        }
      })
    },
    sort: function(type) {
      this.currentPage = 1
      if (this.sortBy === '') {
        this.sortBy = type
        this.getData()
      } else {
        if (this.sortBy === type) {
          this.orderBy = (this.orderBy === 'ASC') ? 'DESC' : 'ASC'
          this.getData()
        } else {
          this.sortBy = type
          this.orderBy = 'ASC'
          this.getData()
        }
      }
    },
    disableThis: function(id) {
      this.$store.dispatch('disableData', { credentials: id }).then((res) => {
        if (!res.errorCode) {
          this.$store.commit('CLOSE_LOADING')
          this.getData()
        }
      })
    },
    highlightThis: function(id) {
      this.$store.dispatch('highlightData', { credentials: id }).then((res) => {
        if (!res.errorCode) {
          this.$store.commit('CLOSE_LOADING')
          this.getData()
        }
      })
    },
    clean: function() {
      this.sortBy = ''
      this.realName = ''
      this.phone = ''
      this.mail = ''
      this.getData()
    },
    getData: function() {
      this.$store.commit('OPEN_LOADING')
      var data = { pageSize: 50, page: this.currentPage, phone: this.phone, mail: this.mail, realName: this.realName, sortBy: this.sortBy, orderBy: this.orderBy }
      this.$store.dispatch('RegistrationList', { credentials: data }).then((res) => {
        if (!res.errorCode) {
          this.viewers = res.data.items
          this.totalPage = res.data.totalPage
          this.$store.commit('CLOSE_LOADING')
        }
      })
    },
    changePage: function(page) {
      this.$store.commit('OPEN_LOADING')
      this.currentPage = page++
      var data = { pageSize: 50, page: this.currentPage, phone: this.phone, mail: this.mail, realName: this.realName, sortBy: this.sortBy, orderBy: this.orderBy }
      this.$store.dispatch('RegistrationList', { credentials: data }).then((res) => {
        if (!res.errorCode) {
          window.scrollTo(0, 0)
          this.viewers = res.data.items
          this.totalPage = res.data.totalPage
          this.currentPage = res.data.currentPage
          this.$store.commit('CLOSE_LOADING')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.search {
  cursor: pointer;
}

.btn-group {
  .btn {
    width: 3rem;
    height: 2rem;
    padding: .5rem 1rem;
  }
}
</style>
