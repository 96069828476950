<template>
  <div class="content ">
    <div class="list">
      <div v-for="(seat,i) in this.$dataManager.getters.mahjong.usualUpdate.seats" :key="i">
        <div
          class="_row player-label"
          :class="(loser.player.id === seat.player.id)? 'disabled':''"
          @click="setMain(seat)"
        >
          <div class="name" v-text="seat.player.displayName || seat.player.name" />
          <div class="point" :class="$dataManager.pointColor(seat.hand_point)" v-text="seat.hand_point" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SetLosePanel',
  components: {},
  props: ['loser', 'comboMode', 'riichied'],
  data() {
    return {
      emtAry: [],
      handsPoint: [],
      handsData: []
    }
  },
  computed: {},
  watch: {},
  mounted() {
    this.$bus._events['setPoint'] = null
    this.$bus._events['cleanHand'] = null
    this.$bus.$on('setPoint', (data) => {
      this.comfrim(data)
    })

    if (!this.$bus._events['cleanHand']) {
      this.$bus.$on('cleanHand', () => {
        this.cleanHand()
      })
    }
  },
  methods: {
    setMain: function(seat) {
      if (seat.player.id !== this.loser.player.id) {
        var data = {
          visible: true,
          title: '選擇牌型',
          type: 'setPoint',
          data: {
            type: 'lose',
            player: seat
          }
        }
        this.$store.commit('UPDATE_DIALOG', data)
      }
    },
    cleanHand: function() {
      this.handsData = []
      this.handsPoint = []
      this.$store.commit('CLEAR_SEAT_HAND_POINT', this.comboMode)
    },
    whoGetRiichi: function(winners, lose = this.loser.position) {
      console.log(winners)
      var next = ''
      switch (lose) {
        case 'e':
          next = 's'
          break
        case 's':
          next = 'w'
          break
        case 'w':
          next = 'n'
          break
        case 'n':
          next = 'e'
          break
      }
      if (winners.indexOf(next) === -1) {
        next = this.whoGetRiichi(winners, next)
      }
      return next
    },
    comfrim: function(data) {
      this.$store.commit('CLEAR_DIALOG')
      var idx = 0
      this.handsPoint.forEach((point) => {
        if (point.playerId === data.playerId) {
          this.handsPoint.splice(idx, 1)
        }
        idx++
      })

      this.handsPoint.push(data)
      this.calcHands()
    },
    calcHands: function() {
      var handPoints = []
      var total = 0
      var riichi = 0

      var winner = []
      var lose = {
        winTypes: []
      }

      /** **  胡牌  ****/
      this.$dataManager.getters.mahjong.usualUpdate.seats.forEach((seat) => {
        this.handsPoint.forEach((point) => {
          if (seat.playerId === point.playerId) {
            var dealerBase = 0
            if (seat.isHost) dealerBase = (seat.combo * 2 + 1)
            if (this.loser.isHost) dealerBase = (this.loser.combo * 2 + 1)

            const thisPoint = (this.comboMode) ? (point.points + dealerBase) * 2 : point.points + dealerBase
            seat.hand_point = thisPoint
            total += thisPoint
            const data = {
              result: 1,
              playerId: seat.playerId,
              points: thisPoint,
              bann: point.bann + dealerBase,
              winTypes: point.winTypes
            }
            handPoints.push(data)

            const losedata = {
              result: 1,
              playerId: this.loser.playerId,
              points: 0 - thisPoint,
              bann: 0,
              winTypes: []
            }
            handPoints.push(losedata)

            winner.push(seat.position)
          }
        })
      })

      /** **  眼牌分數  ****/
      this.$dataManager.getters.mahjong.usualUpdate.seats.forEach((seat) => {
        if (seat.riichi) riichi++
      })
      riichi = (this.comboMode) ? riichi * 2 : riichi
      riichi += this.riichied

      this.$dataManager.getters.mahjong.usualUpdate.seats.forEach((seat) => {
        this.handsPoint.forEach((point) => {
          if (seat.playerId === point.playerId) {
            if (seat.position === this.whoGetRiichi(winner) && riichi > 0) {
              seat.points += riichi
              seat.hand_point += riichi
              const data = {
                result: 4,
                playerId: seat.playerId,
                points: riichi,
                bann: 0,
                winTypes: []
              }
              handPoints.push(data)
            }
            if (seat.riichi) {
              seat.points = (this.comboMode) ? seat.points - 2 : seat.points - 1
              seat.hand_point = (this.comboMode) ? seat.hand_point - 2 : seat.hand_point - 1
            }
          }
        })
        if (seat.riichi) {
          const data = {
            result: 4,
            playerId: seat.playerId,
            points: (this.comboMode) ? -2 : -1,
            bann: 0,
            winTypes: []
          }
          handPoints.push(data)
        }
      })

      this.$dataManager.getters.mahjong.usualUpdate.seats.forEach((seat) => {
        if (seat.playerId === this.loser.playerId) {
          lose.playerId = seat.playerId
          lose.points = 0 - total
          lose.bann = 0
          seat.hand_point = 0 - total
          if (seat.riichi) {
            seat.points = (this.comboMode) ? seat.points - 2 : seat.points - 1
            seat.hand_point = (this.comboMode) ? seat.hand_point - 2 : seat.hand_point - 1
          }
        }
      })
      this.handsData = handPoints
      this.$emit('setHands', this.handsData)
    }
  }
}
</script>

<style lang="scss" scoped>
  .list {
    margin: 4px 4px 2rem;

    ._row {
      display: flex;
      margin: 4px auto;

      .name {
        flex: 3;
      }

      .point {
        flex: 2;
        font-weight: 700;
        color: #fff;
      }
    }
  }
</style>
