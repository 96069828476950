<template>
  <div class="login">
    <div class="alogin">
      <h3>Live Manager System</h3>
      <div class="login-input">
        <div class="wrap-input100 validate-input">
          <input v-model="form.account" class="input100" placeholder="Account" type="text" @keyup.enter="checkForm">
        </div>
        <div class="wrap-input100 validate-input">
          <input v-model="form.password" class="input100" placeholder="Password" type="password" @keyup.enter="checkForm">
        </div>
      </div>
      <div class="login-btn" @click="checkForm">submit</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data: function() {
    return {
      form: {
        account: '',
        password: ''
      }
    }
  },
  computed: {},
  mounted() {
    this.logout()
    localStorage.removeItem('store')
  },
  methods: {
    checkForm() {
      if (Object.values(this.form).some(it => !it)) {
        this.$alert({ type: 'warn', msg: 'Please fill all param before submit.', duration: 3 * 1000 })
      } else {
        this.submit()
      }
    },
    submit: function() {
      this.$store.dispatch('login', { credentials: this.form }).then((res) => {
        if (!res.errorCode) {
          this.$store.commit('CLOSE_LOADING')
          this.$router.push({ name: 'Season.List' })
        }
      })
    },
    logout: function() {
      this.$store.dispatch('logout')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .login {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .alogin {
      max-width: 40vw;
      margin: 1rem auto;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      padding: 1rem;
      .login-input{
        display: flex;
        flex-direction: column;
        .wrap-input100 {
          width: 100%;
          position: relative;
          border-bottom: 2px solid #adadad;
          margin-bottom: 1rem;
          .input100 {
            font-size: 15px;
            color: #555555;
            border: 0;
            line-height: 1.2;
            display: block;
            width: 100%;
            height: 45px;
            background: transparent;
            padding: 0 5px;
            &:focus{
              border: 0;
            }
          }
          .focus-input100 {
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            top: -10px;
            left: 0;
            pointer-events: none;
          }
        }
      }
    }
  }
</style>
