<template>
  <div class="card card-user">
    <div class="card-body">
      <form>
        <div class="form-group row">
          <label class="col-md-2 col-form-label">隊伍</label>
          <div class="col-md-10">
            <select v-model="player.teamId" class="custom-select">
              <option v-for="(item,i) in teams" :key="i" :value="item.id" v-text="item.name" />
            </select>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-2 col-form-label">System Name</label>
          <div class="col-md-10">
            <input v-model="player.name" type="text" class="form-control">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-2 col-form-label">Display Name</label>
          <div class="col-md-10">
            <input v-model="player.displayName" type="text" class="form-control">
          </div>
        </div>
        <!--        <div class="form-group row">-->
        <!--          <label class="col-md-2 col-form-label">加權</label>-->
        <!--          <div class="col-md-10">-->
        <!--            <input type="text" class="form-control" v-model="player.weighted">-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="row">
          <div class="update ml-auto mr-auto">
            <div class="btn btn-primary btn-round" @click="submit">送出</div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlayerCreate',
  data: function() {
    return {
      teams: [],
      users: [],
      player: {
        userId: this.$route.params.userId,
        teamId: '',
        name: '',
        displayName: '',
        weighted: 1
      }
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    submit: function() {
      this.$store.commit('OPEN_LOADING')

      this.$store.dispatch('playerCreate', { credentials: this.player }).then((res) => {
        if (!res.errorCode) {
          this.$store.commit('CLOSE_LOADING')

          this.$router.push({ name: 'Player.List' })
        }
      })
    },
    getData: function() {
      this.$store.commit('OPEN_LOADING')

      this.$store.dispatch('getList', { type: 'Team', action: 'list' }).then((res) => {
        if (!res.errorCode) {
          this.teams = res.data
          this.$store.dispatch('getList', { type: 'User', action: 'list' }).then((res) => {
            if (!res.errorCode) {
              this.users = res.data
              this.$store.commit('CLOSE_LOADING')
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
