import Root from '@/components/Root'

import UserUpdate from '@/components/User/Update'
import RoleUpdate from '@/components/Role/Update'
import GameTypeUpdate from '@/components/GameType/Update'
import SeasonUpdate from '@/components/Season/Update'
import WinningTypeUpdate from '@/components/WinningType/Update'
import TeamUpdate from '@/components/Team/Update'
import PlayerUpdate from '@/components/Player/Update'

const update = [{
  path: '/app/update',
  name: 'Update',
  component: Root,
  children: [

    {
      path: 'role/update/:id',
      name: 'Role.Update',
      component: RoleUpdate
    },

    {
      path: 'user/update/:id',
      name: 'User.Update',
      component: UserUpdate
    },
    {
      path: 'gameType/update/:id',
      name: 'GameType.Update',
      component: GameTypeUpdate
    },
    {
      path: 'season/update/:id',
      name: 'Season.Update',
      component: SeasonUpdate
    },
    {
      path: 'winningType/update/:id',
      name: 'WinningType.Update',
      component: WinningTypeUpdate
    },
    {
      path: 'team/update/:id',
      name: 'Team.Update',
      component: TeamUpdate
    },
    {
      path: 'player/update/:id',
      name: 'Player.Update',
      component: PlayerUpdate
    }

  ]
}
]
export default update
