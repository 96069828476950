<template>
  <div class="today-player-points">
    <horiBar
      v-if="options.length>0"
      :datas="options"
      :bgcolor="'#000'"
      :padding="'5px 3px'"
      :bar-border-color="''"
      :bar-border-width="''"
      :font-size="'3rem'"
      :font-family="'蒙納盈富體'"
      :flex-bar="3"
      :flex-gap="1"
    />
  </div>
</template>

<script>
import horiBar from '@/components/majcmp/horiBar'

export default {
  name: 'BarChat',
  components: { horiBar },
  data() {
    return {
      id: this.$root.voteSetting.voteId,
      transparent: 'rgba(0,0,0,0)',
      vote: {
        title: '',
        options: []
      },
      interval: {}
    }
  },
  computed: {
    options() {
      var res = []
      this.vote.options.forEach((opt, idx) => {
        var item = {}
        item.count = opt.count
        item.color = opt.color
        item.name = opt.title
        item.iconSvg = '<svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M37.93 75.86C58.8782 75.86 75.86 58.8782 75.86 37.93C75.86 16.9818 58.8782 0 37.93 0C16.9818 0 0 16.9818 0 37.93C0 58.8782 16.9818 75.86 37.93 75.86Z" fill="none"/> </svg>'
        res.push(item)
      })
      return res
    }
  },
  watch: {},
  mounted() {
    this.$bus.$on('voteSetting', content => {
      setTimeout(() => {
        this.dataInterval(content.voteId)
      }, 300)
    })
  },
  methods: {
    dataInterval: function(id) {
      clearInterval(this.interval)
      this.getData(id)
      var $this = this
      this.interval = setInterval(() => {
        $this.getData(id)
      }, 10 * 1000)
    },
    getData: function(id) {
      this.$store.dispatch('getData', { type: 'Vote', credentials: id }).then((res) => {
        if (!res.errorCode) {
          this.vote = res.data
        }
      })
    }
  }
}
</script>
