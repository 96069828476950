<template>
  <div class="card card-user">
    <div class="card-body">
      <form>
        <div class="form-group row">
          <label class="col-md-2 col-form-label">遊戲類型</label>
          <div class="col-md-10">
            <select v-model="season.gtId" class="custom-select">
              <option v-for="(item,i) in gameTypes" :key="i" :value="item.id" v-text="item.name" />
            </select>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-2 col-form-label">名稱</label>
          <div class="col-md-10">
            <input v-model="season.name" type="text" class="form-control">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-2 col-form-label">賽季類型</label>
          <div class="col-md-10">
            <select v-model="season.type" class="custom-select">
              <option v-for="(item,i) in type" :key="i" :value="item.key" v-text="item.name" />
            </select>
          </div>
        </div>
        <div v-if="season.type===3" class="form-group row">
          <label class="col-md-2 col-form-label">例行賽第一名加分</label>
          <div class="col-md-10">
            <input v-model="season.setting.rankingFirst" type="text" class="form-control">
          </div>
        </div>
        <div v-if="season.type===3" class="form-group row">
          <label class="col-md-2 col-form-label">例行賽第二名加分</label>
          <div class="col-md-10">
            <input v-model="season.setting.rankingSecond" type="text" class="form-control">
          </div>
        </div>
        <div v-if="season.type===3" class="form-group row">
          <label class="col-md-2 col-form-label">例行賽第三名加分</label>
          <div class="col-md-10">
            <input v-model="season.setting.rankingThird" type="text" class="form-control">
          </div>
        </div>
        <div v-if="season.type===3" class="form-group row">
          <label class="col-md-2 col-form-label">例行賽第四名加分</label>
          <div class="col-md-10">
            <input v-model="season.setting.rankingFourth" type="text" class="form-control">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-2 col-form-label">搜尋對伍</label>
          <div class="col-md-10">
            <input v-model="filter" type="text" class="form-control">
          </div>
        </div>
        <div v-if="showTeams" class="form-group row">
          <table class="table">
            <tr>
              <td>系統名稱</td>
              <td>顯示名稱</td>
              <td>隊伍顏色</td>
              <td>隊伍標誌</td>
              <td>#</td>
            </tr>
            <tr v-for="(team,j) in findTeams" :key="j">
              <td v-if="team.id !== undefined" v-text="team.name" />
              <td v-if="team.id !== undefined" v-text="team.displayName" />
              <td v-if="team.id === undefined">
                <input v-model="team.name" type="text" class="form-control" placeholder="System name">
              </td>
              <td v-if="team.id === undefined">
                <input v-model="team.displayName" type="text" class="form-control" placeholder="Display name">
              </td>
              <td>
                <input v-model="team.color" type="text" class="form-control" placeholder="#000000">
              </td>
              <td class="icon">
                <input v-model="team.icon" type="text" class="form-control" placeholder="#000000">
              </td>
              <td>
                <input v-model="team.active" type="checkbox">
              </td>
            </tr>
            <tr>
              <td><input v-model="team.name" type="text" class="form-control" placeholder="System name"></td>
              <td><input v-model="team.displayName" type="text" class="form-control" placeholder="Display name"></td>
              <td><input v-model="team.color" type="text" class="form-control" placeholder="#000000"></td>
              <td><input v-model="team.icon" type="text" class="form-control" placeholder="#000000"></td>
              <td>
                <div class="btn btn-xs btn-primary btn-round pull-right" @click="addTeamData">+</div>
              </td>
            </tr>
          </table>
        </div>
        <div class="row">
          <div class="update ml-auto mr-auto">
            <button type="submit" class="btn btn-primary btn-round" @click="submit">送出</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SeasonCreate',
  data: function() {
    return {
      showTeams: true,
      showLives: false,
      teams: [],
      team: {
        name: '',
        displayName: '',
        color: ' ',
        icon: '',
        active: false
      },
      filter: '',
      gameTypes: [],
      season: {
        gtId: '',
        name: '',
        type: 1,
        setting: {
          rankingFirst: 0,
          rankingSecond: 0,
          rankingThird: 0,
          rankingFourth: 0
        }
      },
      type: [
        { key: 1, name: '日間直播' },
        { key: 2, name: '一般比賽' },
        { key: 3, name: '大型季賽' }
      ]
    }
  },
  computed: {
    findTeams: function() {
      var arr = []
      if (this.filter.length > 0) {
        this.teams.forEach((item) => {
          if (item.name.indexOf(this.filter) !== -1) {
            arr.push(item)
          }
        })
      }
      return arr
    },
    teamsArr: function() {
      var arr = []
      this.teams.forEach((item) => {
        if (item.active === true) {
          arr.push(item)
        }
      })
      return arr
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    makeIcon: function(team) {
      return team.icon.replace('fill="none"', 'fill="' + team.color + '"')
    },
    addTeamData: function() {
      this.teams.push(this.team)
      this.team = {
        name: '',
        displayName: '',
        color: '',
        icon: ''
      }
    },
    submit: function() {
      this.$store.commit('OPEN_LOADING')
      this.season.teams = this.teamsArr
      this.$store.dispatch('seasonCreate', { credentials: this.season }).then((res) => {
        if (!res.errorCode) {
          this.$store.commit('CLOSE_LOADING')
          this.$router.push({ name: 'Season.List' })
        }
      })
    },
    getData: function() {
      this.$store.commit('OPEN_LOADING')
      this.$store.dispatch('getList', { type: 'GameType', action: 'list' }).then((res) => {
        if (!res.errorCode) {
          this.gameTypes = res.data
          this.$store.dispatch('getList', { type: 'Team', action: 'list' }).then((res) => {
            if (!res.errorCode) {
              this.teams = res.data
              this.teams.forEach((team) => {
                team.active = false
              })
              this.$store.commit('CLOSE_LOADING')
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
