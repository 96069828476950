import Vue from 'vue'
import App from './App.vue'
import store from './store'
import eventBus from '@/eventBus'
import DataManager from '@/utils/DataManager'
import Alert from '@/components/Layout/alert'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

const setting = require('@/utils/config.js')

Vue.use(ElementUI)
Vue.use(Alert)

Vue.prototype.$dataManager = new DataManager()
var firebaseConfig = (process.env.NODE_ENV === 'production') ? setting.prod.firebaseConfig : (process.env.NODE_ENV === 'test') ? setting.test.firebaseConfig : setting.stage.firebaseConfig

// eslint-disable-next-line no-undef
firebase.initializeApp(firebaseConfig)
// eslint-disable-next-line no-undef
firebase.analytics()
// eslint-disable-next-line no-undef
window.lockRef = firebase.database().ref('lockUpdate')
window.lockRef.on('value', function(snapshot) {
  window.vue.lockUpdate = snapshot.val()
})
// eslint-disable-next-line no-undef
window.liveRef = firebase.database().ref('liveSetting')
window.liveRef.on('value', function(snapshot) {
  window.vue.liveSetting = snapshot.val()
})
// eslint-disable-next-line no-undef
window.voteRef = firebase.database().ref('voteSetting')
window.voteRef.on('value', function(snapshot) {
  window.vue.voteSetting = snapshot.val()
})
// eslint-disable-next-line no-undef
window.playerRef = firebase.database().ref('playerSetting')
window.playerRef.on('value', function(snapshot) {
  window.vue.playerSetting = snapshot.val()
})
// eslint-disable-next-line no-undef
window.userPostRef = firebase.database().ref('userPostSetting')
window.userPostRef.on('value', function(snapshot) {
  window.vue.userPostSetting = snapshot.val()
})
// eslint-disable-next-line no-undef
window.version = firebase.database().ref('version')
window.version.on('value', function(snapshot) {
  if (localStorage.getItem('version') == null) {
    localStorage.setItem('version', snapshot.val())
  }
  window.vue.version = snapshot.val()
})

// eslint-disable-next-line no-undef
window.liveStrRef = firebase.database().ref('liveStr')
window.liveStrRef.on('value', function(snapshot) {
  window.vue.liveStr = snapshot.val()
})

// eslint-disable-next-line no-undef
gapi.load('client:auth2', function() {
  // eslint-disable-next-line no-undef
  gapi.auth2.init({ client_id: '182093173885-c2ps9780k04j71it1dgo7plarubparmn.apps.googleusercontent.com' })
})

import('@/router/').then((module) => {
  const router = module.default

  window.vue = new Vue({
    data() {
      return {
        // Bind our event bus to our $root Vue model
        bus: eventBus,
        lockUpdate: false,
        version: null,
        liveStr: '',
        userPostSetting: {
          lastId: 0,
          nowId: 0,
          playing: false,
          reply: false
        },
        voteSetting: {
          voteId: 0,
          liveChatId: ''
        },
        playerSetting: {
          gameBase: 0,
          floatData: 0
        },
        liveSetting: {
          onLiveShow: false,
          seasonId: 0,
          liveShowId: 0,
          gameId: 0,
          nowHands: 0,
          status: {
            riichied: 0,
            riichis: ['0'],
            eyes: ['0'],
            combo: 0,
            game: 0,
            round: '',
            wind: ''
          }
        }
      }
    },
    computed: {
      isPlayoff: function() {
        var check = false
        return check
      },
      isShowPage: function() {
        return this.$route.name.includes('Show')
      }
    },
    watch: {
      liveStr: {
        handler: function(val) {
          this.$bus.$emit('liveStrSetting', val)
          window.liveStrRef.set(val)
        },
        deep: true
      },
      userPostSetting: {
        handler: function(val) {
          this.$bus.$emit('userPostSetting', val)
          if (!this.isShowPage && val.nowId) {
            console.log('set Firebase')
            window.userPostRef.set(val)
          }
        },
        deep: true
      },
      playerSetting: {
        handler: function(val) {
          this.$bus.$emit('playerSetting', val)
          if (!this.isShowPage) {
            console.log('set Firebase')

            window.playerRef.set(val)
          }
        },
        deep: true
      },
      liveSetting: {
        handler: function(val) {
          this.$bus.$emit('liveSetting', val)
          if (!this.isShowPage && val.seasonId && val.liveShowId) {
            console.log('set Firebase')
            window.liveRef.set(val)
            this.$dataManager.recordLog(val)
          }
        },
        deep: true
      },
      voteSetting: {
        handler: function(val) {
          this.$bus.$emit('voteSetting', val)
          if (!this.isShowPage && val.voteId) {
            console.log('set Firebase')
            window.voteRef.set(val)
          }
        },
        deep: true
      },
      lockUpdate: {
        handler: function(val) {
          console.log('lock', val)
        },
        deep: true
      },
      version: {
        handler: function(val) {
          const version = JSON.parse(localStorage.getItem('version'))
          localStorage.setItem('version', val)
          if (val !== version) {
            location.reload()
          }
        },
        deep: true
      }

    },
    mounted() {
      window.$alert = this.$alert

      this.$dataManager.getters.playerKey.forEach((item) => {
        this.$dataManager.addComboKeyDown(item.key, () => {
          this.$root.playerSetting.gameBase = item.id
        }, { ctrl: true, alt: false, shift: true })
      })
      this.$dataManager.getters.teamKey.forEach((item) => {
        this.$dataManager.addComboKeyDown(item.key, () => {
          this.$root.playerSetting.floatData = item.id
        }, { ctrl: false, alt: true, shift: true })
      })
    },
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
})

