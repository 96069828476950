<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import store from '@/store'

export default {
  name: 'Root',
  mounted: function() {
    store.app = this
  }
}
</script>

<style lang="scss">
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-size: .8rem;
}
</style>
