<template>
  <div v-if="nowId" class="col-md-12">
    <div class="bingoLiveEvent">
      <div class="top">
        <div class="nowPlaying">
          目前播放: <el-tag type="info"> #{{ post.id }} {{ post.content }}</el-tag>
        </div>
      </div>
      <div class="bottom">
        <el-tooltip class="item" effect="dark" content="上一則" placement="bottom">
          <el-button icon="el-icon-arrow-left" circle @click="changeCurrentIndex(1)" />
        </el-tooltip>
        <el-tooltip v-if="!playing" class="item" effect="dark" content="重播" placement="bottom">
          <el-button type="warning" icon="el-icon-video-play" circle />
        </el-tooltip>
        <el-tooltip v-if="playing" class="item" effect="dark" content="停止" placement="bottom">
          <el-button type="info" icon="el-icon-video-pause" circle />
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="下一則" placement="bottom">
          <el-button type="success" icon="el-icon-arrow-right" circle @click="changeCurrentIndex(-1)" />
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserPostController',
  props: ['pageSize'],
  data: function() {
    return {
      playing: false,
      replay: false,
      nowId: 0,
      lastId: 0,
      post: {}
    }
  },
  computed: {},
  watch: {
    nowId(val) {
      if (val) {
        this.getDetail()
      }
    }
  },
  mounted() {
    this.getDetail()
    const $this = this
    this.$bus.$on('userPostSetting', content => {
      setTimeout(() => {
        $this.playing = content.playing
        $this.replay = content.replay
        $this.nowId = content.nowId
        $this.lastId = content.lastId
      }, 300)
    })
  },
  methods: {
    getDetail: function() {
      if (this.nowId) {
        this.$store.dispatch('getData', { type: 'UserPost', credentials: this.nowId }).then((res) => {
          if (!res.errorCode) {
            this.post = res.data
          }
        })
      }
    },

    changeCurrentIndex(val) {
      const credentials = { nowId: this.nowId, index: val, pageSize: this.pageSize }
      const setting = {
        nowId: 0,
        lastId: 0,
        playing: false,
        replay: false
      }
      this.$store.dispatch('getUserPostCurrentIndex', { credentials: credentials }).then((res) => {
        if (!res.errorCode) {
          this.lastId = this.nowId
          this.nowId = res.data.id
          setting.nowId = res.data.id
          setting.lastId = this.nowId
          setting.playing = true
          setting.replay = false
          Object.assign(this.$root.userPostSetting, setting)
          this.$bus.$emit('changeUserPostPage', res.data.page)
          console.log(this.lastId, this.nowId)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.bingoLiveEvent {
    display: flex;
    flex-direction: column;

    .top {
        height: 2rem;
        margin: .5rem 0;
    }
}

</style>

