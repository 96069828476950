<template>
  <div class="card card-user">
    <div class="card-body">
      <div v-for="(item, i) in urls" :key="i" class="form-group row">
        <label class="col-md-2 col-form-label" v-text="item.name" />
        <div class="col-md-10">
          <input
            :id="i"
            v-model="item.url"
            class="form-control"
            type="text"
            @click="copy(item, i)"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ShowsList',
  data() {
    return {}
  },
  computed: {
    urls() {
      var data = {
        todayPlayPoint: {
          name: '上方積分條',
          url:
            document.domain +
            '/' +
            this.$router.resolve({ name: 'Show.todayPlayerPoint' }).href
        },
        // todayPlayerPointSpecial: {
        //   name: '上方積分條 (小美國祭版)',
        //   url:
        //     document.domain +
        //     '/' +
        //     this.$router.resolve({ name: 'Show.todayPlayerPointSpecial' }).href
        // },
        todayPlayerPointSpecial2: {
          name: '上方積分條 (SML版)',
          url:
            document.domain +
            '/' +
            this.$router.resolve({ name: 'Show.todayPlayerPointSpecial2' })
              .href
        },
        todayPlayerPointSpecial3: {
          name: '上方積分條 (SML 無隊伍ICON版)',
          url:
            document.domain +
            '/' +
            this.$router.resolve({ name: 'Show.todayPlayerPointSpecial3' })
              .href
        },
        // todayPlayerPointSpecial4: {
        //   name: '上方積分條 (鳳雛杯)',
        //   url:
        //     document.domain +
        //     '/' +
        //     this.$router.resolve({ name: 'Show.todayPlayerPointSpecial4' })
        //       .href
        // },
        todayTotalPoint: {
          name: '當日排行榜',
          url:
            document.domain +
            '/' +
            this.$router.resolve({ name: 'Show.todayTotalPoint' }).href
        },
        preTodayTotalPoint: {
          name: '預選賽當日排行榜',
          url:
            document.domain +
            '/' +
            this.$router.resolve({ name: 'Show.preTodayTotalPoint' }).href
        },
        seasonData: {
          name: '賽季數據Table',
          url:
            document.domain +
            '/' +
            this.$router.resolve({ name: 'Show.seasonData' }).href
        },
        floatData: {
          name: '單場浮動數據',
          url:
            document.domain +
            '/' +
            this.$router.resolve({ name: 'Show.floatData' }).href
        },
        floatTsumoData: {
          name: '浮動自摸數據',
          url:
            document.domain +
            '/' +
            this.$router.resolve({ name: 'Show.floatTsumoData' }).href
        },
        floatWinData: {
          name: '浮動胡牌數據',
          url:
            document.domain +
            '/' +
            this.$router.resolve({ name: 'Show.floatWinData' }).href
        },
        floatLoseData: {
          name: '浮動放槍數據',
          url:
            document.domain +
            '/' +
            this.$router.resolve({ name: 'Show.floatLoseData' }).href
        },
        seasonTotalPoint: {
          name: '賽季排行榜',
          url:
            document.domain +
            '/' +
            this.$router.resolve({ name: 'Show.seasonTotalPoint' }).href
        },
        seasonSchedule: {
          name: '賽季排程',
          url:
            document.domain +
            '/' +
            this.$router.resolve({ name: 'Show.seasonSchedule' }).href
        },
        playerDetailBySeasonP1: {
          name: '賽季選手資訊page 1',
          url:
            document.domain +
            '/' +
            this.$router.resolve({ name: 'Show.playerDetailBySeasonP1' }).href
        },
        playerDetailBySeasonP2: {
          name: '賽季選手資訊page 2',
          url:
            document.domain +
            '/' +
            this.$router.resolve({ name: 'Show.playerDetailBySeasonP2' }).href
        },
        playerGameBaseDataP1: {
          name: '賽季選手三角形數據P1',
          url:
            document.domain +
            '/' +
            this.$router.resolve({ name: 'Show.playerGameBaseDataP1' }).href
        },
        playerGameBaseDataP2: {
          name: '賽季選手三角形數據P2',
          url:
            document.domain +
            '/' +
            this.$router.resolve({ name: 'Show.playerGameBaseDataP2' }).href
        },
        dailyShow: {
          name: 'daily直播連結',
          url:
            document.domain +
            '/' +
            this.$router.resolve({ name: 'Show.dailyShow' }).href
        },
        dailyDetail: {
          name: 'daily直播輔助連結',
          url:
            document.domain +
            '/' +
            this.$router.resolve({ name: 'Show.dailyDetail' }).href
        },
        showBarChat: {
          name: '長條圖投票顯示',
          url:
            document.domain +
            '/' +
            this.$router.resolve({ name: 'Show.voteBarChat' }).href
        },
        showPieChart: {
          name: '圓餅圖投票顯示',
          url:
            document.domain +
            '/' +
            this.$router.resolve({ name: 'Show.votePieChat' }).href
        },
        showHoriBar: {
          name: '長條圖投票顯示(橫幅)',
          url:
            document.domain +
            '/' +
            this.$router.resolve({ name: 'Show.voteHoriBar' }).href
        },
        userPostResult: {
          name: '虎杖生哥',
          url:
            document.domain +
            '/' +
            this.$router.resolve({ name: 'Show.userPostResult' }).href
        },
        LiveShowStr: {
          name: '直播顯示文字',
          url:
            document.domain +
            '/' +
            this.$router.resolve({ name: 'Show.showStr' }).href
        }
      }
      return data
    }
  },
  mounted() {
    this.$store.commit('CLOSE_LOADING')
    this.$store.commit('CLEAR_DIALOG')
  },
  methods: {
    copy(url, i) {
      const testingCodeToCopy = document.querySelector('#' + i)
      testingCodeToCopy.setAttribute('type', 'text') // 不是 hidden 才能複製
      testingCodeToCopy.select()

      document.execCommand('copy')

      /* unselect the range */
      window.getSelection().removeAllRanges()
    }
  }
}
</script>

<style scoped></style>
