<template>
  <div class="player-detail-by-season">
    <div class="main">

      <div class="table">
        <div class="header">
          <div />
          <div class="title">出場雀數</div>
          <div class="title">總得分</div>
          <div class="title">平均得分</div>
          <div class="title">單把最高</div>
          <div class="title">自摸數</div>
        </div>
        <div v-for="(player,i) in players" :key="i" class="rows">
          <div class="item">
            <div class="team-label" :style="'background-color:'+$dataManager.findTeam(player.teamId).team.color" v-text="$dataManager.findTeam(player.teamId).team.displayName" />
            <div class="name" :style="'color:'+$dataManager.findTeam(player.teamId).team.color" v-text="$dataManager.findPlayer(player.playerId).player.displayName" />
          </div>
          <div class="item" :style="'color:'+$dataManager.findTeam(player.teamId).team.color" v-text="player.data.allGames" />
          <div class="item" :style="'color:'+$dataManager.findTeam(player.teamId).team.color" v-text="player.data.points" />
          <div class="item" :style="'color:'+$dataManager.findTeam(player.teamId).team.color" v-text="roundDecimal(player.data.points/player.data.allGames,2)" />
          <div class="item" :style="'color:'+$dataManager.findTeam(player.teamId).team.color" v-text="player.data.maxPoint" />
          <div class="item" :style="'color:'+$dataManager.findTeam(player.teamId).team.color" v-text="player.data.tsumoCount" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlayerDetailBySeason',
  data() {
    return {
      season: {},
      players: {},
      isLoading: false,
      liveSetting: {}
    }
  },
  computed: {
    title: function() {
      return this.season.name ? this.season.name.indexOf('N') !== -1 ? 'N' : 'S' : ''
    },
    fontSize: function() {
      var domain = 'www.supermahjongleague.com'
      var width = window.innerWidth
      const fontSize = ((width - 80) / (1 + Math.max((domain).split('\n').map(t => t.length))))
      return 'font-size:' + fontSize + 'px;flex:0 ' + fontSize
    }
  },
  mounted() {
    this.$bus.$on('liveSetting', content => {
      this.liveSetting = content
      this.getData()
    })
  },
  methods: {
    roundDecimal: function(val, precision) {
      var data = Math.round(Math.round(val * Math.pow(10, (precision || 0) + 1)) / 10) / Math.pow(10, (precision || 0))
      return data || '-'
    },
    roundDecimalPercent: function(val, precision) {
      var data = Math.round(Math.round(val * Math.pow(10, (precision || 0) + 1)) / 10) / Math.pow(10, (precision || 0)) || '-'
      return (data !== '-') ? data + '%' : data
    },
    getData: function() {
      this.$store.commit('CLOSE_LOADING')
      this.$store.dispatch('getData', { type: 'Season', credentials: this.liveSetting.seasonId }).then((res) => {
        if (!res.errorCode) {
          this.season = res.data
          this.$store.commit('UPDATE_TEAM', res.data.teams)
          this.$store.dispatch('playerDetailBySeason', { credentials: this.liveSetting.seasonId }).then((res) => {
            if (!res.errorCode) {
              this.players = res.data
              this.isLoading = true
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import '../../../style/show/playerDetailBySeason';
</style>
