<template>
  <div class="col-md-12">
    <div class="card">
      <div class="card-body">
        <div class="table">
          <table class="table">
            <thead class=" text-primary">
              <tr>
                <th>
                  Name
                </th>
                <th>
                  <router-link
                    v-if="$dataManager.getters.permissions.Lottery.includes('create')"
                    :to="{name:'Lottery.Create',params:{voteId:id}}"
                  >
                    <div class="btn btn-xs btn-primary pull-right">建立抽獎</div>
                  </router-link>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item,i) in lottery" :key="i">
                <td v-text="item.title" />
                <td>
                  <router-link
                    v-if="$dataManager.getters.permissions.Lottery.includes('update')"
                    :to="{name:'Lottery.Detail',params:{id:item.id}}"
                  >
                    <div class="btn btn-xs btn-primary btn-round pull-right">抽獎</div>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'LotteryList',
  data: function() {
    return {
      id: this.$route.params.voteId,
      lottery: []
    }
  },
  computed: {},
  mounted() {
    this.getData()
  },
  methods: {
    getData: function() {
      this.$store.commit('OPEN_LOADING')

      this.$store.dispatch('LotteryList', { credentials: this.id }).then((res) => {
        if (!res.errorCode) {
          this.$store.commit('CLOSE_LOADING')

          this.lottery = res.data
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
