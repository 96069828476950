import Axios from './_axios'
export default {

  gameTypeCreate(getters, { credentials }) {
    const formdata = new FormData()

    formdata.append('name', credentials.name)

    const url = getters.apiUrl + 'GameType/create'
    return Axios.post(
      url, formdata
    ).then(res => {
      return res
    })
  },

  gameTypeUpdate(getters, { credentials }) {
    const formdata = new FormData()

    formdata.append('name', credentials.gameType.name)
    const url = getters.apiUrl + 'GameType/update/' + credentials.id
    return Axios.post(
      url, formdata
    ).then(res => {
      return res
    })
  }
}

