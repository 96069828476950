<template>
  <div class="pre-today-total-points">
    <div class="main">
      <div class="title">當日排行榜</div>
      <div class="sub-title upper">Ranking of the day</div>
      <div class="teams">
        <div v-for="(item,idx) in detail" :key="idx" class="team">
          <div class="sort" v-html="getRankStr(idx+1)" />
          <div class="team-label" :style="'background-color:'+$dataManager.findTeam(item.teamId).team.color" v-text="$dataManager.findTeam(item.teamId).team.displayName" />
          <div class="team-point" :style="'color:'+$dataManager.findTeam(item.teamId).team.color+';opacity: 0;'">
            <span>Team Points</span>
            <span v-text="$dataManager.findTeam(item.teamId).team.teamScore" />
          </div>
          <div class="point" :style="'color:'+$dataManager.findTeam(item.teamId).team.color" v-text="item.total" />
          <div v-for="(player,i) in item.players" :key="i" class="player" :style="'color:'+$dataManager.findTeam(item.teamId).team.color">
            <div class="name" v-text="$dataManager.findPlayer(player.playerId).player.displayName" />
            <div class="point" v-text="player.detail.points" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PreTodayTotalPoint',
  data() {
    return {
      liveTeams: [],
      teams: {},
      isLoading: false,
      liveSetting: {}
    }
  },
  computed: {
    detail: function() {
      var data = []
      this.liveTeams.forEach((item) => {
        var total = 0
        var tsumoCount = 0
        var winCount = 0
        var loseCount = 0
        this.teams.forEach((team) => {
          var playerArr = []

          if (team.id === item.teamId) {
            item.players.forEach((player) => {
              playerArr.push(player.playerId)
              total += player.detail.points
              tsumoCount += player.detail.tsumoCount
              winCount += player.detail.winCount
              loseCount += player.detail.loseCount
            })

            item.teamRank = team.teamRank
            item.teamScore = team.teamScore
            team.players.forEach((i) => {
              if (!playerArr.includes(i.id)) {
                i.detail = {
                  points: 0
                }
                i.playerId = i.id
                item.players.push(i)
              }
            })
          }
        })
        item.total = total
        item.tsumoCount = tsumoCount
        item.winCount = winCount
        item.loseCount = loseCount

        data.push(item)
      })
      data.sort(function(a, b) {
        if (b.total !== a.total) {
          return b.total - a.total
        } else if (b.tsumoCount !== a.tsumoCount) {
          return b.tsumoCount - a.tsumoCount
        } else if (b.winCount !== a.winCount) {
          return b.winCount - a.winCount
        } else {
          return a.loseCount - b.loseCount
        }
      })
      return data
    },
    fontSize: function() {
      var domain = 'www.supermahjongleague.com'
      var width = window.innerWidth
      const fontSize = ((width - 80) / (1 + Math.max((domain).split('\n').map(t => t.length))))
      return 'font-size:' + fontSize + 'px;flex:0 ' + fontSize
    }
  },
  mounted() {
    this.$bus.$on('liveSetting', content => {
      this.liveSetting = content
      this.getData()
    })
  },
  methods: {
    getRankStr: function(idx) {
      var data = ''
      switch (idx) {
        case 1:
          data = '<span>1</span><span>st</span>'
          break
        case 2:
          data = '<span>2</span><span>nd</span>'
          break
        case 3:
          data = '<span>3</span><span>rd</span>'
          break
        case 4:
          data = '<span>4</span><span>th</span>'
          break
        case 5:
          data = '<span>5</span><span>th</span>'
          break
        case 6:
          data = '<span>6</span><span>th</span>'
          break
        case 7:
          data = '<span>7</span><span>th</span>'
          break
        case 8:
          data = '<span>8</span><span>th</span>'
          break
      }
      return data
    },
    getData: function() {
      this.$store.commit('CLOSE_LOADING')
      this.$store.dispatch('getData', { type: 'Season', credentials: this.liveSetting.seasonId }).then((res) => {
        if (!res.errorCode) {
          this.teams = res.data.teams
          this.$store.commit('UPDATE_TEAM', res.data.teams)
          this.$dataManager.getTeamByLiveShow(this.liveSetting.liveShowId).then((res) => {
            this.liveTeams = res.data
            this.isLoading = true
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import '../../../style/show/preTodayTotalPoint';
</style>
