import store from '@/store'
import axios from 'axios'

const Axios = axios.create({
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
    'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
    'Content-Type': 'application/x-www-form-urlencoded'
  }

})
Axios.interceptors.request.use(function(config) {
  if (store && store.getters.sid) {
    config.headers['x-ci-session'] = store.getters.sid
  }
  return config
}, function(error) {
  return Promise.reject(error)
})

Axios.interceptors.response.use(response => {
  if (response.data.errorCode) {
    window.$alert({ type: 'error', msg: response.data.message, duration: 3.5 * 1000 })
    if (response.data.isLogout) {
      store.app.$router.push({ name: 'Login' })
    }
    // throw response.data.errorCode
  }
  return response
})

export default Axios
