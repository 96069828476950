<template>
  <div>
    <div class="col-md-4">
      <div class="input-group mb-3">
        <input v-model="authorChannelId" type="text" class="form-control" placeholder="AuthorChannelId">
        <span class="input-group-text search" @click="search"><span class="ti-search" /></span>
        <span class="input-group-text search" @click="clean"><span class="ti-eraser" /></span>
      </div>
    </div>
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="table">
            <table class="table">
              <thead class=" text-primary">
                <tr>
                  <th>
                    ID
                  </th>
                  <th>
                    authorChannelId
                  </th>
                  <th>
                    Level
                  </th>
                  <th>
                    Experience
                  </th>
                  <th>
                    point
                  </th>
                  <th>
                    #
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item,i) in viewers" :key="i">
                  <td v-text="item.id" />
                  <td>
                    <a target="_blank" :href="'https://www.youtube.com/channel/'+item.authorChannelId" v-text="(item.name == '')?item.authorChannelId:item.name" />
                  </td>
                  <td v-text="item.level" />
                  <td v-text="item.experience" />
                  <td v-text="item.point" />
                  <td>
                    <router-link :to="{name:'Viewer.Statistics',params:{authorChannelId:item.authorChannelId}}">
                      <div class="btn btn-xs btn-primary pull-right">Detail</div>
                    </router-link>
                  <!--                <router-link :to="{name:'User.Update',params:{id:user.id}}"-->
                  <!--                             v-if="$dataManager.getters.permissions.User.includes('update')">-->
                  <!--                  <div class="btn btn-xs btn-primary  pull-right">Update</div>-->
                  <!--                </router-link>-->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <nav aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item">
                <a class="page-link" aria-label="Previous" @click="changePage(1)">
                  <span aria-hidden="true">&laquo;</span>
                </a>
              </li>
              <li v-for="(page,i) in pages" :key="i" class="page-item"><a class="page-link" @click="changePage(page)" v-text="page" /></li>
              <li class="page-item">
                <a class="page-link" aria-label="Next" @click="changePage(totalPage)">
                  <span aria-hidden="true">&raquo;</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
export default {
  name: 'ViewerList',
  data: function() {
    return {
      viewers: [],
      authorChannelId: '',
      currentPage: 1,
      totalPage: 0
    }
  },
  computed: {
    pages: function() {
      var data = []
      if (this.currentPage > 1) {
        data.push(this.currentPage - 1)
        data.reverse()
        for (var i = 0; i < 9; i++) {
          if (this.currentPage + i < this.totalPage) {
            data.push(this.currentPage + i)
          }
        }
      } else {
        for (var j = 0; j < 10; j++) {
          if (this.currentPage + j <= this.totalPage) {
            data.push(this.currentPage + j)
          }
        }
      }
      return data
    }
  },
  watch: {},
  mounted() {
    this.getData()
  },
  methods: {
    search: function() {
      this.$store.commit('OPEN_LOADING')
      var data = { authorChannelId: this.authorChannelId }
      this.$store.dispatch('viewerGet', { credentials: data }).then((res) => {
        if (!res.errorCode) {
          this.viewers = res.data.items
          this.totalPage = res.data.totalPage
          this.$store.commit('CLOSE_LOADING')
        }
      })
    },
    clean: function() {
      this.authorChannelId = ''
      this.getData()
    },
    getData: function() {
      this.$store.commit('OPEN_LOADING')
      var data = { pageSize: 50, page: 1 }
      this.$store.dispatch('viewerList', { credentials: data }).then((res) => {
        if (!res.errorCode) {
          this.viewers = res.data.items
          this.totalPage = res.data.totalPage
          this.$store.commit('CLOSE_LOADING')
        }
      })
    },
    changePage: function(page) {
      this.$store.commit('OPEN_LOADING')
      var data = { pageSize: 50, page: page }
      this.$store.dispatch('viewerList', { credentials: data }).then((res) => {
        if (!res.errorCode) {
          this.viewers = res.data.items
          this.totalPage = res.data.totalPage
          this.currentPage = res.data.currentPage
          this.$store.commit('CLOSE_LOADING')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.search {
  cursor: pointer;
}
</style>
