import Axios from './_axios'

export default {

  teamCreate(getters, { credentials }) {
    const formdata = new FormData()

    formdata.append('name', credentials.name)
    formdata.append('displayName', credentials.displayName)
    formdata.append('color', credentials.color)
    formdata.append('icon', credentials.icon)
    formdata.append('seasonId', credentials.seasonId)

    const url = getters.apiUrl + 'Team/create'
    return Axios.post(
      url, formdata
    ).then(res => {
      return res
    })
  },

  teamUpdate(getters, { credentials }) {
    const formdata = new FormData()

    formdata.append('name', credentials.team.name)
    formdata.append('displayName', credentials.team.displayName)
    formdata.append('seasonId', credentials.team.seasonId)
    const url = getters.apiUrl + 'Team/update/' + credentials.id
    return Axios.post(
      url, formdata
    ).then(res => {
      return res
    })
  }

}

