<template>
  <div class="card card-user">
    <div class="card-body">
      <form>
        <div class="form-group row">
          <label class="col-md-2 col-form-label">System Name</label>
          <div class="col-md-10">
            <input v-model="team.name" type="text" class="form-control">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-2 col-form-label">Display Name</label>
          <div class="col-md-10">
            <input v-model="team.displayName" type="text" class="form-control">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-2 col-form-label">顏色</label>
          <div class="col-md-10">
            <input v-model="team.color" type="text" class="form-control">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-2 col-form-label">icon</label>
          <div class="col-md-10">
            <input v-model="team.icon" type="text" class="form-control">
          </div>
        </div>
        <div class="row">
          <div class="update ml-auto mr-auto">
            <button type="submit" class="btn btn-primary btn-round" @click="submit">送出</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TeamCreate',
  data: function() {
    return {
      seasons: [],
      team: {
        seasonId: this.$route.params.seasonId,
        name: '',
        displayName: '',
        color: '',
        icon: ''
      }
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    submit: function() {
      this.$store.commit('OPEN_LOADING')
      this.$store.dispatch('teamCreate', { credentials: this.team }).then((res) => {
        if (!res.errorCode) {
          this.$store.commit('CLOSE_LOADING')
          this.$router.push({ name: 'Team.List' })
        }
      })
    },
    getData: function() {
      this.$store.commit('OPEN_LOADING')
      this.$store.dispatch('getList', { type: 'Season', action: 'list' }).then((res) => {
        if (!res.errorCode) {
          this.seasons = res.data
          this.$store.commit('CLOSE_LOADING')
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
