import Axios from './_axios'

export default {
  getUserPostCurrentIndex(getters, { credentials }) {
    console.log(credentials)
    const formdata = new FormData()
    formdata.append('nowId', credentials.nowId)
    formdata.append('index', credentials.index)
    formdata.append('pageSize', credentials.pageSize)

    const url = getters.apiUrl + 'UserPost/getCurrentIndex'
    return Axios.post(
      url,
      formdata
    ).then(res => {
      return res
    })
  },
  updateUserPost(getters, { credentials }) {
    console.log(credentials)
    const formdata = new FormData()
    formdata.append('status', credentials.status)
    formdata.append('isShow', credentials.isShow)
    formdata.append('rejectReason', credentials.rejectReason)

    const url = getters.apiUrl + 'UserPost/update/' + credentials.id
    return Axios.post(
      url,
      formdata
    ).then(res => {
      return res
    })
  }
}

