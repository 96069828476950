<template>

  <div class="card card-user">
    <div class="card-body">
      <form>
        <div class="form-group row">
          <label class="col-md-2 col-form-label">Name</label>
          <div class="col-md-10">
            <input v-model="gameType.name" type="text" class="form-control">
          </div>
        </div>
        <div class="row">
          <div class="update ml-auto mr-auto">
            <button type="submit" class="btn btn-primary btn-round" @click="submit">送出</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GameTypeUpdate',
  data: function() {
    return {
      id: this.$route.params.id,
      gameType: {
        name: ''
      }
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData: function() {
      this.$store.commit('OPEN_LOADING')

      this.$store.dispatch('getData', { type: 'GameType', credentials: this.id }).then((res) => {
        if (!res.errorCode) {
          this.gameType = res.data
          this.$store.commit('CLOSE_LOADING')
        }
      })
    },

    submit: function() {
      this.$store.commit('OPEN_LOADING')

      var data = {
        id: this.id,
        gameType: this.gameType
      }
      this.$store.dispatch('gameTypeUpdate', { credentials: data }).then((res) => {
        if (!res.errorCode) {
          this.$store.commit('CLOSE_LOADING')
          this.$router.push({ name: 'GameType.List' })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
