import ShowRoot from '@/components/ShowRoot'

import floatData from '@/components/Show/season/floatData'
import floatTsumoData from '@/components/Show/season/floatTsumoData'
import floatWinData from '@/components/Show/season/floatWinData'
import floatLoseData from '@/components/Show/season/floatLoseData'

import dailyShow from '@/components/Show/dailyShow'
import dailyDetail from '@/components/Show/dailyDetail'

import playerGameBaseDataP1 from '@/components/Show/player/playerGameBaseDataP1'
import playerGameBaseDataP2 from '@/components/Show/player/playerGameBaseDataP2'
import playerGameBaseDataP1HOTFIX from '@/components/Show/player/HOTCODE/playerGameBaseDataP1'
import playerGameBaseDataP2HOTFIX from '@/components/Show/player/HOTCODE/playerGameBaseDataP2'

import playerDetailBySeasonP1 from '@/components/Show/player/playerDetailBySeasonP1'
import playerDetailBySeasonP2 from '@/components/Show/player/playerDetailBySeasonP2'

import todayPlayerPoint from '@/components/Show/season/todayPlayerPoint'
import todayPlayerPointSpecial from '@/components/Show/season/todayPlayerPointSpecial'
import todayPlayerPointSpecial2 from '@/components/Show/season/todayPlayerPointSpecial2'
import todayPlayerPointSpecial3 from '@/components/Show/season/todayPlayerPointSpecial3'
import todayPlayerPointSpecial4 from '@/components/Show/season/todayPlayerPointSpecial4'
import todayTotalPoint from '@/components/Show/season/todayTotalPoint'
import preTodayTotalPoint from '@/components/Show/season/preTodayTotalPoint'
import seasonTotalPoint from '@/components/Show/season/seasonTotalPoint'
import seasonSchedule from '@/components/Show/season/seasonSchedule'
import seasonData from '@/components/Show/season/seasonData'

import playerBaseData from '@/components/Show/playerBaseData'
import playerTotalData from '@/components/Show/playerTotalData'

import PlayerDailyPointHistory from '@/components/Show/player/PlayerDailyPointHistory'
import PlayerSeasonPoint from '@/components/Show/player/PlayerSeasonPoint'
import PlayerSeasonDetail from '@/components/Show/player/PlayerSeasonDetail'
import LiveShowPlayersPointHistory from '@/components/Show/player/LiveShowPlayersPointHistory'

import voteBarChat from '@/components/Show/Vote/BarChat'
import voteHoriBar from '@/components/Show/Vote/HoriBar'
import votePieChat from '@/components/Show/Vote/PieChat'

import lotteryResult from '@/components/Show/lottery/lotteryResult'
import SetPosition from '@/components/Lottery/SetPosition'
import userPostResult from '@/components/Show/userPostResult'
import bingoPrint from '@/components/Show/bingo/bingoPrint'
import showStr from '@/components/Show/showStr'

const show = [
  {
    path: '/show',
    name: 'Root',
    component: ShowRoot,
    children: [
      {
        path: 'showStr',
        name: 'Show.showStr',
        component: showStr
      },
      {
        path: 'bingoPrint/:ids',
        name: 'Show.bingoPrint',
        component: bingoPrint
      },
      {
        path: 'dailyShow',
        name: 'Show.dailyShow',
        component: dailyShow
      },
      {
        path: 'dailyDetail',
        name: 'Show.dailyDetail',
        component: dailyDetail
      },
      {
        path: 'floatData', // 浮動數據
        name: 'Show.floatData',
        component: floatData
      },
      {
        path: 'floatTsumoData', // 浮動自摸數據
        name: 'Show.floatTsumoData',
        component: floatTsumoData
      },
      {
        path: 'floatWinData', // 浮動胡牌數據
        name: 'Show.floatWinData',
        component: floatWinData
      },
      {
        path: 'floatLoseData', // 浮動放槍數據
        name: 'Show.floatLoseData',
        component: floatLoseData
      },
      {
        path: 'todayPlayerPoint', // 上方積分條
        name: 'Show.todayPlayerPoint',
        component: todayPlayerPoint
      },
      {
        path: 'todayPlayerPointSpecial', // 上方積分條
        name: 'Show.todayPlayerPointSpecial',
        component: todayPlayerPointSpecial
      },
      {
        path: 'todayPlayerPointSpecial2', // 上方積分條
        name: 'Show.todayPlayerPointSpecial2',
        component: todayPlayerPointSpecial2
      },
      {
        path: 'todayPlayerPointSpecial3', // 上方積分條
        name: 'Show.todayPlayerPointSpecial3',
        component: todayPlayerPointSpecial3
      },
      {
        path: 'todayPlayerPointSpecial4', // 上方積分條
        name: 'Show.todayPlayerPointSpecial4',
        component: todayPlayerPointSpecial4
      },
      {
        path: 'todayTotalPoint', // 當日排行榜
        name: 'Show.todayTotalPoint',
        component: todayTotalPoint
      },
      {
        path: 'preTodayTotalPoint', // 預選賽當日排行榜
        name: 'Show.preTodayTotalPoint',
        component: preTodayTotalPoint
      },
      {
        path: 'playerGameBaseDataP1', // 賽季選手資訊三角
        name: 'Show.playerGameBaseDataP1',
        component: playerGameBaseDataP1
      },
      {
        path: 'playerGameBaseDataP2', // 賽季選手資訊三角
        name: 'Show.playerGameBaseDataP2',
        component: playerGameBaseDataP2
      },
      {
        path: 'playerGameBaseDataP1HOTFIX', // 賽季選手資訊三角
        name: 'Show.playerGameBaseDataP1HOTFIX',
        component: playerGameBaseDataP1HOTFIX
      },
      {
        path: 'playerGameBaseDataP2HOTFIX', // 賽季選手資訊三角
        name: 'Show.playerGameBaseDataP2HOTFIX',
        component: playerGameBaseDataP2HOTFIX
      },
      {
        path: 'playerDetailBySeasonP1', // 賽季選手資訊
        name: 'Show.playerDetailBySeasonP1',
        component: playerDetailBySeasonP1
      },
      {
        path: 'playerDetailBySeasonP2', // 賽季選手資訊
        name: 'Show.playerDetailBySeasonP2',
        component: playerDetailBySeasonP2
      },
      {
        path: 'seasonTotalPoint', // 賽季排行榜第一階段
        name: 'Show.seasonTotalPoint',
        component: seasonTotalPoint
      },
      {
        path: 'seasonSchedule',
        name: 'Show.seasonSchedule',
        component: seasonSchedule
      },
      {
        path: 'seasonData',
        name: 'Show.seasonData',
        component: seasonData
      },
      {
        path: 'playerBase/:id',
        name: 'playerBaseData',
        component: playerBaseData
      },
      {
        path: 'playerTotal/:id',
        name: 'playerTotalData',
        component: playerTotalData
      },
      {
        path: 'player/PlayerDailyPointHistory/:seasonId/:playerId',
        name: 'Show.PlayerDailyPointHistory',
        component: PlayerDailyPointHistory
      },
      {
        path: 'player/PlayerSeasonPoint/:seasonId/:playerId',
        name: 'Show.PlayerSeasonPoint',
        component: PlayerSeasonPoint
      },
      {
        path: 'player/PlayerSeasonDetail/:seasonId/:playerId',
        name: 'Show.PlayerSeasonDetail',
        component: PlayerSeasonDetail
      },
      { // no use
        path: 'player/LiveShowPlayersPointHistory/:seasonId/:liveshowId',
        name: 'Show.LiveShowPlayersPointHistory',
        component: LiveShowPlayersPointHistory
      },
      {
        path: 'vote/BarChat',
        name: 'Show.voteBarChat',
        component: voteBarChat
      },
      {
        path: 'vote/HoriBar',
        name: 'Show.voteHoriBar',
        component: voteHoriBar
      },
      {
        path: 'vote/PieChat',
        name: 'Show.votePieChat',
        component: votePieChat
      },
      {
        path: 'lottery/setPosition/:id',
        name: 'Lottery.setPosition',
        component: SetPosition
      },
      {
        path: 'lottery/Result/:id',
        name: 'Show.lotteryResult',
        component: lotteryResult
      },
      {
        path: 'userPostResult',
        name: 'Show.userPostResult',
        component: userPostResult
      }
    ]
  }

]

export default show
