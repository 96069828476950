<template>
  <div class="card card-user">
    <div class="card-body">
      <form v-if="flag">
        <div class="form-group row">
          <label class="col-md-2 col-form-label">Role name</label>
          <div class="col-md-10">
            <input v-model="role.name" type="text" class="form-control">
          </div>
        </div>
        <div v-for="(item,i) in permission" :key="i" class="form-group row">
          <label class="col-md-2 col-form-label" v-text="item" />
          <div class="col-md-10">
            <div class="form-check form-check-inline">
              <input
                :id="item+'List'"
                v-model="role.permission[item]"
                class="form-check-input"
                type="checkbox"
                :value="'list'"
              >
              <label class="form-check-label" :for="item+'List'">列表</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                :id="item+'Create'"
                v-model="role.permission[item]"
                class="form-check-input"
                type="checkbox"
                :value="'create'"
              >
              <label class="form-check-label" :for="item+'Create'">建立</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                :id="item+'Update'"
                v-model="role.permission[item]"
                class="form-check-input"
                type="checkbox"
                :value="'update'"
              >
              <label class="form-check-label" :for="item+'Update'">更新</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                :id="item+'Delete'"
                v-model="role.permission[item]"
                class="form-check-input"
                type="checkbox"
                :value="'delete'"
              >
              <label class="form-check-label" :for="item+'Delete'">刪除</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="update ml-auto mr-auto">
            <button type="submit" class="btn btn-primary btn-round" @click="submit">送出</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RoleUpdate',
  data: function() {
    return {
      id: this.$route.params.id,
      role: {
        name: '',
        projects: [],
        permission: {
          Role: [],
          User: [],
          Category: [],
          Project: [],
          Game: [],
          Export: [],
          Key: [],
          Registration: [],
          Translate: {
            list: [],
            create: [],
            update: [],
            delete: []
          },
          Log: []
        }
      },
      permission: [
        'Role',
        'User',
        'GameType',
        'WinningType',
        'Team',
        'Player',
        'Season',
        'Live',
        'Hand',
        'Vote',
        'Lottery',
        'Registration'
      ],
      flag: false
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData: function() {
      this.$store.commit('OPEN_LOADING')
      this.$store.dispatch('getData', { type: 'Role', credentials: this.id }).then((res) => {
        if (!res.errorCode) {
          this.role = res.data
          this.role.permission = Object.assign(this.role.permission, res.data.permission)
          this.flag = true
          this.$store.commit('CLOSE_LOADING')
        }
      })
    },
    submit: function() {
      this.$store.commit('OPEN_LOADING')
      var data = {
        id: this.id,
        role: this.role
      }
      this.$store.dispatch('roleUpdate', { credentials: data }).then((res) => {
        if (!res.errorCode) {
          this.$store.commit('CLOSE_LOADING')
          this.$router.push({ name: 'Role.List' })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
