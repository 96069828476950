<template>
  <div class="season-data">
    <table>
      <thead>
        <tr>
          <th class="label">目前積分</th>
          <th class="label">目前自摸數</th>
          <th class="label">目前胡牌數</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(i,x) in teamsDetail" :key="x">
          <th>
            <div class="team-label" :style="'background-color:'+i.color" v-text="i.displayName" />
            {{ i.teamScore }}
          </th>
          <td v-text="i.detail.tsumoCount" />
          <td v-text="i.detail.winCount" />
        </tr>
      </tbody>
    </table>

    <div class="fill" />
  </div>
</template>

<script>
export default {
  name: 'SeasonData',
  data() {
    return {
      season: {},
      teams: [],
      detail: [],
      isLoading: false,
      liveSetting: {}
    }
  },
  computed: {
    teamsDetail: function() {
      var res = []

      this.teams.forEach((team) => {
        this.detail.forEach((i) => {
          if (team.id === i.teamId) {
            let data = {}
            data = team
            data.detail = i.data
            res.push(data)
          }
        })
      })
      res.sort(function(a, b) {
        return a.teamRank - b.teamRank
      })
      return res
    }
  },
  mounted() {
    this.$bus.$on('liveSetting', content => {
      this.liveSetting = content
      this.getData()
    })
  },
  methods: {
    getData: function() {
      this.$store.commit('CLOSE_LOADING')
      this.$store.dispatch('getData', { type: 'Season', credentials: this.liveSetting.seasonId }).then((res) => {
        if (!res.errorCode) {
          this.season = res.data
          this.teams = res.data.teams
          this.$store.commit('UPDATE_TEAM', res.data.teams)
          this.$store.dispatch('teamDetailBySeason', { credentials: this.liveSetting.seasonId }).then((res) => {
            if (!res.errorCode) {
              this.detail = res.data
              this.isLoading = true
            }
          })
          this.isLoading = true
        }
      })
    }
  }
}
</script>

<style lang="scss">
  @import '../../../style/show/seasonData';
</style>
