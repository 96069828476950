<template>
  <div class="content">
    <h5 v-text="'目前選取:' + totalPoint + '台'" />
    <div class="types">
      <div
        v-for="(type, i) in formatWintype"
        :key="i"
        class="type "
        :class="type.active ? 'active' : ''"
        @click="chooseType(type)"
      >
        <span v-text="type.name + type.point" />
        <span
          v-if="type.has > 1 && type.isMulti"
          class="badge"
          v-text="type.has"
        />
      </div>
    </div>
    <div class="buttons">
      <div class="btn capitalize" @click="close">cancel</div>
      <div class="btn upper" @click="confirm">ok</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SetPoint',
  components: {},
  data() {
    return {
      type: [],
      base: 3,
      player: this.$dataManager.getters.dialog.data.player,
      seasonType: this.$dataManager.getters.mahjong.liveUpdate.season.type
    }
  },
  computed: {
    totalPoint() {
      var total = 0
      this.type.forEach((data) => {
        total += data.point
      })
      return total
    },
    totalWinType() {
      var type = []
      this.type.forEach((data) => {
        type.push(data.id)
      })
      return type
    },
    totalBann() {
      var bann = 0
      this.type.forEach((data) => {
        bann += data.point
      })
      return bann
    },
    formatWintype() {
      var wintype = []
      this.$dataManager.getters.mahjong.liveUpdate.wintype.forEach((idx) => {
        var active = false
        var has = 0
        this.type.forEach((i) => {
          if (idx.id === i.id) {
            has++
            active = true
          }
        })
        idx.active = active
        idx.has = has
        if (idx.seasonType === '') {
          wintype.push(idx)
        } else {
          const t = idx.seasonType.split(',').map((i) => {
            return parseInt(i)
          })
          if (t.includes(this.seasonType)) {
            wintype.push(idx)
          }
        }
      })
      return wintype
    }
  },
  watch: {},
  methods: {
    close: function() {
      this.$store.commit('CLEAR_DIALOG')
    },
    confirm: function() {
      var data = {
        playerId: this.player.playerId,
        bann: this.totalPoint,
        points: this.totalPoint + this.base,
        winTypes: this.totalWinType
      }
      switch (this.$dataManager.getters.dialog.data.type) {
        case 'lose':
          this.$bus.$emit('setPoint', data)
          break
        case 'tsumo':
          this.$bus.$emit('setTsumo', data)
          break
      }
    },
    chooseType: function(type) {
      var has = 0
      this.type.forEach((i) => {
        if (i.id === type.id) {
          has++
        }
      })
      if (has < type.isMulti) {
        this.type.push(type)
      } else {
        var idx = 0
        var arr = []
        this.type.forEach((i) => {
          if (i.id === type.id) {
            arr.push(idx)
          }
          idx++
        })
        arr.reverse().forEach((idx) => {
          this.type.splice(idx, 1)
        })
      }
    },
    _chooseType: function(type) {
      var has = 0
      this.type.forEach((i) => {
        if (i.id === type.id) {
          has++
        }
      })
      switch (has) {
        case 0:
          this.type.push(type)
          break
        case 1:
          if (!type.isMulti) {
            this.type.forEach((j, idx) => {
              if (j.id === type.id) {
                this.type.splice(idx, 1)
              }
            })
          } else {
            this.type.push(type)
          }
          break
        case 2:
          var idx = 0
          var arr = []
          this.type.forEach((i) => {
            if (i.id === type.id) {
              arr.push(idx)
            }
            idx++
          })
          arr.reverse().forEach((idx) => {
            this.type.splice(idx, 1)
          })
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.types {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;
  width: 100%;

  .type {
    position: relative;
    cursor: pointer;
    padding: 0.45rem;
    border-radius: 8px;
    text-align-last: justify;
    padding: 0.5rem 1.5rem;
    text-align: center;
    color: #b5b7c6;
    background: -moz-linear-gradient(
      91deg,
      rgb(23, 25, 48) 0%,
      rgb(34, 37, 71) 100%
    );
    background: -o-linear-gradient(
      91deg,
      rgb(23, 25, 48) 0%,
      rgb(34, 37, 71) 100%
    );
    background: -ms-linear-gradient(
      91deg,
      rgb(23, 25, 48) 0%,
      rgb(34, 37, 71) 100%
    );
    background: linear-gradient(
      91deg,
      rgb(23, 25, 48) 0%,
      rgb(34, 37, 71) 100%
    );
    background: -webkit-linear-gradient(
      91deg,
      rgb(23, 25, 48) 0%,
      rgb(34, 37, 71) 100%
    );
    box-shadow: 1px 0.214rem 4.85px 0.15px rgba(0, 0, 0, 0.34),
      -1px -1px 0.142rem 0px rgba(0, 0, 0, 0.34),
      -1px 1px 0.214rem 0px rgba(0, 0, 0, 0.34),
      0.142rem 0.142rem 0.214rem 0px rgba(0, 0, 0, 0.34);

    &.active {
      color: rgb(20, 21, 41);
      background: -moz-linear-gradient(
        90deg,
        rgb(109, 124, 220) 0%,
        rgb(84, 166, 228) 77%
      );
      background: -o-linear-gradient(
        90deg,
        rgb(109, 124, 220) 0%,
        rgb(84, 166, 228) 77%
      );
      background: -ms-linear-gradient(
        90deg,
        rgb(109, 124, 220) 0%,
        rgb(84, 166, 228) 77%
      );
      background: linear-gradient(
        90deg,
        rgb(109, 124, 220) 0%,
        rgb(84, 166, 228) 77%
      );
      background: -webkit-linear-gradient(
        90deg,
        rgb(109, 124, 220) 0%,
        rgb(84, 166, 228) 77%
      );
    }

    .badge {
      position: absolute;
      top: -5px;
      right: -5px;
      width: 15px;
      height: 15px;
      border-radius: 8px;
      color: $btn-text-color;
      background-color: $btn-color7;
    }
  }
}
</style>
