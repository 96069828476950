import Axios from './_axios'

export default {

  playerCreate(getters, { credentials }) {
    const formdata = new FormData()

    formdata.append('userId', credentials.userId)
    formdata.append('teamId', credentials.teamId)
    formdata.append('name', credentials.name)
    formdata.append('displayName', credentials.displayName)
    // formdata.append('weighted', credentials.weighted)

    const url = getters.apiUrl + 'Player/create'
    return Axios.post(
      url, formdata
    ).then(res => {
      return res
    })
  },

  playerUpdate(getters, { credentials }) {
    console.log(credentials)
    const formdata = new FormData()

    formdata.append('name', credentials.player.name)
    formdata.append('displayName', credentials.player.displayName)
    const url = getters.apiUrl + 'Player/update/' + credentials.id
    return Axios.post(
      url, formdata
    ).then(res => {
      return res
    })
  },

  addTeam(getters, { credentials }) {
    const formdata = new FormData()

    formdata.append('teamId', credentials.teamId)
    formdata.append('playerIds', JSON.stringify(credentials.playerIds))
    const url = getters.apiUrl + 'Player/addTeam'
    return Axios.post(
      url, formdata
    ).then(res => {
      return res
    })
  },

  playerDetail(getters, { credentials }) {
    const url = getters.apiUrl + 'Player/getDetail/' + credentials
    return Axios.post(
      url
    ).then(res => {
      return res
    })
  },
  playerDetailBySeason(getters, { credentials }) {
    const url = getters.apiUrl + 'Player/getSeasonPlayersDetail/' + credentials
    return Axios.post(
      url
    ).then(res => {
      return res
    })
  },
  teamDetailBySeason(getters, { credentials }) {
    const url = getters.apiUrl + 'Player/getSeasonTeamsDetail/' + credentials
    return Axios.post(
      url
    ).then(res => {
      return res
    })
  },
  teamDetailByLiveshow(getters, { credentials }) {
    const url = getters.apiUrl + 'Player/getLiveShowTeamsDetail/' + credentials
    return Axios.post(
      url
    ).then(res => {
      return res
    })
  },

  getPointByPlayer(getters, { credentials }) {
    const url = getters.apiUrl + 'Player/getPointByPlayer/' + credentials.seasonId + '/' + credentials.playerId
    return Axios.post(
      url
    ).then(res => {
      return res
    })
  },
  playerSeasonDetail(getters, { credentials }) {
    const url = getters.apiUrl + 'Player/getDetail/' + credentials.playerId
    const formdata = new FormData()

    formdata.append('seasonId', credentials.seasonId)
    return Axios.post(
      url, formdata
    ).then(res => {
      return res
    })
  }

}

