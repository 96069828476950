import Root from '@/components/Root'

import RoleCreate from '@/components/Role/Create'
import UserCreate from '@/components/User/Create'
import GameTypeCreate from '@/components/GameType/Create'
import SeasonCreate from '@/components/Season/Create'
import LiveCreate from '@/components/Live/Create'
import WinningTypeCreate from '@/components/WinningType/Create'
import TeamCreate from '@/components/Team/Create'
import PlayerCreate from '@/components/Player/Create'
import VoteCreate from '@/components/Vote/Create'
import LotteryCreate from '@/components/Lottery/Create'
import LiveStrCreate from '@/components/LiveStr/Create'

const create = [
  {
    path: '/app/create',
    name: 'Create',
    component: Root,
    children: [
      {
        path: 'liveStr',
        name: 'LiveStr.Create',
        component: LiveStrCreate
      },
      {
        path: 'role',
        name: 'Role.Create',
        component: RoleCreate
      },
      {
        path: 'user',
        name: 'User.Create',
        component: UserCreate
      },
      {
        path: 'gameType',
        name: 'GameType.Create',
        component: GameTypeCreate
      },
      {
        path: 'winningType',
        name: 'WinningType.Create',
        component: WinningTypeCreate
      },
      {
        path: 'season',
        name: 'Season.Create',
        component: SeasonCreate
      },
      {
        path: 'live/:seasonId',
        name: 'Live.Create',
        component: LiveCreate
      },
      {
        path: 'team/:seasonId',
        name: 'Team.Create',
        component: TeamCreate
      },
      {
        path: 'player/:userId',
        name: 'Player.Create',
        component: PlayerCreate
      },
      {
        path: 'vote',
        name: 'Vote.Create',
        component: VoteCreate
      },
      {
        path: 'lottery/:voteId',
        name: 'Lottery.Create',
        component: LotteryCreate
      }
    ]
  }
]
export default create
