
<template>
  <div style="padding-bottom: 2rem">
    <div v-if="edit.mode" class="alert alert-warning">
      <h3 style="margin: 0;">編輯模式</h3>
    </div>
    <div v-if="doubleLoser" class="alert alert-danger">
      <h5 style="margin: 0;">先輸入放槍資料，再輸入自摸</h5>
    </div>
    <div v-if="seasonType == '1' && !isLiveShowEnd" class="form-group row">
      <div class="col-md-6">
        <div class="form-check form-check-inline">
          <input
            id="comboMode"
            v-model="handsData.comboMode"
            class="form-check-input"
            type="checkbox"
            value="true"
          >
          <label class="form-check-label" for="comboMode">豹子</label>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-check form-check-inline">
          <input
            id="doubleLoser"
            v-model="doubleLoser"
            class="form-check-input"
            type="checkbox"
            value="true"
          >
          <label class="form-check-label" for="doubleLoser">緩存模式</label>
        </div>
      </div>
    </div>

    <div class="seats" :class="gameType != '3' ? 'seat4' : 'seat2'">
      <div
        v-for="(seat, i) in $dataManager.getters.mahjong.usualUpdate.seats"
        :key="i"
        class="seat"
      >
        <div class="newSale-wrap">
          <div class="content">
            <div
              class="newSale"
              :style="'background-color:' + finTeamColor(seat)"
              v-text="finTeamName(seat)"
            />
          </div>
        </div>
        <div
          v-if="seat.setPlayer && seat.isHost && seat.combo"
          class="host"
          v-text="seat.combo"
        />
        <div class="seat-content">
          <div v-if="seat.setPlayer && seat.isHost" class="h4-bg" />
          <div class="player">
            <span
              v-if="seat.playerId !== null"
              v-text="
                (seat.player.displayName || seat.player.name) + seat.point
              "
            />
            <span
              v-if="seat.playerId === null"
              v-text="$dataManager.positionName(seat.position)"
            />
          </div>
        </div>
        <select
          v-if="
            seasonType == '1' &&
              (isLiveShowEnd ||
                $dataManager.getters.mahjong.liveUpdate.setNewGame)
          "
          v-model="seat.location"
          class="custom-select"
        >
          <option selected>位置</option>
          <option
            v-for="(item, idx) in $dataManager.getters.location"
            :key="idx"
            :value="item.data"
            v-text="item.name"
          />
        </select>
        <div class="buttons">
          <!-- 設置玩家 -->
          <div
            v-if="
              !seat.setPlayer &&
                (isLiveShowEnd ||
                  $dataManager.getters.mahjong.liveUpdate.setNewGame)
            "
            class="setPlayer upper"
            @click="openSetPlayer(seat)"
            v-text="'set'"
          />
          <!-- 取消玩家 -->
          <div
            v-if="
              seat.setPlayer &&
                (isLiveShowEnd ||
                  $dataManager.getters.mahjong.liveUpdate.setNewGame)
            "
            class="cancelPlayer upper"
            @click="cancelSetPlayer(seat)"
            v-text="'cancel'"
          />
          <!-- 眼牌按鈕 -->
          <div
            v-if="
              seat.setPlayer &&
                seasonType == '1' &&
                !isLiveShowEnd &&
                !$dataManager.getters.mahjong.liveUpdate.setNewGame &&
                (selectedSeat == null ||
                  (selectedSeat !== null &&
                    selectedSeat.position !== seat.position))
            "
            class="btn"
            @click="setRiichi(seat)"
            v-text="seat.riichi ? '取消眼牌' : '眼牌'"
          />
          <!-- 聽牌按鈕 -->
          <div
            v-if="
              seat.setPlayer &&
                seasonType == '1' &&
                gameType == '3' &&
                !isLiveShowEnd &&
                !$dataManager.getters.mahjong.liveUpdate.setNewGame &&
                (selectedSeat == null ||
                  (selectedSeat !== null &&
                    selectedSeat.position !== seat.position))
            "
            class="btn"
            @click="setEye(seat)"
            v-text="seat.earEvent ? '取消聽牌' : '聽牌'"
          />
          <!-- 放槍按鈕 -->
          <div
            v-if="
              seat.setPlayer &&
                !isLiveShowEnd &&
                !$dataManager.getters.mahjong.liveUpdate.setNewGame &&
                (selectedSeat == null ||
                  (selectedSeat !== null &&
                    selectedSeat.position !== seat.position))
            "
            class="btn"
            @click="setLoser(seat)"
            v-text="'放槍'"
          />
          <!-- 自摸按鈕 -->
          <div
            v-if="
              seat.setPlayer &&
                !isLiveShowEnd &&
                !$dataManager.getters.mahjong.liveUpdate.setNewGame &&
                (selectedSeat == null ||
                  (selectedSeat !== null &&
                    selectedSeat.position !== seat.position))
            "
            class="btn"
            @click="setTsumo(seat)"
            v-text="'自摸'"
          />
          <!-- 編輯狀態臭裝按鈕 -->
          <div
            v-if="
              gameType !== 3 &&
                seat.setPlayer &&
                !isLiveShowEnd &&
                seat.isHost &&
                !$dataManager.getters.mahjong.liveUpdate.setNewGame &&
                (selectedSeat == null ||
                  (selectedSeat !== null &&
                    selectedSeat.position !== seat.position)) &&
                edit.mode
            "
            class="btn draw-btn"
            @click="updateDraw()"
            v-text="'臭莊'"
          />
          <!-- 臭裝按鈕 -->
          <div
            v-if="
              gameType !== 3 &&
                seat.setPlayer &&
                !isLiveShowEnd &&
                seat.isHost &&
                !$dataManager.getters.mahjong.liveUpdate.setNewGame &&
                (selectedSeat == null ||
                  (selectedSeat !== null &&
                    selectedSeat.position !== seat.position)) &&
                !edit.mode
            "
            class="btn draw-btn"
            @click="submitDraw()"
            v-text="'臭莊'"
          />
          <!-- 美麻臭裝按鈕 -->
          <div
            v-if="
              gameType === 3 &&
                seat.setPlayer &&
                !isLiveShowEnd &&
                seat.isHost &&
                !$dataManager.getters.mahjong.liveUpdate.setNewGame &&
                (selectedSeat == null ||
                  (selectedSeat !== null &&
                    selectedSeat.position !== seat.position)) &&
                !edit.mode
            "
            class="btn draw-btn"
            @click="setDraw()"
            v-text="'流局'"
          />
          <!-- 清除按鈕 -->
          <div
            v-if="
              seat.setPlayer &&
                !isLiveShowEnd &&
                !$dataManager.getters.mahjong.liveUpdate.setNewGame &&
                selectedSeat !== null &&
                selectedSeat.position === seat.position
            "
            class="btn draw-btn"
            @click="cleanHand()"
            v-text="'CLEAN'"
          />
          <!-- 選擇胡哪張牌 -->
          <div
            v-if="
              seat.setPlayer &&
                !doubleLoser &&
                !isLiveShowEnd &&
                !$dataManager.getters.mahjong.liveUpdate.setNewGame &&
                selectedSeat !== null &&
                selectedSeat.position === seat.position &&
                !edit.mode
            "
            class="btn"
            @click="openSetCard()"
            v-text="'牌'"
          />
          <!-- 送出按鈕 -->
          <div
            v-if="
              seat.setPlayer &&
                !doubleLoser &&
                !isLiveShowEnd &&
                !$dataManager.getters.mahjong.liveUpdate.setNewGame &&
                selectedSeat !== null &&
                selectedSeat.position === seat.position &&
                !edit.mode
            "
            class="btn highlight"
            @click="submitHand(seat)"
            v-text="'OK'"
          />
          <!-- 編輯儲存 -->
          <div
            v-if="
              seat.setPlayer &&
                doubleLoser &&
                !isLiveShowEnd &&
                !$dataManager.getters.mahjong.liveUpdate.setNewGame &&
                selectedSeat !== null &&
                selectedSeat.position === seat.position &&
                !edit.mode
            "
            class="btn highlight"
            @click="saveHand()"
            v-text="'SAVE'"
          />
        </div>
        <setLosePanel
          v-if="
            popup.setLose &&
              selectedSeat !== null &&
              selectedSeat.position === seat.position
          "
          :loser="selectedSeat"
          :riichied="riichied"
          :combo-mode="handsData.comboMode"
          @setHands="setLose"
        />
      </div>
    </div>
    <div v-if="doubleLoser" class="panel" style="margin-top: 3rem;">
      <table class="table">
        <tr
          v-for="(seat, i) in $dataManager.getters.mahjong.usualUpdate.seats"
          :key="i"
        >
          <td
            v-text="
              seat.player.displayName === ''
                ? seat.player.name
                : seat.player.displayName
            "
          />
          <td v-text="seat.temp_point" />
        </tr>
      </table>

      <div
        class="start btn danger"
        @click="submitDoubleHand"
        v-text="'Submit'"
      />
    </div>
    <div
      v-if="checkAllSeated && !isLiveShowEnd && edit.mode"
      class="buttons-group"
    >
      <div
        class="cancel btn warning"
        @click="cancelEdit"
        v-text="'Cancel'"
      />
      <div class="start btn danger" @click="fixedHand" v-text="'Submit'" />
    </div>
  </div>
</template>

<script>
import setLosePanel from '@/components/Layout/Dialog/SetLose'

export default {
  name: 'Seats',
  components: {
    setLosePanel
  },
  props: ['seasonType', 'gameType'],
  data: function() {
    return {
      setNewGame: false,
      continueCombo: false,
      doubleLoser: false,
      selectedSeat: null,
      showEdit: false,
      riichied: 0,
      tsumoplayerId: 0,
      popup: {
        setLose: false
      },
      handsData: {
        result: 0,
        combo: 0,
        dealerId: 0,
        comboMode: false,
        handPoints: [],
        riichis: []
      },
      edit: {
        mode: false,
        needReset: 0,
        gameid: 0,
        roundid: 0,
        handid: 0,
        dealerId: 0,
        combo: 0,
        result: 2
      },
      orginTemp: {
        dealer: 'e',
        prevailing: 'e',
        tempSeats: [],
        tempHand: []
      }
    }
  },
  computed: {
    isLiveShowEnd() {
      return this.$dataManager.getters.mahjong.liveUpdate.isLiveShowEnd
    },
    checkAllSeated() {
      var data = true
      this.$dataManager.getters.mahjong.usualUpdate.seats.forEach((seat) => {
        if (!seat.setPlayer) {
          data = false
        }
      })
      return data
    },
    dealerId() {
      var id = 0
      if (this.checkAllSeated) {
        this.$dataManager.getters.mahjong.usualUpdate.seats.forEach((seat) => {
          if (seat.isHost) {
            id = seat.playerId
          }
        })
      }
      return id
    },
    riichis() {
      var data = [0]
      if (this.checkAllSeated) {
        this.$dataManager.getters.mahjong.usualUpdate.seats.forEach((seat) => {
          if (seat.riichi) {
            data.push(seat.playerId)
          }
        })
      }
      return data
    },
    eyes() {
      var data = [0]
      if (this.checkAllSeated) {
        this.$dataManager.getters.mahjong.usualUpdate.seats.forEach((seat) => {
          if (seat.earEvent) {
            data.push(seat.playerId)
          }
        })
      }
      return data
    }
  },
  watch: {
    riichis: {
      handler: function(val) {
        this.$root.liveSetting.status.riichis = val
      },
      deep: true
    },
    eyes: {
      handler: function(val) {
        this.$root.liveSetting.status.eyes = val
      },
      deep: true
    }
  },
  mounted() {
    this.$store.commit('CLEAR_SEAT_HAND_POINT')
    this.$store.commit('CLEAR_SEAT_TEMP_POINT')
    if (!this.$dataManager.getters.mahjong.liveUpdate.isLiveShowEnd) {
      this.riichied = this.$dataManager.getters.mahjong.usualUpdate.riichied
      this.$dataManager.getTeamByLiveShow(
        this.$dataManager.getters.mahjong.liveUpdate.liveShow.id
      )
    }
    this.$bus._events['setDraw'] = null
    this.$bus.$on('setDraw', (data) => {
      this.setDrawPoint(data, true)
    })
    this.$bus._events['updateDraw'] = null
    this.$bus.$on('updateDraw', (data) => {
      this.setDrawPoint(data, false)
    })

    this.$bus._events['setTsumo'] = null
    this.$bus.$on('setTsumo', (data) => {
      this.tsumo(data)
    })

    this.$bus._events['confrimTsumo'] = null
    this.$bus.$on('confrimTsumo', () => {
      if (!this.doubleLoser) {
        this.submitHand()
      } else {
        this.submitDoubleHand()
      }
    })

    this.$bus._events['editHand'] = null
    this.$bus.$on('editHand', (detail) => {
      this.$store.commit('CLEAR_DIALOG')
      if (detail.hand.result !== 5) {
        this.editHand(detail)
      } else {
        var data = {
          visible: true,
          title: '選擇是否聽牌',
          type: 'setDraw',
          data: detail.hand.handPoints
        }
        this.editDraw(detail)

        this.$store.commit('UPDATE_DIALOG', data)
      }
    })
  },
  methods: {
    openSetPlayer: function(seat) {
      var data = {
        visible: true,
        title: '設置選手',
        type: 'setPLayer',
        data: seat
      }
      this.$store.commit('UPDATE_DIALOG', data)
    },
    setDraw: function() {
      var data = {
        visible: true,
        title: '選擇是否聽牌',
        type: 'setDraw',
        data: []
      }
      this.$store.commit('UPDATE_DIALOG', data)
    },
    openSetCard: function() {
      var data = {
        visible: true,
        title: '選擇胡牌',
        type: 'setCard'
      }
      this.$store.commit('UPDATE_DIALOG', data)
    },
    cancelSetPlayer: function(seat) {
      this.$store.commit('CLEAR_SEAT_PLAYER', seat)
    },
    setRiichi: function(seat) {
      seat.riichi = !seat.riichi
      this.$store.commit('CLEAR_SEAT_HAND_POINT', this.handsData.comboMode)
    },
    setEye: function(seat) {
      seat.earEvent = !seat.earEvent
    },
    saveHand: function() {
      this.$dataManager.getters.mahjong.usualUpdate.seats.forEach((seat) => {
        seat.temp_point += seat.hand_point
      })
      this.selectedSeat = null
      this.$store.commit('CLEAR_RIICHI')
      this.$store.commit('CLEAR_SEAT_HAND_POINT')
    },
    setLoser: function(seat) {
      this.popup.setLose = true
      this.selectedSeat = seat
    },
    setDrawPoint: function(setHands, isCreated) {
      const eyePlayers = []
      this.handsData.result = 5
      this.handsData.riichis = this.riichis.filter((i) => i > 0)
      this.handsData.dealerId = this.dealerId
      this.handsData.comboMode = this.handsData.comboMode ? 1 : 0
      this.handsData.handPoints = setHands

      this.handsData.riichis.forEach((item) => {
        if (item) {
          var i = {}
          i.playerId = item
          i.points = this.handsData.comboMode ? -2 : -1
          this.riichied = this.handsData.comboMode
            ? this.riichied + 2
            : this.riichied + 1
          i.result = 4
          i.bann = 0
          i.winTypes = []
          this.handsData.handPoints.push(i)
        }
      })
      this.$store.commit('SET_RIICHIED', this.riichied)

      let someoneHasEye = true
      setHands.forEach((hand) => {
        eyePlayers.push(hand.playerId)
        if (!hand.points) {
          someoneHasEye = false
        }
      })

      this.handsData.riichis = this.riichis.filter((i) => i > 0)
      this.$dataManager.getters.mahjong.usualUpdate.seats.forEach((seat) => {
        if (seat.isHost) {
          this.handsData.combo = seat.combo
          if (someoneHasEye) {
            this.handsData.handPoints.forEach((hand) => {
              if (hand.playerId === this.dealerId && hand.points > 0) {
                this.continueCombo = true // 檢查莊家是否加分
              }
            })
          } else {
            if (eyePlayers.indexOf(seat.playerId) > -1) {
              // 莊家有聽
              this.continueCombo = true
            }
          }
        }
      })

      if (isCreated) {
        this.createHand(true)
      } else {
        this.$root.liveSetting.status.riichied = this.riichied
        var dealerWin = false
        var _dealerWin = false
        var isDraw =
          this.orginTemp.tempHand.hand.handPoints.length === 0 ? 1 : 0

        var riichi = []
        this.orginTemp.tempHand.hand.handPoints.forEach((item) => {
          if (item.result === 4) {
            riichi.push(item.playerId)
          } else {
            if (item.points > 0) {
              if (item.dealer) {
                _dealerWin = 1
              }
            }
          }
        })
        this.handsData.handPoints.forEach((hand) => {
          if (hand.result === 4) {
            riichi.push(hand.playerId)
          } else {
            if (hand.playerId === this.handsData.dealerId) {
              dealerWin = hand.points > 0
            }
          }
          this.edit.needReset =
            this.edit.dealerId === hand.playerId ? 1 : this.edit.needReset
          if (hand.dealer !== undefined) {
            hand.points = hand.points + hand.dealer
            delete hand.dealer
          }
        })
        this.edit.needReset = isDraw
          ? !dealerWin
          : _dealerWin
            ? dealerWin
              ? 1
              : 0
            : dealerWin
              ? 1
              : 0
        const data = {
          result: 5,
          combo: this.edit.combo,
          handPoints: setHands
        }
        this.updateHand(data)
      }
    },

    setLose: function(setHands) {
      // this.$store.commit('CLEAR_RIICHI')
      this.handsData.result = 2
      if (this.doubleLoser) {
        setHands.forEach((hand) => {
          this.handsData.handPoints.push(hand)
        })
      } else {
        this.handsData.handPoints = setHands
      }
      this.handsData.dealerId = this.dealerId
      this.handsData.comboMode = this.handsData.comboMode ? 1 : 0
      if (this.doubleLoser) { this.handsData.riichis = this.riichis.filter((i) => i > 0) }
      this.$dataManager.getters.mahjong.usualUpdate.seats.forEach((seat) => {
        if (seat.isHost) {
          this.handsData.combo = seat.combo
          this.handsData.handPoints.forEach((hand) => {
            if (hand.playerId === this.dealerId && hand.points > 0) {
              this.continueCombo = true // 檢查莊家是否加分
            }
          })
        }
      })
    },
    setTsumo: function(seat) {
      this.tsumoplayerId = seat.playerId
      var data = {
        visible: true,
        title: '選擇牌型',
        type: 'setPoint',
        data: {
          type: 'tsumo',
          player: seat
        }
      }
      this.$store.commit('UPDATE_DIALOG', data)
    },
    tsumo: function(data) {
      this.tsumoplayerId = 0
      this.handsData.result = 1
      this.handsData.dealerId = this.dealerId
      this.handsData.riichis = this.riichis.filter((i) => i > 0)
      var response = this.$dataManager.tsumoData(
        data,
        this.dealerId,
        this.handsData.comboMode
      )
      this.continueCombo = response.continueCombo
      this.handsData.combo = response.combo

      if (this.doubleLoser) {
        response.handsData.forEach((hand) => {
          this.handsData.handPoints.push(hand)
        })
      } else {
        this.handsData.handPoints = response.handPoints
      }
      var param = {
        visible: true,
        title: '確認分數',
        type: 'setWin',
        data: response.handsData
      }
      this.$store.commit('UPDATE_DIALOG', param)
    },
    updateDraw: function() {
      while (this.handsData.handPoints.length) {
        this.handsData.handPoints.pop()
      }
      this.updateHand(this.handsData)
    },
    submitDraw: function() {
      this.continueCombo = true
      this.handsData.riichis = this.riichis.filter((i) => i > 0)
      var data = this.handsData
      data.dealerId = this.dealerId
      data.result = 3

      this.handsData.riichis.forEach((item) => {
        if (item) {
          var i = {}
          i.playerId = item
          i.points = this.handsData.comboMode ? -2 : -1
          this.riichied = this.handsData.comboMode
            ? this.riichied + 2
            : this.riichied + 1
          i.result = 4
          i.bann = 0
          i.winTypes = []
          data.handPoints.push(i)
        }
      })

      this.$dataManager.getters.mahjong.usualUpdate.seats.forEach((seat) => {
        if (seat.isHost) {
          data.combo = seat.combo
        }
      })
      this.$store.commit('SET_RIICHIED', this.riichied)
      if (!this.edit.mode) {
        this.createHand(true)
      } else {
        this.fixedHand(true)
      }
    },
    cleanHand: function() {
      this.handsData.handPoints = []
      this.continueCombo = false
      this.$bus.$emit('cleanHand')
    },
    submitHand: function() {
      if (!this.doubleLoser) {
        if (this.edit.handid === 0) {
          this.createHand(false)
        } else {
          this.fixedHand(false)
        }
      }
    },
    submitDoubleHand: function() {
      if (this.doubleLoser) {
        var obj = []
        var dataArr = []
        this.handsData.handPoints.forEach((data) => {
          var item = {
            playerId: 0,
            bann: 0,
            points: 0,
            result: 0,
            winTypes: []
          }
          item.playerId = data.playerId
          item.bann = data.bann
          item.points = data.points
          item.result = data.result
          item.winTypes = item.winTypes.concat(data.winTypes)
          dataArr.push(data.playerId)
          obj.push(item)
        })
        this.handsData.handPoints = obj
        if (this.edit.handid === 0) {
          this.createHand(false)
        } else {
          this.fixedHand(false)
        }
      }
    },
    createRound: function() {
      var round = {
        gameId: this.$dataManager.getters.mahjong.liveUpdate.game.id,
        round: {
          dealerWind: this.$dataManager.getters.mahjong.usualUpdate.prevailing,
          prevailingWind: this.$dataManager.getters.mahjong.usualUpdate
            .prevailing
        }
      }
      this.$dataManager.createRound(round).then(() => {
        this.$root.liveSetting.status.round = this.$dataManager.getters.mahjong.usualUpdate.prevailing
        this.$root.liveSetting.status.wind = this.$dataManager.getters.mahjong.usualUpdate.dealer
        var data = {
          isLiveShowEnd: false
        }
        this.$store.commit('UPDATE_STATUS', data)
        this.$bus.$emit('saveStore')
      })
    },
    createHand: function(isDraw) {
      var hand = {
        roundId: this.$dataManager.getters.mahjong.liveUpdate.round.id,
        hand: this.handsData
      }
      const liveSetting = {
        gameId: this.$dataManager.getters.mahjong.liveUpdate.game.id,
        liveShowId: this.$dataManager.getters.mahjong.liveUpdate.liveShow.id,
        nowHand: 0,
        onLiveShow: true,
        seasonId: this.$dataManager.getters.mahjong.liveUpdate.season.id,
        status: {}
      }
      const LiveStatus = {
        riichied: this.riichied,
        riichis: [],
        eyes: ['0'],
        combo: 0,
        game: this.$root.liveSetting.status.game,
        wind: '',
        round: this.$root.liveSetting.status.round
      }

      this.$dataManager.createHand(hand).then((res) => {
        if (!isDraw) {
          this.riichied = 0
        }
        LiveStatus.riichied = this.riichied
        LiveStatus.riichis = ['0']
        this.handsData = {
          result: 0,
          combo: 0,
          comboMode: 0,
          dealerId: 0,
          handPoints: [],
          riichis: []
        }
        this.$store.commit('CLEAR_RIICHI')
        this.$store.commit('CLEAR_EYEEVENT')

        if (this.continueCombo) {
          this.$store.commit('COMBO_DEALER')
        } else {
          this.$store.commit('TRUN_DEALER')
          if (this.$dataManager.getters.mahjong.liveUpdate.setNewRound) {
            this.createRound()
          }
        }
        this.$dataManager.getters.mahjong.usualUpdate.seats.forEach((seat) => {
          seat.earEvent = false
          if (seat.isHost) {
            LiveStatus.combo = seat.combo
          }
        })
        this.doubleLoser = false
        this.continueCombo = false
        this.popup.setLose = false
        this.tsumoplayerId = 0
        this.selectedSeat = null
        this.$dataManager.getTeamByLiveShow(
          this.$dataManager.getters.mahjong.liveUpdate.liveShow.id
        )
        LiveStatus.wind = this.$dataManager.getters.mahjong.usualUpdate.dealer

        liveSetting.nowHand = res.data.id
        liveSetting.status = LiveStatus

        this.$root.liveSetting = liveSetting
        this.$bus.$emit('saveStore')
      })
    },
    updateHand: function(data) {
      var hand = {
        handid: this.edit.handid,
        reset: this.edit.needReset,
        result: data.result,
        dealerId: this.dealerId,
        combo: data.combo,
        handPoints: data.handPoints,
        riichis: []
      }
      this.$dataManager.updateHand(hand).then(() => {
        this.$root.liveSetting.status.riichis = ['0']
        this.$root.liveSetting.nowHand = 0
        this.popup.setLose = false
        this.selectedSeat = null
        this.edit.mode = false
        if (this.edit.needReset) {
          // 重置狀態 :: 狀態回到edit
          console.log(this.continueCombo)
          var param = {
            dealer: this.edit.wind,
            prevailing: this.edit.prevailing
          }
          this.$store.commit('ROLLBACK_DEALER', param)
          this.$dataManager.getters.mahjong.usualUpdate.seats.forEach(
            (seat) => {
              seat.temp_point = 0
              seat.hand_point = 0
            }
          )
          if (this.continueCombo) {
            this.$store.commit('COMBO_DEALER')
          } else {
            this.$store.commit('TRUN_DEALER')
          }
          this.edit.needReset = 0
          this.continueCombo = false
          this.popup.setLose = false
          this.popup.setWin = false
          this.tsumoplayerId = 0
          this.selectedSeat = null
        } else {
          // 不重置狀態 :: 狀態回到orginTemp
          this.$dataManager.getters.mahjong.usualUpdate.seats = this.orginTemp.tempSeats
          const param = {
            dealer: this.orginTemp.dealer,
            prevailing: this.orginTemp.prevailing
          }
          this.$store.commit('ROLLBACK_DEALER', param)
        }
        this.edit.handid = 0
        this.edit.dealerId = 0
        this.edit.combo = 0
        this.edit.result = 0

        while (this.handsData.handPoints.length) {
          this.handsData.handPoints.pop()
        }
        this.$dataManager.getTeamByLiveShow(
          this.$dataManager.getters.mahjong.liveUpdate.liveShow.id
        )
        this.$bus.$emit('saveStore')
      })
    },
    cancelEdit: function() {
      this.edit.mode = false
      this.popup.setLose = false
      this.selectedSeat = null
      this.edit.needReset = 0
      this.edit.handid = 0
      this.edit.dealerId = 0
      this.edit.combo = 0
      this.edit.result = 0
      this.$store.commit('RESET_EDITDATA', this.orginTemp)
    },
    editDraw: function(detail) {
      var prevailing = JSON.parse(
        JSON.stringify(this.$dataManager.getters.mahjong.usualUpdate.prevailing)
      )
      var dealer = JSON.parse(
        JSON.stringify(this.$dataManager.getters.mahjong.usualUpdate.dealer)
      )
      if (!this.edit.mode) {
        this.edit.mode = true
        this.orginTemp.tempHand = detail
        this.orginTemp.tempSeats = JSON.parse(
          JSON.stringify(this.$dataManager.getters.mahjong.usualUpdate.seats)
        )
        this.orginTemp.prevailing = prevailing
        this.orginTemp.dealer = dealer
      }
      // this.edit.needReset = detail.hand.needReset
      this.edit.combo = detail.hand.combo
      this.edit.wind = this.findDealer(detail.hand.dealerId)
      this.edit.prevailing = detail.prevailing
      this.edit.dealerId = detail.hand.dealerId
      this.edit.gameid = detail.gameid
      this.edit.roundid = detail.roundid
      this.edit.handid = detail.hand.id
      this.edit.result = detail.hand.result
      this.edit.combo = detail.hand.combo
    },
    editHand: function(detail) {
      var prevailing = JSON.parse(
        JSON.stringify(this.$dataManager.getters.mahjong.usualUpdate.prevailing)
      )
      var dealer = JSON.parse(
        JSON.stringify(this.$dataManager.getters.mahjong.usualUpdate.dealer)
      )
      if (!this.edit.mode) {
        this.edit.mode = true
        this.orginTemp.tempHand = detail
        this.orginTemp.tempSeats = JSON.parse(
          JSON.stringify(this.$dataManager.getters.mahjong.usualUpdate.seats)
        )
        this.orginTemp.prevailing = prevailing
        this.orginTemp.dealer = dealer
      }
      // this.edit.needReset = detail.hand.needReset
      this.edit.combo = detail.hand.combo
      this.edit.wind = this.findDealer(detail.hand.dealerId)
      this.edit.prevailing = detail.prevailing
      this.edit.dealerId = detail.hand.dealerId
      this.edit.gameid = detail.gameid
      this.edit.roundid = detail.roundid
      this.edit.handid = detail.hand.id
      this.prevailing = detail.prevailing
      this.dealer = this.findDealer(detail.hand.dealerId)
      this.handsData.handPoints = detail.hand.handPoints
      this.$dataManager.getters.mahjong.usualUpdate.seats.forEach((seat) => {
        seat.temp_point = 0
        seat.hand_point = 0
      })

      this.$dataManager.getters.mahjong.usualUpdate.seats.forEach((seat) => {
        if (seat.playerId === detail.hand.dealerId) {
          seat.isHost = true
          seat.combo = detail.hand.combo
        } else {
          seat.isHost = false
          seat.combo = 0
        }
        detail.hand.handPoints.forEach((data) => {
          if (seat.playerId === data.playerId) {
            seat.hand_point = data.points
            if (detail.hand.result === 2 && data.points < 0) {
              this.popup.setLose = true
              this.selectedSeat = seat
            }
          }
        })
      })
    },
    fixedHand: function(isDraw) {
      if (isDraw) {
        this.$root.liveSetting.status.riichied = this.riichied
      } else {
        this.riichied = 0
        this.$root.liveSetting.status.riichied = this.riichied
      }
      var dealerWin = false
      var _dealerWin = false
      const _isDraw = this.orginTemp.tempHand.hand.handPoints.length === 0 ? 1 : 0

      var riichi = []
      this.orginTemp.tempHand.hand.handPoints.forEach((item) => {
        if (item.result === 4) {
          riichi.push(item.playerId)
        } else {
          if (item.points > 0) {
            if (item.dealer) {
              _dealerWin = 1
            }
          }
        }
      })
      this.handsData.handPoints.forEach((hand) => {
        console.log(hand)
        if (hand.result === 4) {
          riichi.push(hand.playerId)
        } else {
          if (hand.playerId === this.handsData.dealerId) {
            dealerWin = hand.points > 0
          }
        }
        this.edit.needReset =
          this.edit.dealerId === hand.playerId ? 1 : this.edit.needReset
        if (hand.dealer !== undefined) {
          hand.points = hand.points + hand.dealer
          delete hand.dealer
        }
      })
      this.edit.needReset = _isDraw
        ? !dealerWin
        : _dealerWin
          ? dealerWin
            ? 1
            : 0
          : dealerWin
            ? 1
            : 0
      this.updateHand(this.handsData)
    },
    finTeamName: function(seat) {
      return seat.team !== null ? seat.team.displayName : ''
    },
    finTeamColor: function(seat) {
      return seat.team !== null ? seat.team.color : ''
    },
    findDealer: function(dealerid) {
      var dealer = ''
      this.$dataManager.getters.mahjong.usualUpdate.seats.forEach((seat) => {
        if (seat.playerId === dealerid) {
          dealer = seat.position
        }
      })
      return dealer
    }
  }
}
</script>
<style lang="scss">
@import "../../../style/layout/seat";
</style>
