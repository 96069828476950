const setting = require('../utils/config.js')

let initialState = {}

initialState = {
  apiUrl: (process.env.NODE_ENV === 'production') ? setting.prod.apiUrl : setting.stage.apiUrl,
  languages: [],
  allLiveshowList: [],
  loading: false,
  location: [
    {
      name: '下',
      data: {
        x: 816,
        y: 1335
      }
    },
    {
      name: '右',
      data: {
        x: 2108,
        y: 209
      }
    },
    {
      name: '上',
      data: {
        x: 1789,
        y: 27
      }
    }, {
      name: '左',
      data: {
        x: 19,
        y: 238
      }
    }
  ],
  dialog: {
    visible: false,
    title: '',
    type: '',
    data: {},
    callback: null,
    buttons: []
  },
  auth: {
    sid: '',
    username: '',
    password: '',
    roleID: '',
    phone: '',
    email: '',
    permissions: {
      Role: [],
      User: [],
      GameType: [],
      WinningType: [],
      Team: [],
      Player: [],
      Season: [],
      Live: [],
      Hand: [],
      Vote: [],
      Lottery: []
    }
  },
  playerKey: setting.playerKey,
  teamKey: setting.teamKey,
  colorList: setting.colorList,
  mahjong: {
    usualUpdate: {
      dealer: 'e',
      prevailing: 'e',
      riichied: 0,
      teams: [],
      seats: [
        {
          position: 'e',
          setPlayer: false,
          isHost: true,
          combo: 0,
          teamid: null,
          team: null,
          playerId: null,
          player: null,
          point: 0,
          temp_point: 0,
          hand_point: 0,
          riichi: false,
          earEvent: false,
          location: {
            x: 0,
            y: 0
          }
        },
        {
          position: 's',
          setPlayer: false,
          isHost: false,
          combo: 0,
          teamid: null,
          team: null,
          playerId: null,
          player: null,
          point: 0,
          temp_point: 0,
          hand_point: 0,
          riichi: false,
          earEvent: false,
          location: {
            x: 0,
            y: 0
          }
        },
        {
          position: 'w',
          setPlayer: false,
          isHost: false,
          combo: 0,
          teamid: null,
          team: null,
          playerId: null,
          player: null,
          point: 0,
          temp_point: 0,
          hand_point: 0,
          riichi: false,
          earEvent: false,
          location: {
            x: 0,
            y: 0
          }
        },
        {
          position: 'n',
          setPlayer: false,
          isHost: false,
          combo: 0,
          teamid: null,
          team: null,
          playerId: null,
          player: null,
          point: 0,
          temp_point: 0,
          hand_point: 0,
          riichi: false,
          earEvent: false,
          location: {
            x: 0,
            y: 0
          }
        }
      ]
    },
    liveUpdate: {
      isLiveShowEnd: true,
      lastChampion: 4,
      setNewGame: false,
      setNewRound: false,
      setWinCard: 0,
      wintype: [],
      season: {},
      liveShow: {},
      game: {},
      round: {}
    },
    liveDetail: {}
  }
}

export const state = initialState

// create hand
// //臭莊
// {"result":"3","dealerId":35,"combo":0,riichis : [],"handPoints":[]}
// //一般胡牌
// {"result":"2",
//     "dealerId":33,
//     "combo":0,
//     riichis : [],
//     "handPoints":[{"playerId":38,"bann":1,"points":5,"winTypes":[13]},{"playerId":33,"points":-5,"winTypes":[13],"bann":0}]}
// //自摸
// {"result":"1",
//     "dealerId":35,
//     "combo":1,
//     riichis : [35],
//     "handPoints":[{"playerId":35,"points":-9,"winTypes":[3,5,14],"bann":0},{"playerId":33,"points":-6,"winTypes":[3,5,14],"bann":0},{"playerId":38,"points":21,"winTypes":[3,5,14],"bann":3},{"playerId":40,"points":-6,"winTypes":[3,5,14],"bann":0}]}
