var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card card-user"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"liveDetail content"},[_c('h4',[_vm._v(" 起始:"),_vm._l((_vm.basePoint.data),function(item,i){return _c('span',{key:i,staticClass:"badge rounded-pill bg-light text-dark",domProps:{"textContent":_vm._s(
            _vm.$dataManager.findPlayer(item.playerId).player.name +
              item.historyPoint)}})})],2),_vm._l((_vm.formatData.games),function(game,i){return _c('div',{key:i,staticClass:"panel"},[_c('div',{staticClass:"header"},[_c('h3',{staticClass:"panel-title upper",domProps:{"textContent":_vm._s('第' + parseInt(i + 1) + '雀')}}),_vm._v("QQㄆ ")]),_c('div',{staticClass:"content text-block"},_vm._l((game.rounds),function(round,r){return _c('div',{key:r,staticClass:"list",staticStyle:{"margin-top":"24px"}},[_c('h5',{domProps:{"textContent":_vm._s(round.dealerWind)}}),_c('table',{staticClass:"table table-hover table-striped"},[_vm._m(0,true),_c('tbody',_vm._l((round.hands),function(hand,idx){return _c('tr',{key:idx},[_c('td',{domProps:{"textContent":_vm._s(idx + 1)}}),_c('td',{staticClass:"flex1",domProps:{"textContent":_vm._s(
                      hand.isDouble ? hand.title + '(豹子)' : hand.title
                    )}}),_c('td',{staticClass:"flex2 table-secondary"},[_vm._l((hand.loser),function(player,p){return _c('div',{key:p},[_c('span',{domProps:{"textContent":_vm._s(
                          _vm.$dataManager.findPlayer(player.playerId).player.name
                        )}}),_c('span',{class:_vm.$dataManager.pointColor(player.prePoint),domProps:{"textContent":_vm._s(player.prePoint)}})])}),_vm._l((hand.winner),function(player,p){return _c('div',{key:p},[_c('span',{domProps:{"textContent":_vm._s(
                          _vm.$dataManager.findPlayer(player.playerId).player.name
                        )}}),_c('span',{class:_vm.$dataManager.pointColor(player.prePoint),domProps:{"textContent":_vm._s(player.prePoint)}})])})],2),_c('td',{staticClass:"flex3"},_vm._l((hand.winner),function(player,p){return _c('div',{key:p},[_c('span',{staticClass:"bolder",domProps:{"textContent":_vm._s(player.player)}}),_c('span',{domProps:{"textContent":_vm._s(' (' + player.type + ') ')}}),(
                          player.riichi &&
                            (player.point === -1 || player.point === 1)
                        )?_c('span',{domProps:{"textContent":_vm._s(' (眼牌) ')}}):_vm._e(),_c('span',{class:_vm.$dataManager.pointColor(player.point),domProps:{"textContent":_vm._s(player.point > 0 ? '+' + player.point : '')}})])}),0),_c('td',{staticClass:"flex3"},_vm._l((hand.loser),function(player,p){return _c('div',{key:p},[_c('span',{domProps:{"textContent":_vm._s(player.player + ' ')}}),(
                          player.riichi &&
                            (player.point === -1 || player.point === 1)
                        )?_c('span',{domProps:{"textContent":_vm._s(' (眼牌) ')}}):_vm._e(),_c('span',{class:_vm.$dataManager.pointColor(player.point),domProps:{"textContent":_vm._s(player.point)}})])}),0),_c('td',{staticClass:"flex2 table-secondary"},[_vm._l((hand.loser),function(player,p){return _c('div',{key:p},[_c('span',{domProps:{"textContent":_vm._s(
                          _vm.$dataManager.findPlayer(player.playerId).player.name
                        )}}),_c('span',{class:_vm.$dataManager.pointColor(player.afterPoint),domProps:{"textContent":_vm._s(player.afterPoint)}})])}),_vm._l((hand.winner),function(player,p){return _c('div',{key:p},[_c('span',{domProps:{"textContent":_vm._s(
                          _vm.$dataManager.findPlayer(player.playerId).player.name
                        )}}),_c('span',{class:_vm.$dataManager.pointColor(player.afterPoint),domProps:{"textContent":_vm._s(player.afterPoint)}})])})],2),_c('td',{staticClass:"flex1",domProps:{"textContent":_vm._s(hand.id)}}),_c('td',{staticClass:"flex1",domProps:{"textContent":_vm._s(hand.essenceCount)}})])}),0)])])}),0)])})],2)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('th',[_vm._v("#")]),_c('th',{staticClass:"flex1"},[_vm._v("莊")]),_c('th',{staticClass:"flex2"},[_vm._v("當前")]),_c('th',{staticClass:"flex3"},[_vm._v("Win")]),_c('th',{staticClass:"flex3"},[_vm._v("Lose")]),_c('th',{staticClass:"flex2"},[_vm._v("結果")]),_c('th',{staticClass:"flex1"},[_vm._v("ID")]),_c('th',{staticClass:"flex1"},[_vm._v("精華數")])])}]

export { render, staticRenderFns }