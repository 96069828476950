<template>
  <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
  <div class="player-game-data">
    <div class="main">
      <div
        class="team-label"
        :style="'background-color:'+$dataManager.findTeam(player.teamId).team.color"
        v-text="$dataManager.findTeam(player.teamId).team.displayName"
      />
      <div class="name" v-text="$dataManager.findPlayer(player.playerId).player.displayName" />
      <div class="trigon">
        <div class="main">
          <span :style="'color:'+$dataManager.findTeam(player.teamId).team.color">自摸率</span>
          <span :style="'color:'+$dataManager.findTeam(player.teamId).team.color">胡牌率</span>
          <span :style="'color:'+$dataManager.findTeam(player.teamId).team.color">放槍率</span>
          <multiTrigon
            v-if="isLoading"
            :width="810"
            :height="810"
            :bgcolor="transparent"
            :cfgs="cfgs"
            :datas="datas"
          />
        </div>
      </div>
      <div class="rows">
        <div class="item">
          <div class="title">自摸率</div>
          <div class="data" v-text="roundDecimalPercent((player.data.tsumoCount/player.data.allHands)*100,2)" />
          <div class="lifedata" v-text="roundDecimalPercent((life.tsumoCount/life.allHands)*100,2)" />
        </div>
        <div class="item">
          <div class="title">胡牌率</div>
          <div class="data" v-text="roundDecimalPercent((player.data.winCount/player.data.allHands)*100,2)" />
          <div class="lifedata" v-text="roundDecimalPercent((life.winCount/life.allHands)*100,2)" />
        </div>
        <div class="item">
          <div class="title">放槍率</div>
          <div class="data" v-text="roundDecimalPercent((player.data.loseCount/player.data.allHands)*100,2)" />
          <div class="lifedata" v-text="roundDecimalPercent((life.loseCount/life.allHands)*100,2)" />
        </div>
        <div class="item">
          <div class="title">最高連莊</div>
          <div class="data" v-text="player.data.maxCombo" />
          <div class="lifedata" v-text="life.maxCombo" />
        </div>
        <div class="item">
          <div class="title">平均連莊</div>
          <div class="data" v-text="roundDecimal(player.data.allCombo/(player.data.allGames*4),2)" />
          <div class="lifedata" v-text="roundDecimal(life.allCombo/(life.allGames*4),2)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import multiTrigon from '@/components/majcmp/multiTrigon'

export default {
  name: 'PlayerGameBaseDataP2',
  components: { multiTrigon },
  data() {
    return {
      season: {},
      player: {},
      key: 'e04',
      isLoading: false,
      transparent: 'rgba(0,0,0,0)',
      liveSetting: {},
      life: {},
      thisPlayerId: 58
    }
  },
  computed: {
    title: function() {
      return this.season.name ? this.season.name.indexOf('N') !== -1 ? 'N' : 'S' : ''
    },
    cfgs: function() {
      return {
        'lineLength': 650,
        'borderWidth': 4,
        'dotWidth': 8,
        'borderColor': this.$dataManager.findTeam(this.player.teamId).team.color,
        'delayTime': 0,
        'animationTime': 500,
        'borderCover': true,
        'visMode': '3',
        'maxValueFullRate': 0.7
      }
    },
    datas: function() {
      var fill = 'rgba(255,255,255,.8)'
      var _fill = 'rgba(179,181,182,.6)'
      var data = [{
        'color': this.$dataManager.findTeam(this.player.teamId).team.color,
        'fill': fill,
        'rates': [
          this.roundDecimalPercentDraw((this.player.data.tsumoCount / this.player.data.allHands) * 100, 2),
          this.roundDecimalPercentDraw((this.player.data.winCount / this.player.data.allHands) * 100, 2),
          this.roundDecimalPercentDraw((this.player.data.loseCount / this.player.data.allHands) * 100, 2)
        ]
      }, {
        'color': this.$dataManager.findTeam(this.player.teamId).team.color,
        'fill': _fill,
        'rates': [
          this.roundDecimalPercentDraw((this.life.tsumoCount / this.life.allHands) * 100, 2),
          this.roundDecimalPercentDraw((this.life.winCount / this.life.allHands) * 100, 2),
          this.roundDecimalPercentDraw((this.life.loseCount / this.life.allHands) * 100, 2)
        ]
      }]
      return data
    }
  },
  mounted() {
    this.getPlayerLifeData(this.thisPlayerId)

    if (!this.$bus._events['liveSetting']) {
      this.$bus.$on('liveSetting', content => {
        this.liveSetting = content
        this.getData()
      })
    }
    window.addEventListener('keydown', (e) => {
      this.key = e.key
    })
  },
  methods: {
    roundDecimalPercentDraw: function(val, precision) {
      var data = Math.round(Math.round(val * Math.pow(10, (precision || 0) + 1)) / 10) / Math.pow(10, (precision || 0)) || 0
      return data
    },
    roundDecimal: function(val, precision) {
      var data = Math.round(Math.round(val * Math.pow(10, (precision || 0) + 1)) / 10) / Math.pow(10, (precision || 0))
      return data || '-'
    },
    roundDecimalPercent: function(val, precision) {
      var data = Math.round(Math.round(val * Math.pow(10, (precision || 0) + 1)) / 10) / Math.pow(10, (precision || 0)) || '-'
      return (data !== '-') ? data + '%' : data
    },
    getData: function() {
      this.$store.commit('CLOSE_LOADING')
      this.$store.dispatch('getData', { type: 'Season', credentials: this.liveSetting.seasonId }).then((res) => {
        if (!res.errorCode) {
          this.season = res.data
          this.$store.commit('UPDATE_TEAM', res.data.teams)
          this.$store.dispatch('playerDetailBySeason', { credentials: this.liveSetting.seasonId }).then((res) => {
            if (!res.errorCode) {
              this.players = res.data
              this.players.forEach((p) => {
                if (p.playerId === this.thisPlayerId) {
                  this.player = p
                }
              })
              this.getPlayerLifeData(this.thisPlayerId)
              this.isLoading = true
            }
          })
        }
      })
    },
    getPlayerLifeData: function(id) {
      this.$store.commit('CLOSE_LOADING')

      this.$store.dispatch('playerDetail', { credentials: id }).then((res) => {
        if (!res.errorCode) {
          this.life = res.data
          this.players.forEach((p) => {
            if (p.playerId === id) {
              p.life = res.data
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
  @import '../../../../style/show/playerGameBaseData';

</style>
