<template>
  <div>
    <bumpyChart :width="600" :height="350" :cfgs="configs" :bgcolor="transparent" :line-color="'#000'" :xtags="xtags" :datas="datas" />
  </div>
</template>

<script>
import bumpyChart from '@/components/majcmp/bumpyChart'

export default {
  name: 'PlayerDailyPointHistory',
  components: { bumpyChart },
  data() {
    return {
      seasonId: this.$route.params.seasonId,
      playerId: this.$route.params.playerId,
      transparent: 'rgba(0,0,0,0)',
      lastHistory: [],
      thisHistory: [],
      configs: {
        'axisColor': '#a9a9a9', 'gridColor': '#c2c2c2',
        'dotSize': 3, 'lineWidth': 2,
        'fontSize': 12, 'fontColor': '#000000',
        'showAxisX': false, 'showAxisY': false,
        'showAxisXGrid': false, 'showAxisYGrid': false,
        'showAxisTop': false, 'showAxisRight': false,
        'showYNumber': true, 'showXTag': true,
        'maxYNumber': 10
      },
      colorList: [
        {
          thisM: {
            line: 'rgba(255, 169, 16,1)',
            fill: 'rgba(255, 169, 16,.6)'
          },
          lastM: {
            line: 'rgba(255, 169, 16,.6)',
            fill: 'rgba(255, 169, 16,.2)'
          }
        },
        {
          thisM: {
            line: 'rgba(2, 103, 189,1)',
            fill: 'rgba(2, 103, 189,.6)'
          },
          lastM: {
            line: 'rgba(2, 103, 189,.6)',
            fill: 'rgba(2, 103, 189,.2)'
          }
        },
        {
          thisM: {
            line: 'rgba(114, 45, 224,1)',
            fill: 'rgba(114, 45, 224,.6)'
          },
          lastM: {
            line: 'rgba(114, 45, 224,.6)',
            fill: 'rgba(114, 45, 224,.2)'
          }
        },
        {
          thisM: {
            line: 'rgba(245, 34, 3,1)',
            fill: 'rgba(245, 34, 3,.6)'
          },
          lastM: {
            line: 'rgba(245, 34, 3,.6)',
            fill: 'rgba(245, 34, 3,.2)'
          }
        }
      ]
    }
  },
  computed: {
    datas() {
      var res = []
      var index = this.getRandom(0, 3)
      var item = {}
      item.color = this.colorList[index].thisM.line
      item.fill = this.colorList[index].thisM.fill
      var data = []
      var last = []
      if (this.thisHistory.length > 0) {
        this.xtags.forEach((d) => {
          var played = false
          this.thisHistory.forEach((i) => {
            var date = new Date(i.createdTime).getDate()
            if (d === date) {
              played = true
              data.push(i.points)
            }
          })
          if (!played) {
            data.push('')
          }
        })
      }
      item.data = data
      res.push(item)
      item = {}
      item.color = this.colorList[index].lastM.line
      item.fill = this.colorList[index].lastM.fill
      last = []
      if (this.lastHistory.length > 0) {
        this.xtags.forEach((d) => {
          var played = false
          this.lastHistory.forEach((i) => {
            var date = new Date(i.createdTime).getDate()
            if (d === date) {
              played = true
              last.push(i.points)
            }
          })
          if (!played) {
            last.push('')
          }
        })
      }
      item.data = last

      res.push(item)
      return res
    },
    xtags() {
      var res = []
      var thisDate = 0
      var lastDate = 0

      if (this.thisHistory.length > 0) {
        var thisYear = new Date(this.thisHistory[0].createdTime).getFullYear()
        var thisMonth = new Date(this.thisHistory[0].createdTime).getMonth() + 1
        thisDate = new Date(thisYear, thisMonth, 0).getDate()
      }
      if (this.lastHistory.length > 0) {
        var lastYear = new Date(this.lastHistory[0].createdTime).getFullYear()
        var lastMonth = new Date(this.lastHistory[0].createdTime).getMonth() + 1
        lastDate = new Date(lastYear, lastMonth, 0).getDate()
      }
      var date = thisDate > lastDate ? thisDate : lastDate
      for (var i = 1; i <= date; i++) {
        res.push(i)
      }

      return res
    }
  },
  watch: {
  },
  mounted() {
    this.getData()
  },
  methods: {
    dataInterval: function(id) {
      clearInterval(this.interval)
      this.getData(id)
      var $this = this
      setInterval(() => {
        $this.getData(id)
      }, 10 * 1010)
    },
    getData: function() {
      var credentials = {
        seasonId: this.seasonId,
        playerId: this.playerId
      }
      this.$store.dispatch('getLastSeason', { credentials }).then((res) => {
        if (!res.errorCode) {
          credentials = {
            seasonId: res.data.lastId,
            playerId: this.playerId
          }
          this.$store.dispatch('getPointByPlayer', { credentials }).then((res) => {
            if (!res.errorCode) {
              this.lastHistory = res.data
            }
          })
        }
      })
      credentials = {
        seasonId: this.seasonId,
        playerId: this.playerId
      }
      this.$store.dispatch('getPointByPlayer', { credentials }).then((res) => {
        if (!res.errorCode) {
          this.thisHistory = res.data
        }
      })
    },
    getRandom: function(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min
    }

  }
}
</script>

<style scoped>

</style>
