<template>
  <div>
    <h5 v-text="$dataManager.findPlayer(parseInt(playerId)).player.displayName" />
    <table v-if="datas.allGames">
      <tr><td>出場雀數</td><td v-text="datas.allGames" /></tr>
      <tr><td>總把數</td><td v-text="datas.allHands" /></tr>
      <tr><td>總連莊數</td><td v-text="datas.allCombo" /></tr>
      <tr><td>總放槍數</td><td v-text="datas.loseCount" /></tr>
      <tr><td>總胡牌數</td><td v-text="datas.winCount" /></tr>
      <tr><td>總自摸數</td><td v-text="datas.tsumoCount" /></tr>
      <tr><td>總得分數</td><td v-text="datas.points" /></tr>
      <tr><td>最高連莊</td><td v-text="datas.maxCombo" /></tr>
      <tr><td>單場最高</td><td v-text="datas.maxPoint" /></tr>
      <tr><td>總正分數</td><td v-text="datas.allWinPoint" /></tr>
      <tr><td>總台數</td><td v-text="datas.totalBanns" /></tr>
      <tr><td>自摸率</td><td v-text="roundDecimalPercent((datas.tsumoCount/datas.allHands)*100,2)" /></tr>
      <tr><td>胡牌率</td><td v-text="roundDecimalPercent((datas.winCount/datas.allHands)*100,2)" /></tr>
      <tr><td>放槍率</td><td v-text="roundDecimalPercent((datas.loseCount/datas.allHands)*100,2)" /></tr>
      <tr><td>平均得分/雀</td><td v-text="roundDecimal(datas.points/datas.allGames,2)" /></tr>
      <tr><td>平均連莊</td><td v-text="roundDecimal(datas.allCombo/(datas.allGames*4),2)" /></tr>
      <tr><td>平均打點</td><td v-text="roundDecimal(datas.allWinPoint/(datas.winCount+datas.tsumoCount),2)" /></tr>
      <tr><td>平均台數</td><td v-text="roundDecimal(datas.totalBanns/(datas.winCount+datas.tsumoCount),2)" /></tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'PlayerSeasonDetail',
  data: function() {
    return {
      seasonId: this.$route.params.seasonId,
      playerId: this.$route.params.playerId,
      datas: []
    }
  },
  computed: {

  },
  mounted() {
    this.getData()
    this.$dataManager.getSeasonData(this.seasonId)
  },
  methods: {
    roundDecimal: function(val, precision) {
      var data = Math.round(Math.round(val * Math.pow(10, (precision || 0) + 1)) / 10) / Math.pow(10, (precision || 0))
      return data
    },
    roundDecimalPercent: function(val, precision) {
      var data = Math.round(Math.round(val * Math.pow(10, (precision || 0) + 1)) / 10) / Math.pow(10, (precision || 0)) || '-'
      return (data !== '-') ? data + '%' : data
    },
    getData: function() {
      var credentials = {
        seasonId: this.seasonId,
        playerId: this.playerId
      }

      this.$store.dispatch('playerSeasonDetail', { credentials: credentials }).then((res) => {
        if (!res.errorCode) {
          this.datas = res.data
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
