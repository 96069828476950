<template>
  <div class="card card-user">
    <div class="card-body">
      <form>
        <div class="form-group row">
          <label for="staticEmail" class="col-md-2 col-form-label">Role name</label>
          <div class="col-md-10">
            <input v-model="role.name" type="text" class="form-control">
          </div>
        </div>
        <div v-for="(item,i) in permission" :key="i" class="form-group row">
          <label class="col-md-2 col-form-label" v-text="item" />
          <div class="col-md-10">
            <div class="form-check form-check-inline">
              <input
                :id="item+'List'"
                v-model="role.permission[item]"
                class="form-check-input"
                type="checkbox"
                :value="'list'"
              >
              <label class="form-check-label" :for="item+'List'">列表</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                :id="item+'Create'"
                v-model="role.permission[item]"
                class="form-check-input"
                type="checkbox"
                :value="'create'"
              >
              <label class="form-check-label" :for="item+'Create'">建立</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                :id="item+'Update'"
                v-model="role.permission[item]"
                class="form-check-input"
                type="checkbox"
                :value="'update'"
              >
              <label class="form-check-label" :for="item+'Update'">更新</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                :id="item+'Delete'"
                v-model="role.permission[item]"
                class="form-check-input"
                type="checkbox"
                :value="'delete'"
              >
              <label class="form-check-label" :for="item+'Delete'">刪除</label>
            </div>
          </div>
        </div>
        <!--				<div class="form-group row">-->
        <!--					<label class="col-md-2 col-form-label">Translate Export</label>-->
        <!--					<div class="col-md-10">-->
        <!--						<div class="form-check form-check-inline">-->
        <!--							<input class="form-check-input" type="radio" id="TranslateExportTrue" v-model="role.permission.Export" :value="['create']">-->
        <!--							<label class=" form-check-label" for="TranslateExportTrue">True</label>-->
        <!--						</div>-->
        <!--						<div class="form-check form-check-inline">-->
        <!--							<input class="form-check-input" type="radio" id="TranslateExportFalse" v-model="role.permission.Export" :value="[]">-->
        <!--							<label class=" form-check-label" for="TranslateExportFalse">False</label>-->
        <!--						</div>-->
        <!--					</div>-->
        <!--				</div>-->
        <!--				<div class="form-group row">-->
        <!--					<label class="col-md-2 col-form-label">Translate Key 更新</label>-->
        <!--					<div class="col-md-10">-->
        <!--						<div class="form-check form-check-inline">-->
        <!--							<input class="form-check-input" type="radio" id="TranslateKeyTrue" v-model="role.permission.Key" :value="['update']">-->
        <!--							<label class=" form-check-label" for="TranslateKeyTrue">True</label>-->
        <!--						</div>-->
        <!--						<div class="form-check form-check-inline">-->
        <!--							<input class="form-check-input" type="radio" id="TranslateKeyFalse" v-model="role.permission.Key" :value="[]">-->
        <!--							<label class=" form-check-label" for="TranslateKeyFalse">False</label>-->
        <!--						</div>-->
        <!--					</div>-->
        <!--				</div>-->
        <div class="row">
          <div class="update ml-auto mr-auto">
            <button type="submit" class="btn btn-primary btn-round" @click="submit">送出</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RoleCreate',
  data: function() {
    return {
      role: {
        name: '',
        permission: {
          'Role': [],
          'User': [],
          'GameType': [],
          'WinningType': [],
          'Team': [],
          'Player': [],
          'Season': [],
          'Live': [],
          'Hand': [],
          'Vote': [],
          'Lottery': [],
          'Registration': []
        }
      },
      permission: [
        'Role',
        'User',
        'GameType',
        'WinningType',
        'Team',
        'Player',
        'Season',
        'Live',
        'Hand',
        'Vote',
        'Lottery',
        'Registration'
      ]
    }
  },
  mounted() {
  },
  methods: {
    submit: function() {
      this.$store.commit('OPEN_LOADING')

      this.$store.dispatch('roleCreate', { credentials: this.role }).then((res) => {
        if (!res.errorCode) {
          this.$store.commit('CLOSE_LOADING')
          this.$router.push({ name: 'Role.List' })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
