import { render, staticRenderFns } from "./SetLose.vue?vue&type=template&id=ede57966&scoped=true&"
import script from "./SetLose.vue?vue&type=script&lang=js&"
export * from "./SetLose.vue?vue&type=script&lang=js&"
import style0 from "./SetLose.vue?vue&type=style&index=0&id=ede57966&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ede57966",
  null
  
)

export default component.exports