import Axios from './_axios'

export default {
  seasonCreate(getters, { credentials }) {
    const formdata = new FormData()
    formdata.append('gtId', credentials.gtId)
    formdata.append('name', credentials.name)
    formdata.append('type', credentials.type)
    formdata.append('teams', JSON.stringify(credentials.teams))
    formdata.append('rankingFirst', credentials.setting.rankingFirst)
    formdata.append('rankingSecond', credentials.setting.rankingSecond)
    formdata.append('rankingThird', credentials.setting.rankingThird)
    formdata.append('rankingFourth', credentials.setting.rankingFourth)

    const url = getters.apiUrl + 'Season/create'
    return Axios.post(
      url, formdata
    ).then(res => {
      return res
    })
  },

  seasonUpdate(getters, { credentials }) {
    const formdata = new FormData()
    formdata.append('gtId', credentials.season.gtId)
    formdata.append('name', credentials.season.name)
    formdata.append('type', credentials.season.type)
    formdata.append('teams', JSON.stringify(credentials.teams))
    formdata.append('rankingFirst', credentials.season.setting.rankingFirst)
    formdata.append('rankingSecond', credentials.season.setting.rankingSecond)
    formdata.append('rankingThird', credentials.season.setting.rankingThird)
    formdata.append('rankingFourth', credentials.season.setting.rankingFourth)
    const url = getters.apiUrl + 'Season/update/' + credentials.id
    return Axios.post(
      url, formdata
    ).then(res => {
      return res
    })
  },
  seasonCopy(getters, { credentials }) {
    const formdata = new FormData()
    formdata.append('name', credentials.name)
    const url = getters.apiUrl + 'Season/copy/' + credentials.id
    return Axios.post(
      url, formdata
    ).then(res => {
      return res
    })
  },

  getLastSeason(getters, { credentials }) {
    const url = getters.apiUrl + 'Season/getLastSeasonId/' + credentials.seasonId
    return Axios.post(
      url
    ).then(res => {
      return res
    })
  }
}

