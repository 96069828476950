<template>
  <div>
    <svg :width="width" :height="height" :style="getSvgStyle()">

      <text v-for="(t,i) in xtagDisplay" v-if="_cfgs.showXTag" :key="i" :x="gridPosition.xtagPos[i].x" :y="gridPosition.xtagPos[i].y" :font-size="_cfgs.fontSize" :stroke="_cfgs.fontColor" text-anchor="middle" alignment-baseline="middle">{{ t }}</text>

      <g v-for="(pathText, p) in gridPosition.paths" :key="p">
        <path :d="gridPosition.paths[p]" :stroke="_lineColor[p]" :stroke-width="gridPosition.lineWidth" fill="none" />
        <circle v-for="(t,i) in xtags" v-show="gridPosition.dotShow[p][i]" :key="i" :cx="gridPosition.linePos[p][i].x" :cy="gridPosition.linePos[p][i].y" :r="_cfgs.dotSize" :fill="_lineColor[p]" />
      </g>

      <line v-for="(t, i) in yData" v-if="gridPosition.showXLine[i]" :key="i" :stroke="_cfgs.gridColor" :stroke-width="strokeWidthGrid" :y1="gridPosition.ypos[i]" :x1="axis.x.x1" :y2="gridPosition.ypos[i]" :x2="axis.x.x2" />
      <text v-for="(t,i) in yData" v-if="_cfgs.showYNumber && t!==false" :key="i" :x="gridPosition.ytagPos[i].x" :y="gridPosition.ytagPos[i].y" :font-size="_cfgs.fontSize" :stroke="_cfgs.fontColor" text-anchor="end" alignment-baseline="end">{{ t }}</text>

      <line v-if="_cfgs.showAxisX" :stroke="_cfgs.axisColor" :stroke-width="strokeWidthAxis" :x1="axis.x.x1" :y1="axis.x.y1" :x2="axis.x.x2" :y2="axis.x.y2" />
      <line v-if="_cfgs.showAxisY" :stroke="_cfgs.axisColor" :stroke-width="strokeWidthAxis" :x1="axis.y.x1" :y1="axis.y.y1" :x2="axis.y.x2" :y2="axis.y.y2" />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'LuColumnChart',
  props: [
    'xtags', 'datas', 'cfgs',
    'width', 'height', 'bgcolor',
    'lineColor'
  ],
  data() {
    return {
      aniStartTime: 0,
      drawFullRate: 1,
      pdLeft: 80,
      pdTop: 50,
      pdRight: 80,
      pdBottom: 80,
      strokeWidthAxis: 2,
      strokeWidthGrid: 1
    }
  },
  computed: {
    _lineColor: function() {
      var lineColor = this.lineColor || '#77bba0'
      if (typeof (lineColor) === 'string') lineColor = [lineColor]
      var ary = this.datas.map((c) => { return c.color || lineColor })
      return ary
    },
    _fillColor: function() {
      var fillColor = this.fillColor || '#99ddc0'
      if (typeof (fillColor) === 'string') fillColor = [fillColor]
      var ary = this.datas.map((c) => { return c.fill || fillColor })
      return ary
    },
    _cfgs: function() {
      var defaultVal = {
        aniTime: 600,
        axisColor: '#444444', gridColor: '#999999',
        dotSize: 0,
        fontColor: '#111111', fontSize: 14,
        showAxisX: true, showAxisY: true,
        showAxisXGrid: true,
        showYNumber: true, showXTag: true,
        maxXNumber: 35
      }
      return Object.assign(defaultVal, this.cfgs)
    },
    chartWidth: function() {
      const w = this.getProps('width')
      return w - this.pdLeft - this.pdRight
    },
    chartHeight: function() {
      const h = this.getProps('height')
      return h - this.pdTop - this.pdBottom
    },
    axis: function() {
      const w = this.getProps('width')
      const h = this.getProps('height')
      const X = {
        x1: this.pdLeft,
        y1: h - this.pdBottom,
        x2: w - this.pdRight,
        y2: h - this.pdBottom
      }
      const Y = {
        x1: this.pdLeft,
        y1: this.pdTop,
        x2: this.pdLeft,
        y2: h - this.pdBottom
      }
      return { x: X, y: Y }
    },
    xtagDisplay: function() {
      const modNumber = Math.ceil(this.xtags.length / this._cfgs.maxXNumber)
      var ary = this.xtags.map((c, i, arr) => {
        if (i === 0 || i === arr.length - 1) {
          return c
        } else if (i % modNumber === 0) {
          return c
        } else {
          return ''
        }
      })

      return ary
    },
    yData: function() {
      var reduceData = this.datas.map((curv) => { return curv.data }).reduce((arr, crr) => { return arr.concat(crr) })
      let minValue = Math.min.apply(this, reduceData)
      let maxValue = Math.max.apply(this, reduceData)

      if (maxValue > 0 && minValue > 0)minValue = 0
      if (maxValue < 0 && minValue < 0)maxValue = 0

      if (maxValue > 0) {
        var tmpPs0 = maxValue - 0
        if ((0 - minValue) / tmpPs0 < 0.2) minValue = 0 - Math.round(tmpPs0 * 0.2)
      }

      if (minValue < 0) {
        var tmpNs0 = 0 - minValue
        if (Math.abs(0 - maxValue) / tmpNs0 < 0.2) maxValue = Math.round(tmpNs0 * 0.2)
      }
      // const rangeValue = maxValue - minValue
      const step = 5
      // if(rangeValue > 100) step = 10;
      const stepMinMax = this.getMinMaxStep(step, minValue, maxValue)
      let yData = []
      for (var i = stepMinMax.min; i <= stepMinMax.max; i += step) { yData.push(i) }
      yData.reverse()

      // 只秀 Max , 0 , Min
      yData = yData.map((c, i) => {
        if (i === 0 || i === yData.length - 1 || c === 0) {
          return c
        } else {
          return false
        }
      })
      return yData
    },
    gridPosition: function() {
      let w = 0; let h = 0
      const xlen = this.xtags.length
      const cfgs = this.cfgs
      w = Math.round(this.chartWidth / (xlen - 1))

      const xpos = []
      const xtagPos = []
      const xIntervalWidth = ((1 / (xlen - 1)) * this.chartWidth)
      const lineWidth = Math.floor(xIntervalWidth / this.datas.length)
      const offsetLeftPosX = Math.ceil(lineWidth / 2)
      for (var i = 0; i < xlen; i++) {
        const xposx = Math.round((i / (xlen - 1)) * this.chartWidth) + this.pdLeft
        xpos[i] = xposx
        xtagPos[i] = { x: xposx, y: this.chartHeight + this.pdTop + 30 }
      }

      // 計算 Y 高度
      const yData = this.yData
      const ylen = this.yData.length
      h = Math.round(this.chartHeight / (ylen - 1))

      const ypos = []
      const ytagPos = []
      let zeroPosY = 0
      for (let i = 0; i < ylen; i++) {
        const yposy = Math.round((i / (ylen - 1)) * this.chartHeight) + this.pdTop
        // let yposy = h * i + this.pdTop;
        ypos[i] = yposy
        ytagPos[i] = { x: this.pdLeft - 10, y: yposy }
        if (yData[i] === 0) zeroPosY = yposy
      }

      // 計算資料座標
      const linePos = []
      const dotShow = []
      var paths = []
      for (var p = 0; p < this.datas.length; p++) {
        linePos[p] = []
        dotShow[p] = []
        var pathAry = []
        // var pathFillAry = []
        for (let i = 0; i < xlen; i++) {
          var lx = xpos[i] + (p * lineWidth) + offsetLeftPosX
          var ly = ((this.yData[0] - this.datas[p].data[i]) / (this.yData[0] - this.yData[this.yData.length - 1])) * this.chartHeight + this.pdTop
          if (this.drawFullRate < 1) {
            var offsetZeropToTop = (zeroPosY - ly) * (1 - this.drawFullRate)
            ly = ly + offsetZeropToTop
          }
          linePos[p][i] = { x: lx, y: ly }
          dotShow[p][i] = !this.dataDetectNull(this.datas[p].data[i])
          if (this.datas[p].data[i] !== 0) {
            pathAry.push('M' + lx + ' ' + ly)
            pathAry.push('L' + lx + ' ' + zeroPosY)
          } else {
            pathAry.push('M' + lx + ' ' + (zeroPosY - 3))
            pathAry.push('L' + lx + ' ' + (zeroPosY + 3))
          }
        }
        paths[p] = pathAry.join(' ')
      }

      // 計算顯示與否
      const showXLine = ypos.map((c, idx) => {
        if (yData[idx] === false || idx === ypos.length - 1 || yData[idx] !== 0) {
          return false
        } else {
          return cfgs.showAxisXGrid
        }
      })

      return {
        xpos: xpos,
        xtagPos: xtagPos,
        ypos: ypos,
        ytagPos: ytagPos,
        dotShow: dotShow,
        linePos: linePos,
        lineWidth: lineWidth,
        path: paths[0],
        paths: paths,
        showXLine: showXLine,
        height: h,
        width: w
      }
    }
  },
  watch: {
    $props: {
      handler: function() {
        this.enableDraw()
      },
      deep: true
    }
  },
  mounted: function() {
    this.enableDraw()
  },
  methods: {
    getProps: function(st) {
      switch (st) {
        case 'width':
          return parseInt(this.width) || 800

        case 'height':
          return parseInt(this.height) || 600
      }
    },
    getMinMaxStep: function(step, min, max) {
      var smin = min - (min % step); var smax = max - (max % step) + step
      if (min < 0) smin = smin - step
      return { min: smin, max: smax }
    },
    getSvgStyle: function() {
      const bgcolor = this.bgcolor || '#ffffff'
      return 'background-color:' + bgcolor + ';'
    },
    dataDetectNull: function(c) {
      return !!(c === '' || c === false || c === null)
    },
    drawAni: function() {
      var msec = Date.now() - this.aniStartTime
      var rate = msec / this._cfgs.aniTime
      if (rate >= 1) rate = 1
      this.drawFullRate = rate
      if (rate < 1) {
        window.requestAnimationFrame(this.drawAni)
      }
    },
    enableDraw: function() {
      this.aniStartTime = Date.now()
      this.drawAni()
    }
  }
}
</script>
