<template>
  <div>
    <bingo v-for="(item,i) in list" :key="i" :bingo="item" />
  </div>
</template>
<script>
import bingo from '@/components/Show/bingo/bingo'

export default {
  name: 'BingoPrint',
  components: {
    bingo
  },
  data: function() {
    return {
      list: []
    }
  },
  computed: {
    multipleSelection: function() {
      return this.$route.params.ids
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.loading = true
      this.$store
        .dispatch('getPrintIndex', { credentials: this.multipleSelection })
        .then((res) => {
          if (!res.errorCode) {
            this.loading = false
            this.list = res.data.items
          }
        })
    }
  }
}
</script>
<style lang="scss">
@import './style/print';

</style>
