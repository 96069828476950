<template>
  <div class="col-md-12">
    <div class="card">
      <div class="card-body">
        <div class="table">
          <table class="table">
            <thead class=" text-primary">
              <tr>
                <th>System Name</th>
                <th>Display Name</th>
                <th>
                  <router-link v-if="$dataManager.getters.permissions.Team.includes('create')" :to="{name:'Team.Create'}">
                    <div class="btn btn-xs btn-success btn-round pull-right">Create</div>
                  </router-link>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item,i) in team" :key="i">
                <td v-text="item.name" />
                <td v-text="item.displayName" />
                <td>
                  <router-link
                    v-if="$dataManager.getters.permissions.Team.includes('update')"
                    :to="{name:'Team.Update',params:{id:item.id}}"
                  >
                    <div class="btn btn-xs btn-primary  pull-right">Update</div>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TeamList',
  data: function() {
    return {
      team: []
    }
  },
  computed: {},
  mounted() {
    this.getData()
  },
  methods: {
    getData: function() {
      this.$store.commit('OPEN_LOADING')
      this.$store.dispatch('getList', { type: 'Team', action: 'list' }).then((res) => {
        if (!res.errorCode) {
          this.team = res.data
          this.$store.commit('CLOSE_LOADING')
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
