<template>
  <div class="floatTsumoData">
    <div v-for="(i,x) in teamsDetail" :key="x" class="item" :style="'background-color:'+i.color">
      <div class="title" :style="'border-color:'+i.color+';color:'+i.color">
        <div class="team-item"><div class="team-label" :style="'background-color:'+i.color" v-text="i.displayName" /></div>
        放槍數
      </div>
      <div class="content" v-text="i.detail.loseCount" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FloatLoseData',
  data() {
    return {
      season: {},
      teams: [],
      detail: [],
      isLoading: false,
      liveSetting: {}
    }
  },
  computed: {
    teamsDetail: function() {
      var res = []

      this.teams.forEach((team) => {
        this.detail.forEach((i) => {
          if (team.id === i.teamId) {
            let data = {}
            data = team
            data.detail = i.data
            res.push(data)
          }
        })
      })
      res.sort(function(a, b) {
        return a.teamRank - b.teamRank
      })
      return res
    }
  },
  mounted() {
    this.$bus.$on('liveSetting', content => {
      this.liveSetting = content
      this.getData()
    })
  },
  methods: {
    getData: function() {
      this.$store.commit('CLOSE_LOADING')
      this.$store.dispatch('getData', { type: 'Season', credentials: this.liveSetting.seasonId }).then((res) => {
        if (!res.errorCode) {
          this.season = res.data
          this.teams = res.data.teams
          this.$store.commit('UPDATE_TEAM', res.data.teams)
          this.$store.dispatch('teamDetailBySeason', { credentials: this.liveSetting.seasonId }).then((res) => {
            if (!res.errorCode) {
              this.detail = res.data
              this.isLoading = true
            }
          })
          this.isLoading = true
        }
      })
    }
  }
}
</script>

<style lang="scss">
  @import '../../../style/show/floatTsumoData';
</style>
