<template>
  <div class="winCard" :class="classObject">
    <img :src="require('./images/'+ card.id +'.png')" alt="" @click="setCard(card)">
  </div>
</template>

<script>
export default {
  name: 'GetCard',
  components: {},
  props: ['card', 'chooseCard'],
  data() {
    return {

    }
  },
  computed: {
    classObject: function() {
      return {
        'choose': this.chooseCard.id === this.card.id
      }
    }
  },
  methods: {
    setCard(card) {
      this.$emit('setCard', card.id)
      this.$store.commit('UPDATE_WINCARD', card.id)
    }
  }
}
</script>

<style lang="scss" scoped>
  .winCard {
    width: 4rem;
    height: 6rem;
    background-size: contain;
    filter: brightness(0.6);
    transition:  all .3s cubic-bezier(0.18, 0.89, 0.32, 1.28);

    cursor: pointer;
    &.choose,&:hover{
      transform: scale(1.5);
      filter: drop-shadow(2px 4px 6px black);

    }
  }
</style>
