<template>
  <div class="lottery">
    <div class="header">
      <div class="btn" @click="submit">送出</div>
    </div>
    <div v-for="(p, idx) in result" :key="idx" class="lotteryBoxDisaply box" :style="lotteryBoxPosition(idx)" @click="selectId($event,idx)">
      <div class="lotteryText" v-text="p.title" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SetPosition',
  data() {
    return {
      id: this.$route.params.id,
      result: [],
      nowId: -1,
      mousePos: {
        x: 0, y: 0
      },
      startMousePos: {
        x: 0, y: 0
      }
    }
  },
  computed: {
    lotteryPosition: function() {
      var data = []
      this.result.forEach((item) => {
        const i = {
          x: this.getRandom(50, 1031),
          y: this.getRandom(50, 1892)
        }
        data.push(i)
      })
      return data
    },
    details: function() {
      var data = []
      this.result.forEach((item) => {
        var i = {
          id: item.id,
          x: item.x,
          y: item.y
        }
        data.push(i)
      })
      return data
    }
  },
  mounted() {
    this.getData()
    var $this = this
    window.addEventListener('mousemove', function(evt) {
      // 滑鼠移動時，將最新位置記錄到vue中
      var nowMousePos = { x: evt.x, y: evt.y }
      $this.mousePos = nowMousePos
      if ($this.nowId > -1) {
        evt.preventDefault()
        $this.result[$this.nowId].x = nowMousePos.y - $this.startMousePos.y
        $this.result[$this.nowId].y = nowMousePos.x - $this.startMousePos.x
      }
    })

    window.addEventListener('dblclick', function(evt) {
      $this.nowId = -1
    })
  },
  methods: {
    getRandom: function(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min
    },
    lotteryBoxPosition: function(idx) {
      var rt = ''
      var pos = this.result[idx]
      if (pos && !pos.x && !pos.y) {
        pos = this.lotteryPosition[idx]
        rt = 'top:' + pos.x + 'px; left:' + pos.y + 'px;'
      } else {
        pos = this.result[idx]
        rt = 'top:' + pos.x + 'px; left:' + pos.y + 'px;'
      }
      return rt
    },
    selectId(evt, id) {
      this.startMousePos = { x: evt.offsetX, y: evt.offsetY }
      var cl = evt.srcElement.classList
      if (cl.contains('lotteryBoxDisaply') || cl.contains('lotteryText')) {
        this.nowId = id
      } else {
        this.nowId = -1
      }
      evt.preventDefault()
    },
    submit: function() {
      this.$store.dispatch('LotterySetPosition', { credentials: this.details }).then((res) => {
        if (!res.errorCode) {
          this.$alert({ type: 'success', msg: 'Success Setting.', duration: 3 * 1000 })
          this.$router.push({ name: 'Show.lotteryResult', params: { id: this.id }})
        }
      })
    },
    getData: function() {
      this.$store.dispatch('LotteryGetResult', { credentials: this.id }).then((res) => {
        if (!res.errorCode) {
          this.result = res.data
        }
      })
    }
  }
}
</script>

<style lang="scss">
  $fontAddition: 1;
  .lottery {
    position: relative;
    width: 100%;
    height: 100%;

    .lotteryBoxDisaply {
      position: absolute;
      cursor: pointer;
      color: #ddd;
      font-size: 64px*$fontAddition;
      line-height: 96px*$fontAddition;
      font-family: 'Noto Sans TC';
      font-weight: 900;
    }
  }
</style>
