<template>

  <div class="card card-user">
    <div class="card-body">
      <form>
        <div class="form-group row">
          <label class="col-md-2 col-form-label">歸屬User</label>
          <div class="col-md-10">
            <select v-model="team.userId" class="custom-select">
              <option v-for="(item,i) in users" :key="i" :value="item.id" v-text="item.name" />
            </select>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-2 col-form-label">System Name</label>
          <div class="col-md-10">
            <input v-model="team.name" type="text" class="form-control">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-2 col-form-label">Display Name</label>
          <div class="col-md-10">
            <input v-model="team.displayName" type="text" class="form-control">
          </div>
        </div>
        <div class="row">
          <div class="update ml-auto mr-auto">
            <button type="submit" class="btn btn-primary btn-round" @click="submit">送出</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TeamUpdate',
  data: function() {
    return {
      id: this.$route.params.id,
      users: [],
      team: {}
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData: function() {
      this.$store.commit('OPEN_LOADING')
      this.$store.dispatch('getList', { type: 'User', action: 'list' }).then((res) => {
        if (!res.errorCode) {
          this.users = res.data
          this.$store.dispatch('getData', { type: 'Team', credentials: this.id }).then((res) => {
            if (!res.errorCode) {
              this.team = res.data
              this.$store.commit('CLOSE_LOADING')
            }
          })
        }
      })
    },

    submit: function() {
      this.$store.commit('OPEN_LOADING')
      var data = {
        id: this.id,
        team: this.team
      }
      this.$store.dispatch('teamUpdate', { credentials: data }).then((res) => {
        if (!res.errorCode) {
          this.$store.commit('CLOSE_LOADING')
          this.$router.push({ name: 'Team.List' })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
