<template>
  <div :style="outStyle" class="hori-bar-out">
    <div v-for="(p, idx) in datas" :key="idx" style="flex:1; display:inline-flex;" class="hori-bar-sect">
      <span :style="nameStyle(p)" v-text="p.name" />
      <!--      <div :style="nameStyle(p)" class="hori-bar-name">-->
      <!--        <div :style="bgOfCodeName(p)">-->
      <!--          &lt;!&ndash;          <div class="iconText" :style="bgOfCodeNameStr(p)">{{ aryCodeName[idx] }}</div>&ndash;&gt;-->
      <!--          <div class="iconText" :style="bgOfCodeNameStr(p)">{{ p.name }}</div>-->
      <!--          <div :style="bgOfCodeNameIcon(p)" v-html="bgIconSvg(p)"></div>-->
      <!--        </div>-->
      <!--        &lt;!&ndash;        <span style="white-space:nowrap;font-size: 32px;font-family: 'ReservoirGrunge';">{{ p.name }}</span>&ndash;&gt;-->
      <!--      </div>-->
      <div :style="barStyle" class="hori-bar-block">
        <div :style="barBoderStyle(p)">
          <div :style="barBlockStyle(p)" />
        </div>
      </div>
      <div :style="countStyle(p)" class="hori-bar-count">{{ p.count }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LuHoriBar',
  props: [
    'datas', 'bgcolor', 'padding', 'barBorderColor', 'barBorderWidth',
    'fontSize', 'fontColor', 'fontFamily'
  ],
  data() {
    return {
      aryCodeName: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q'],
      comeZero: true
    }
  },
  computed: {
    _fontSize: function() {
      return this.fontSize || '14px'
    },
    _fontColor: function() {
      return this.fontColor || '#111111'
    },
    _fontFamily: function() {
      return this.fontFamily || 'Arial'
    },
    _svgIconHeight: function() {
      const f = this._fontSize
      let unit = 'px'
      if (/rem$/.test(f)) {
        unit = 'rem'
      } else if (/em$/.test(f)) {
        unit = 'em'
      }
      const num = parseFloat(f.replace(unit, ''))

      return num * 2 + unit
    },
    maxCount: function() {
      var maxCount = 0
      this.datas.map((c) => {
        maxCount = Math.max(maxCount, c.count)
        return c.count
      })
      return maxCount
    },
    barStyle: function() {
      return 'display:table-cell;max-width:40%;width:40%; position:relative;'
    },
    outStyle: function() {
      const bgcolor = this.bgcolor || 'transparent'
      const padding = this.padding || '0px'
      const fontSize = (this.fontSize) ? 'font-size:' + this.fontSize + ';' : ''
      const fontColor = (this.fontColor) ? 'color:' + this.fontColor + ';' : ''
      const fontFamily = (this.fontFamily) ? 'font-family:' + this.fontFamily + ';' : ''
      const heightR = 'height:' + this._svgIconHeight + '; line-height:' + this._svgIconHeight + ';'
      return 'background-color:' + bgcolor + '; display:flex; padding:' + padding + ';' + fontSize + fontColor + fontFamily + heightR
    }
  },
  watch: {
    $props: {
      handler: function() {
        this.enableDraw()
      },
      deep: true
    }
  },
  created: function() {
    setTimeout(() => {
      this.comeZero = false
    }, 100)
  },
  methods: {
    nameStyle: function(p) {
      return 'display:table-cell;max-width:40%;text-align:right; padding-right:1%;display:flex;font-family: "Noto Sans TC"; height:100%; color:' + p.color + ';'
    },
    countStyle: function(p) {
      return 'display:table-cell;font-size:48px;max-width:20%;width:20%;text-align:left; padding-left:1%; color:' + p.color + ';font-family: "GT Walsheim Pro Regular"'
    },
    barBlockStyle: function(p) {
      // maxCount
      let percent = Math.round(p.count / this.maxCount * 100)
      if (this.comeZero) percent = 1
      return 'position:absolute; background-color:' + p.color + '; display:inline-block; height:100%; width:' + percent + '%; transition: all 0.8s ease;'
    },
    barBoderStyle: function(p) {
      const bw = (this.barBorderWidth) ? this.barBorderWidth : '1px'
      let border = (this.barBorderColor) ? 'border:' + bw + ' solid ' + this.barBorderColor + ';' : 'border:' + bw + ' solid transparent;'
      if (p.borderColor) border = 'border: ' + bw + ' solid ' + p.borderColor + ';'
      return 'position:relative; box-sizing:border-box; width:100%; height:' + this._fontSize + '; top:calc(0.5 * ' + this._fontSize + '); ' + border
    },
    bgIconSvg: function(p) {
      var str = ''
      if (p.iconSvg) {
        str = p.iconSvg.replace('fill="none"', 'fill="' + p.color + '" style="width:100%;transform:scale(1.3)"').replace('fill="none"', 'fill="' + p.color + '" style="width:100%;transform:scale(0.8);margin-top: 30px;margin-left: 30px;"')
      }
      return str
    },
    bgOfCodeName: function(p) {
      var rt = 'display:inline-block;height:100%;width:' + this._svgIconHeight + '; position:relative;'

      return rt
    },
    bgOfCodeNameStr: function(p) {
      return 'position:relative; z-index:3;padding-top:6px;padding-right:6px; text-align:center;height:100%;color:' + this.bgcolor + ';display:flex;align-items: center;justify-content: center;font-size:2.4rem'
    },
    bgOfCodeNameIcon: function(p) {
      var rt = 'display:inline-block;height:100%; position:absolute; top:-.2rem; left:1rem; z-index:1;'
      return rt
    },
    enableDraw: function() {
    }
  }
}
</script>

<style lang="scss">
.hori-bar-out {
  width: 100%;
  font-weight: 900;
  /*font-family: '蒙納盈富體' !important;*/
}

.iconText {
  font-family: 'ReservoirGrunge' !important;
  font-size: 24px;

}

.hori-bar-count {
  margin-left: 5px;
}

svg {
  vertical-align: revert;
}
</style>
