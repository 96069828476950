var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('div',{staticClass:"dailyShow"},[_vm._l((_vm.players),function(item,i){return _c('div',{key:i,staticClass:"item",style:(_vm.seatPosition(item))},[(item.host && _vm.liveSetting.status.combo > 0)?_c('div',{staticClass:"host-count",domProps:{"innerHTML":_vm._s(_vm.liveSetting.status.combo)}}):_vm._e(),(_vm.liveSetting.status.riichis && item.riichi)?_c('div',{staticClass:"eyeicon"}):_vm._e(),(_vm.liveSetting.status.eyes && item.eye)?_c('div',{staticClass:"earicon"}):_vm._e(),_c('div',{staticClass:"main",class:item.host ? 'host' : 'player',staticStyle:{"background-color":"transparent"}},[_c('div',{staticClass:"user"},[_c('div',{staticClass:"name",class:_vm.$dataManager.getters.mahjong.liveUpdate.lastChampion ===
              item.teamId
              ? 'king'
              : 'normal',domProps:{"innerHTML":_vm._s(item.player.displayName)}}),_c('div',{staticClass:"point",domProps:{"innerHTML":_vm._s(item.totalPoint)}})]),_c('transition',{attrs:{"name":"fade-up"}},[(_vm.pointChange)?_c('div',{staticClass:"pointAni up",class:{
            big: item.changePoint > 8 && item.changePoint < 25,
            large: item.changePoint >= 25,
            hidden: item.changePoint <= 0,
          }},[(item.changePoint !== '')?_c('span',[_vm._v("+")]):_vm._e(),_vm._v(_vm._s(item.changePoint)+" ")]):_vm._e()]),_c('transition',{attrs:{"name":"fade-down"}},[(_vm.pointChange)?_c('div',{staticClass:"pointAni down",class:{
            big: item.changePoint < -8 && item.changePoint > -25,
            large: item.changePoint <= -25,
            hidden: item.changePoint >= 0,
          }},[_vm._v(" "+_vm._s(item.changePoint)+" ")]):_vm._e()])],1)])}),_c('transition',{attrs:{"name":"slide-fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.leaderBoardChange),expression:"leaderBoardChange"}],staticClass:"leaderboard"},[_c('div',{staticClass:"content"},_vm._l((_vm.allPlayers),function(item,i){return _c('div',{key:i,staticClass:"user"},[_c('div',{staticClass:"rank",domProps:{"textContent":_vm._s(item.rank)}}),_c('div',{staticClass:"_card"},[_c('div',{staticClass:"name",class:_vm.$dataManager.getters.mahjong.liveUpdate.lastChampion ===
                  item.teamId
                  ? 'king'
                  : 'normal',domProps:{"innerHTML":_vm._s(item.player.displayName)}}),_c('div',{staticClass:"point",domProps:{"innerHTML":_vm._s(item.team.point)}})]),_c('div',{staticClass:"icon",class:item.status})])}),0)])]),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.detailChange)?_c('div',{staticClass:"detailboard"},[_c('div',{staticClass:"content"},_vm._l((_vm.players),function(item,i){return _c('div',{key:i,staticClass:"user"},[_c('div',{staticClass:"i"},[_c('div',{staticClass:"_card"},[_c('div',{staticClass:"name",class:_vm.$dataManager.getters.mahjong.liveUpdate.lastChampion ===
                    item.teamId
                    ? 'king'
                    : 'normal',domProps:{"innerHTML":_vm._s(item.player.displayName)}}),_c('div',{staticClass:"point",domProps:{"innerHTML":_vm._s(item.totalPoint)}})]),_c('div',{staticClass:"todaypoint",class:item.team.point === 0
                  ? '_neutral'
                  : item.team.point > 0
                    ? '_positive'
                    : '_negative',domProps:{"innerHTML":_vm._s(item.team.point)}})]),_c('div',{staticClass:"i detail"},[_c('div',{staticClass:"data",domProps:{"textContent":_vm._s('胡牌: ' + item.player.winCount)}}),_c('div',{staticClass:"line"}),_c('div',{staticClass:"data",domProps:{"textContent":_vm._s('放槍: ' + item.player.loseCount)}}),_c('div',{staticClass:"line"}),_c('div',{staticClass:"data",domProps:{"textContent":_vm._s('自摸: ' + item.player.tsumoCount)}})])])}),0)]):_vm._e()]),_c('div',{staticClass:"windshow"},[_c('div',{staticClass:"round",domProps:{"textContent":_vm._s(_vm.liveSetting.status.game)}}),_c('div',{staticClass:"wind",domProps:{"textContent":_vm._s(_vm.$dataManager.positionName(_vm.liveSetting.status.round))}}),(_vm.liveSetting.status.riichied !== 0)?_c('div',{staticClass:"eye"},[_c('i',{staticClass:"icon"}),_c('span',{domProps:{"textContent":_vm._s(_vm.liveSetting.status.riichied)}})]):_vm._e()])],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }