<template>
  <div class="content">
    <div class="list">
      <div v-for="(seat,i) in tsumoData" :key="i" class="_row">
        <div
          class="name"
          v-text="(seat.isHost=== true)?seat.player.name+'(莊家)': seat.player.name"
        />
        <div class="point" :class="$dataManager.pointColor(seat.hand_point)" v-text="seat.hand_point" />
      </div>
    </div>
    <setCard :ok-btn="false" />
    <div class="buttons">
      <div class="btn capitalize" @click="close">cancel</div>
      <div class="btn upper" @click="comfrim">ok</div>
    </div>
  </div>
</template>

<script>
import setCard from '@/components/Layout/Dialog/setCards'
export default {
  name: 'SetWinPopup',
  components: { setCard },
  props: ['handsData'],
  data() {
    return {
      thisPlayer: {
        playerId: null
      }
    }
  },
  computed: {
    tsumoData() {
      var data = []
      this.$dataManager.getters.mahjong.usualUpdate.seats.forEach((seat) => {
        var item = {
          teamid: null,
          playerId: null,
          player: null,
          temp_point: null,
          hand_point: null,
          isHost: false
        }
        this.$dataManager.getters.dialog.data.forEach((hand) => {
          if (hand.playerId === seat.playerId) {
            item.playerId = hand.playerId
            item.teamid = seat.teamid
            item.player = seat.player
            item.hand_point = hand.points
            item.isHost = seat.isHost
            data.push(item)
          }
        })
      })
      return data
    }
  },
  watch: {},
  methods: {
    openSetCard: function() {
      var data = {
        visible: true,
        title: '選擇胡牌',
        type: 'setCard'
      }
      this.$store.commit('UPDATE_DIALOG', data)
    },
    close: function() {
      this.$store.commit('CLEAR_DIALOG')
    },
    comfrim: function() {
      this.$bus.$emit('confrimTsumo')
      this.$store.commit('CLEAR_DIALOG')
    }

  }
}
</script>

<style lang="scss" scoped>
.list {
  margin: 4px 4px;

  ._row {
    display: flex;
    margin: 4px auto;

    .name {
      flex: 3;
    }

    .point {
      flex: 2;
      font-weight: 700;
      color: #fff;
    }
  }
}
</style>
