<template>
  <div class="col-md-12">
    <div class="card">
      <div class="card-body">
        <div class="table">
          <table class="table">
            <thead class=" text-primary">
              <tr>
                <th>
                  標題
                </th>
                <th>
                  建立時間
                </th>
                <th>
                  建立抽獎
                </th>
                <th>
                  <router-link v-if="$dataManager.getters.permissions.Vote.includes('create')" :to="{name:'Vote.Create'}">
                    <div class="btn btn-xs btn-success btn-round">Create</div>
                  </router-link>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item,i) in vote" :key="i">
                <td v-text="item.title" />
                <td v-text="item.createdTime" />
                <td>
                  <router-link
                    v-if="$dataManager.getters.permissions.Lottery.includes('list')"
                    :to="{name:'Lottery.List',params:{voteId:item.id}}"
                  >
                    <div class="btn btn-xs btn-warning">抽獎列表</div>
                  </router-link>
                </td>
                <td>
                  <router-link
                    v-if="$dataManager.getters.permissions.Vote.includes('list')"
                    :to="{name:'Vote.Detail',params:{id:item.id}}"
                  >
                    <div class="btn btn-xs btn-primary">Detail</div>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'VoteList',
  data: function() {
    return {
      vote: []
    }
  },
  computed: {},
  mounted() {
    this.getData()
  },
  methods: {
    getData: function() {
      this.$store.commit('OPEN_LOADING')
      this.$store.dispatch('getList', { type: 'Vote', action: 'list' }).then((res) => {
        if (!res.errorCode) {
          this.$store.commit('CLOSE_LOADING')
          this.vote = res.data
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
