<template>
  <div>
    <bumpyChart :width="600" :height="350" :cfgs="configs" :bgcolor="transparent" :line-color="'#000'" :xtags="xtags" :datas="datas" />
  </div>
</template>

<script>
import bumpyChart from '@/components/majcmp/bumpyChart'

export default {
  name: 'PlayerSeasonPoint',
  components: { bumpyChart },
  data() {
    return {
      seasonId: this.$route.params.seasonId,
      playerId: this.$route.params.playerId,
      transparent: 'rgba(0,0,0,0)',
      thisHistory: [],
      configs: {
        'axisColor': '#a9a9a9', 'gridColor': '#c2c2c2',
        'dotSize': 3, 'lineWidth': 2,
        'fontSize': 12, 'fontColor': '#000000',
        'showAxisX': false, 'showAxisY': false,
        'showAxisXGrid': false, 'showAxisYGrid': false,
        'showAxisTop': false, 'showAxisRight': false,
        'showYNumber': true, 'showXTag': true,
        'maxYNumber': 10
      },
      colorList: [
        {
          thisM: {
            line: 'rgba(255, 169, 16,1)',
            fill: 'rgba(255, 169, 16,.6)'
          }
        },
        {
          thisM: {
            line: 'rgba(2, 103, 189,1)',
            fill: 'rgba(2, 103, 189,.6)'
          }
        },
        {
          thisM: {
            line: 'rgba(114, 45, 224,1)',
            fill: 'rgba(114, 45, 224,.6)'
          }
        },
        {
          thisM: {
            line: 'rgba(245, 34, 3,1)',
            fill: 'rgba(245, 34, 3,.6)'
          }
        }
      ]
    }
  },
  computed: {
    datas() {
      var res = []
      var index = this.getRandom(0, 3)
      var item = {}
      item.color = this.colorList[index].thisM.line
      item.fill = this.colorList[index].thisM.fill
      var data = []
      if (this.thisHistory.length > 0) {
        // eslint-disable-next-line no-unused-vars
        var played = false
        this.thisHistory.forEach((i) => {
          played = true
          data.push(i.points)
        })
      }
      item.data = data
      res.push(item)
      return res
    },
    xtags() {
      var res = []
      this.thisHistory.forEach((i, idx) => {
        var date = i.createdTime.split(' ')[0].split('-')
        var str = date[2]
        res.push(str)
      })
      return res
    }
  },
  watch: {},
  mounted() {
    this.getData()
  },
  methods: {
    dataInterval: function(id) {
      clearInterval(this.interval)
      this.getData(id)
      var $this = this
      setInterval(() => {
        $this.getData(id)
      }, 10 * 1010)
    },
    getData: function() {
      var credentials = {
        seasonId: this.seasonId,
        playerId: this.playerId
      }

      this.$store.dispatch('getPointByPlayer', { credentials }).then((res) => {
        if (!res.errorCode) {
          this.thisHistory = res.data
        }
      })
    },
    getRandom: function(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min
    }
  }
}
</script>

<style scoped>

</style>
