<template>
  <div class="content forceStatus">
    <select v-model="round" class="custom-select">
      <option value="e" v-text="'東'" />
      <option value="s" v-text="'南'" />
      <option value="w" v-text="'西'" />
      <option value="n" v-text="'北'" />
    </select>
    <div class="seats">
      <div
        v-for="(seat, i) in $dataManager.getters.mahjong.usualUpdate.seats"
        :key="i"
        class="seat"
      >
        <div class="seat-content">
          <div class="player">
            <span
              v-if="seat.playerId !== null"
              v-text="seat.player.displayName || seat.player.name"
            />
            <span
              v-if="seat.playerId === null"
              v-text="$dataManager.positionName(seat.position)"
            />
          </div>
          <div class="buttons">
            <div
              v-if="seat.setPlayer && !seat.isHost"
              class="btn"
              @click="isDealer(seat)"
            >
              莊家
            </div>
            <div
              v-if="seat.setPlayer && seat.isHost"
              class="btn"
              @click="changeCombo(seat, +1)"
            >
              +
            </div>
            <div
              v-if="seat.setPlayer && seat.isHost"
              class="btn"
              @click="changeCombo(seat, -1)"
            >
              -
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ForceStatus',
  data() {
    return {
      round: 'e'
    }
  },
  watch: {
    round(val) {
      // this.$dataManager.getters.mahjong.usualUpdate.prevailing = val
      this.$root.liveSetting.status.round = val
    }
  },
  methods: {
    isDealer: function(seat) {
      this.$dataManager.getters.mahjong.usualUpdate.seats.forEach((item) => {
        if (seat.playerId === item.playerId) {
          item.isHost = true
          this.$dataManager.getters.mahjong.usualUpdate.dealer = item.position
          this.$root.liveSetting.status.wind = item.position
        } else {
          item.isHost = false
        }
      })
      this.$bus.$emit('saveStore')
    },
    changeCombo: function(seat, type) {
      this.$dataManager.getters.mahjong.usualUpdate.seats.forEach((item) => {
        if (seat.playerId === item.playerId) {
          item.combo += type
          this.$root.liveSetting.status.combo += type
        } else {
          item.combo = 0
        }
      })
      this.$bus.$emit('saveStore')
    }
  }
}
</script>

<style lang="scss" scoped>
.forceStatus {
  min-height: 9rem;
  .custom-select {
    margin: 8px 0;
  }
  .seats {
    .seat {
      min-height: 4.5rem;

      .seat-content {
        overflow: inherit;
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      .player {
        font-size: 1.6rem;
        margin: 4px 0;
      }
    }
  }
}
</style>
