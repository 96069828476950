<template>
  <div class="col-md-12">
    <div class="card">
      <div class="card-body">
        <div class="table">
          <table class="table">
            <thead class=" text-primary">
              <tr>
                <th>DateTime</th>
                <th>Reason</th>
                <th>Point</th>
                <th>Experience</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item,i) in logs" :key="i">
                <td v-text="item.dateTime" />
                <td v-text="item.reason" />
                <td v-text="item.point" />
                <td v-text="item.experience" />
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PointLog',
  data: function() {
    return {
      authorChannelId: this.$route.params.authorChannelId,
      liveChatId: this.$route.params.liveChatId,
      logs: []
    }
  },
  computed: {},
  mounted() {
    this.getData()
  },
  methods: {
    getData: function() {
      this.$store.commit('OPEN_LOADING')
      var data = { authorChannelId: this.authorChannelId, liveChatId: this.liveChatId }
      this.$store.dispatch('viewerPointLog', { credentials: data }).then((res) => {
        if (!res.errorCode) {
          this.logs = res.data
          this.$store.commit('CLOSE_LOADING')
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
