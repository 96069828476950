<template>
  <div class="card card-user">
    <div class="card-body">
      <h3 v-text="team.name" />
      <div class="form-group row">
        <label class="col-md-2 col-form-label">搜尋對伍</label>
        <div class="col-md-10">
          <input v-model="filter" type="text" class="form-control">
        </div>
      </div>
      <div class="form-group row">
        <table class="table">
          <tr>
            <td>系統名稱</td>
            <td>顯示名稱</td>
            <td>#</td>
          </tr>
          <tr v-for="(player,i) in findPlayers" :key="i">
            <td v-if="player.id !== undefined" v-text="player.name" />
            <td v-if="player.id !== undefined" v-text="player.displayName" />
            <td>
              <input v-model="player.active" type="checkbox">
            </td>
          </tr>
        </table>
      </div>
      <div class="row">
        <div class="update ml-auto mr-auto">
          <button type="submit" class="btn btn-primary btn-round" @click="submit">送出</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Detail',
  data: function() {
    return {
      id: this.$route.params.id,
      team: {},
      filter: '',
      players: []
    }
  },
  computed: {
    findPlayers: function() {
      var arr = []
      this.players.forEach((item) => {
        if (item.name.indexOf(this.filter) !== -1) {
          arr.push(item)
        }
      })
      return arr
    },
    playersArr: function() {
      var arr = []
      this.findPlayers.forEach((item) => {
        if (item.active === true) {
          arr.push(item.id)
        }
      })
      return arr
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    submit: function() {
      this.$store.commit('OPEN_LOADING')
      var credentials = {
        teamId: this.id,
        playerIds: this.playersArr
      }
      this.$store.dispatch('playerAddTeam', { credentials: credentials }).then((res) => {
        if (!res.errorCode) {
          this.$store.commit('CLOSE_LOADING')
          this.$router.go(-1)
        }
      })
    },
    getData: function() {
      this.$store.commit('OPEN_LOADING')
      this.$store.dispatch('getData', { type: 'Team', credentials: this.id }).then((res) => {
        if (!res.errorCode) {
          this.team = res.data
          this.$store.dispatch('getList', { type: 'Player', action: 'list' }).then((res) => {
            if (!res.errorCode) {
              this.players = res.data
              this.players.forEach((player) => {
                player.active = false
              })
              this.team.players.forEach((p) => {
                this.players.forEach((player) => {
                  if (p.id === player.id) {
                    player.active = true
                  }
                })
              })
              this.$store.commit('CLOSE_LOADING')
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
