<template>
  <div class="card card-user">
    <div class="card-body">

      <span v-text="lottery.title" />
      <router-link :to="{ name: 'Lottery.setPosition',params:{id:id}}" target="_blank">
        <span class="">設定位置</span>
      </router-link>
      <router-link :to="{ name: 'Show.lotteryResult',params:{id:id}}" target="_blank">
        <span class="">抽獎結果</span>
      </router-link>
      <div class="form-group row">
        <label class="col-md-2 col-form-label">選擇投票</label>
        <div class="col-md-10">
          <input v-model="vote.title" type="text" disabled class="form-control">
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-2 col-form-label">抓取選項</label>
        <div class="col-md-10">
          <select v-model="lottery.voId" class="custom-select">
            <option v-for="(item,i) in vote.options" :key="i" :value="item.voId" v-text="item.title" />
          </select>
        </div>
      </div>
      <div v-for="(prize,i) in lottery.prizes" :key="i" class="group">
        <h5 class="option" v-text="prize.title+'*'+prize.count" />
        <ul class="list-group">
          <li v-for="(item,j) in prize.result" :key="j" class="list-group-item">
            <a class="item" :href="item.channelUrl" target="_blank">
              <img :src="item.avatar" alt="">
              <p v-text="item.nickname" />
            </a>
            <div class="btn btn-danger" @click="redraw(item)">重抽</div>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="lottery.voId !==''" class="row">
      <div class="update ml-auto mr-auto">
        <div class="btn btn-primary btn-round" @click="draw">抽獎</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Detail',
  components: { },
  data() {
    return {
      id: this.$route.params.id,
      vote: {},
      lottery: {
        voId: '',
        title: '',
        prizes: []
      }
    }
  },
  computed: {
    check: function() {
      var res = false
      this.lottery.prizes.forEach((prize) => {
        res = prize.result.length === 0
      })
      return this.lottery.voId === '' ? false : res
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    draw: function() {
      this.$store.commit('CLOSE_LOADING')
      var data = {
        lotteryId: this.id,
        voId: this.lottery.voId
      }

      this.$store.dispatch('LotteryDraw', { credentials: data }).then((res) => {
        if (!res.errorCode) {
          this.lottery.prizes = res.data
          this.$store.commit('CLOSE_LOADING')
        }
      })
    },
    redraw: function(item) {
      this.$store.commit('CLOSE_LOADING')
      var data = {
        lotteryResultId: item.id
      }
      this.$store.dispatch('LotteryReDraw', { credentials: data }).then((res) => {
        if (!res.errorCode) {
          this.getData()
          this.$store.commit('CLOSE_LOADING')
        }
      })
    },
    getData: function() {
      this.$store.commit('CLOSE_LOADING')
      this.$store.dispatch('getData', { type: 'Lottery', credentials: this.id }).then((res) => {
        if (!res.errorCode) {
          this.lottery = res.data
          this.$store.dispatch('getData', { type: 'Vote', credentials: this.lottery.vId }).then((res) => {
            if (!res.errorCode) {
              this.vote = res.data
            }
          })
          this.$store.commit('CLOSE_LOADING')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .option {
    margin: 8px auto 0;
  }

  .list-group-item {
    display: flex;
    justify-content: space-between;
  }

  .item {
    display: flex;
    align-items: center;

    img {
      height: 40px;
      width: auto;
      border-radius: 50%;
    }

    p {
      margin: 0 1rem 0;

    }
  }
</style>
