import Axios from './_axios'

export default {

  RegistrationList(getters, { credentials }) {
    const formdata = new FormData()
    formdata.append('pageSize', credentials.pageSize)
    formdata.append('page', credentials.page)

    if (credentials.sortBy !== '') {
      formdata.append('sortBy', credentials.sortBy)
      formdata.append('orderBy', credentials.orderBy)
    }
    formdata.append('realName', credentials.realName)
    formdata.append('phone', credentials.phone)
    formdata.append('mail', credentials.mail)

    const url = getters.apiUrl + 'Registration/list'
    return Axios.post(
      url, formdata
    ).then(res => {
      return res
    })
  },
  disableData(getters, { credentials }) {
    const url = getters.apiUrl + 'Registration/disable/' + credentials
    return Axios.get(
      url
    ).then(res => {
      return res
    })
  },
  highlightData(getters, { credentials }) {
    const url = getters.apiUrl + 'Registration/highlight/' + credentials
    return Axios.get(
      url
    ).then(res => {
      return res
    })
  }

}
