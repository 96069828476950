import Axios from './_axios'

export default {
  getPrintIndex(getters, { credentials }) {
    console.log(credentials)
    const formdata = new FormData()
    formdata.append('ids', credentials)

    const url = getters.apiUrl + 'BingoLiveEvent/getCurrentDataByIds'
    return Axios.post(
      url,
      formdata
    ).then(res => {
      return res
    })
  },
  updatePrintStatus(getters, { credentials }) {
    console.log(credentials)
    const formdata = new FormData()
    formdata.append('ids', credentials)

    const url = getters.apiUrl + 'BingoLiveEvent/print'
    return Axios.post(
      url,
      formdata
    ).then(res => {
      return res
    })
  }
}

