<template>
  <div class="card card-user">
    <div class="card-body">
      <el-row v-if="!isLiveShowEnd" :gutter="10">
        <el-col :span="20">
          <h5 v-text="live.name" />
        </el-col>
        <el-col :span="4">
          <el-button small type="danger" icon="el-icon-lock" @click="bindLive(live.id)">綁定賓果盤</el-button>
        </el-col>
      </el-row>

      <div v-if="$dataManager.getters.mahjong.liveUpdate.setNewGame" class="alert alert-warning">
        <p style="margin: 0;">請先確認選手座位，再點擊下一雀</p>
      </div>
      <div v-if="$dataManager.getters.mahjong.liveUpdate.setNewGame" class="btn" @click="createGame()">下一雀</div>
      <div v-if="$dataManager.getters.mahjong.liveUpdate.setNewGame" class="btn" @click="endGame()">直播結束</div>
      <div v-if="isLiveShowEnd" class="form-group row">
        <label class="col-md-2 col-form-label">標題</label>
        <div class="col-md-10">
          <label>
            <input v-model="live.name" type="text" class="form-control">
          </label>
        </div>
      </div>
      <div v-if="season.type == 3 && isLiveShowEnd" class="form-group row">
        <label class="col-md-2 col-form-label">類型</label>
        <div class="col-md-10">
          <select v-model="live.type" class="custom-select">
            <option value="0" v-text="'例行賽'" />
            <option value="1" v-text="'季後賽'" />
          </select>
        </div>
      </div>
      <Seats :season-type="season.type" :game-type="season.gtId" />
      <div v-if="isLiveShowEnd&&checkAllSeated" class="row">
        <div class="update ml-auto mr-auto">
          <div class="btn btn-primary btn-round" @click="submit">送出</div>
        </div>
      </div>
    </div>
    <div v-if="!isLiveShowEnd" class="fixed-plugin" @click="liveDetail()"><i class="ti-harddrives" /></div>
    <div v-if="!isLiveShowEnd" class="fixed-plugin" style="top: 60px;" @click="forceStatus()"><i
      class="ti-settings"
    /></div>
  </div>
</template>

<script>
import Seats from '@/components/Live/Assist/Seats'

export default {
  name: 'LiveCreate',
  components: { Seats },
  data: function() {
    return {
      id: this.$route.params.seasonId,
      season: {},
      liveChatId: null,
      flag: true,
      live: {
        name: '',
        type: '0',
        startDatetime: new Date().toLocaleDateString() + ' ' + new Date().toTimeString(),
        created: false
      },
      liveSetting: {
        onLiveShow: false,
        seasonId: 0,
        liveShowId: 0,
        gameId: 0,
        nowHands: 0,
        status: {
          game: 0,
          round: '',
          wind: ''
        }
      }
    }
  },
  computed: {
    isLiveShowEnd() {
      return this.$dataManager.getters.mahjong.liveUpdate.isLiveShowEnd
    },
    checkAllSeated() {
      var data = true
      this.$dataManager.getters.mahjong.usualUpdate.seats.forEach((seat) => {
        if (!seat.setPlayer) {
          data = false
        }
      })
      return data
    }
  },
  watch: {},
  mounted() {
    this.$bus.$on('liveSetting', content => {
      setTimeout(() => {
        this.liveSetting = content
      }, 300)
    })
    this.getData()
    this.$store.commit('CLOSE_LOADING')

    if (this.isLiveShowEnd) {
      this.$bus.$emit('saveStore')
    } else {
      this.live = this.$dataManager.getters.mahjong.liveUpdate.liveShow
    }
  },
  methods: {
    liveDetail: function() {
      this.$dataManager.liveShowDetail(this.$dataManager.getters.mahjong.liveUpdate.liveShow.id).then((res) => {
        this.$store.commit('UPDATE_LIVEDETAIL', res.data)
        console.log(res.data)
        var data = {
          visible: true,
          title: '本場細節',
          type: 'liveDetail'
        }
        this.$store.commit('UPDATE_DIALOG', data)
      })
    },
    bindLive: function(id) {
      this.$store.dispatch('bingoBind', { credentials: id })
    },
    forceStatus: function() {
      var data = {
        visible: true,
        title: '強制更新狀態',
        type: 'forceStatus'
      }
      this.$store.commit('UPDATE_DIALOG', data)
    },

    submit: function() {
      var live = {
        seasonId: this.id,
        liveShow: this.live
      }
      if (this.flag) {
        this.flag = false
        this.$dataManager.createLiveShow(live).then(() => {
          this.flag = true
          this.$root.liveSetting.onLiveShow = true
          this.$root.liveSetting.seasonId = parseInt(this.id)
          this.$root.liveSetting.status.game = 0
          this.$root.liveSetting.liveShowId = this.$dataManager.getters.mahjong.liveUpdate.liveShow.id
          this.createGame()
        })
      }
    },
    createGame: function() {
      var game = {
        liveId: this.$dataManager.getters.mahjong.liveUpdate.liveShow.id,
        game: {
          position: {}
        }
      }
      this.$dataManager.createGame(game).then(() => {
        this.$root.liveSetting.status.game += 1
        this.$root.liveSetting.gameId = parseInt(this.$dataManager.getters.mahjong.liveUpdate.game.id)
        this.createRound()
      })
    },
    createRound: function() {
      var round = {
        gameId: this.$dataManager.getters.mahjong.liveUpdate.game.id,
        round: {
          dealerWind: this.$dataManager.getters.mahjong.usualUpdate.prevailing,
          prevailingWind: this.$dataManager.getters.mahjong.usualUpdate.prevailing
        }
      }
      this.$dataManager.createRound(round).then(() => {
        this.$root.liveSetting.status.round = this.$dataManager.getters.mahjong.usualUpdate.prevailing
        this.$root.liveSetting.status.wind = this.$dataManager.getters.mahjong.usualUpdate.dealer
        var data = {
          isLiveShowEnd: false
        }
        this.$store.commit('UPDATE_STATUS', data)
        this.$bus.$emit('saveStore')
      })
    },
    getData: function() {
      this.$dataManager.getSeasonData(this.id).then((res) => {
        this.season = res.data
      })
    },
    cleanGame: function() {
      this.$dataManager.cleanLiveShowData()
      this.$bus.$emit('saveStore')
      this.$router.push({ name: 'Season.List' })
    },
    endGame: function() {
      this.$store.commit('OPEN_LOADING')
      this.$store.dispatch('liveEnd', { credentials: this.$dataManager.getters.mahjong.liveUpdate.liveShow.id }).then((res) => {
        if (!res.errorCode) {
          this.$store.commit('CLOSE_LOADING')
          this.$dataManager.cleanLiveShowData()
          this.$bus.$emit('saveStore')
          this.$router.push({ name: 'Season.List' })
        }
      })
    }
  }
}
</script>

