<template>
  <div v-if="isLoading" class="dailyDetail">

    <div v-show="leaderBoardChange" class="leaderboard">
      <div class="content">
        <div v-for="(item,i) in allPlayers" :key="i" class="user">
          <div class="rank" v-text="item.rank" />
          <div class="_card">
            <div class="name" :class="($dataManager.getters.mahjong.liveUpdate.lastChampion === item.teamId) ? 'king': 'normal'" v-html="item.player.displayName" />
            <div class="point" v-html="item.team.point" />
          </div>
          <div class="icon" :class="item.status" />
        </div>
      </div>
    </div>
    <div v-if="detailChange" class="detailboard">
      <div class="content">
        <div v-for="(item,i) in players" :key="i" class="user">
          <div class="i">
            <div class="_card">
              <div class="name" :class="($dataManager.getters.mahjong.liveUpdate.lastChampion === item.teamId) ? 'king': 'normal'" v-html="item.player.displayName" />
              <div class="point" v-html="item.totalPoint" />
            </div>
            <div class="todaypoint" :class="item.team.point===0 ?'_neutral':item.team.point>0?'_positive':'_negative'" v-html="item.team.point" />
          </div>
          <div class="i detail">
            <div class="data" v-text="'胡牌 : '+item.player.winCount" />
            <div class="line" />
            <div class="data" v-text="'放槍 : '+item.player.loseCount" />
            <div class="line" />
            <div class="data" v-text="'自摸 : '+item.player.tsumoCount" />
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'DailyDetail',
  components: { },
  data() {
    return {
      livePlayers: {},
      detail: {},
      lastDetail: {},
      pointChange: false,
      leaderBoardChange: true,
      detailChange: true,
      teams: {},
      isLoading: false,
      liveSetting: {}
    }
  },
  computed: {
    allPlayers: function() {
      var data = []
      if (this.isLoading) {
        data = this.$dataManager.formatDataShow(this.teams, this.liveSetting, this.pointChange, this.detail, this.lastDetail, true)
      }
      data.sort((a, b) => {
        return b.team.point - a.team.point
      })
      var rank = 0

      data.forEach((i) => {
        rank++
        i.rank = rank
        i.status = ''
        this.tempPlayers.forEach((j) => {
          if (i.player.playerId === j.player.playerId) {
            i.status = (j.oldRank === i.rank) ? '' : (j.oldRank - i.rank > 0) ? 'up' : 'low'
          }
        })
      })
      return data
    },
    players: function() {
      var data = []
      if (this.isLoading) {
        data = this.$dataManager.formatDataShow(this.teams, this.liveSetting, this.pointChange, this.livePlayers, this.lastHand, false)
      }
      this.detail.forEach((de) => {
        data.forEach((da) => {
          de.players.forEach((i) => {
            if (i.playerId === da.player.playerId) {
              da.totalPoint = i.detail.points
            }
          })
        })
      })
      data.sort((a, b) => {
        return b.team.point - a.team.point
      })
      return data
    },
    tempPlayers: function() {
      var data = []
      if (this.lastDetail.length > 0) {
        data = this.$dataManager.formatDataShow(this.teams, this.liveSetting, this.pointChange, this.lastDetail, this.lastDetail, true)
      }
      data.sort((a, b) => {
        return b.team.point - a.team.point
      })
      var rank = 0
      data.forEach((i) => {
        rank++
        i.oldRank = rank
      })
      return data
    }

  },
  watch: {
    liveSetting(val) {
      if (val) {
        this.$dataManager.getLastSeasonChampion(this.liveSetting.seasonId)
      }
    }
  },
  mounted() {
    this.$bus.$on('liveSetting', content => {
      this.liveSetting = content
      var $this = this
      var ms = this.getRandom(1500, 5000)
      setTimeout(function() {
        $this.getLeaderBoardData()
      }, ms)
    })
  },
  methods: {
    seatPosition(data) {
      return {
        left: data.x + 'px',
        top: data.y + 'px'
      }
    },
    windToCount: function(wind) {
      var data = ''
      switch (wind) {
        case 'e':
          data = '1'
          break
        case 's':
          data = '2'
          break
        case 'w':
          data = '3'
          break
        case 'n':
          data = '4'
          break
      }
      return data
    },
    makeIcon: function(team) {
      return team.icon.replace('fill="none"', 'fill="' + team.color + '"')
    },
    getLeaderBoardData: function() {
      this.$store.commit('OPEN_LOADING')
      this.lastDetail = this.detail
      this.lastHand = this.livePlayers
      this.$store.dispatch('getData', { type: 'Season', credentials: this.liveSetting.seasonId }).then((res) => {
        if (!res.errorCode) {
          this.teams = res.data.teams
          this.$dataManager.getTeamBySeason(this.liveSetting.seasonId).then((res) => {
            this.detail = res.data
            if (this.lastHand.length > 0) this.pointChange = true
            this.$dataManager.getTeamByLiveShow(this.liveSetting.liveShowId).then((res) => {
              this.livePlayers = res.data
              this.isLoading = true
            })
          })
        }
      })
    },

    getRandom: function(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min
    }
  }
}
</script>

<style lang="scss">
@import '../../style/show/dailyDetail';
</style>

