<template>
  <div v-if="isLoading" class="dailyShow">
    <div
      v-for="(item, i) in players"
      :key="i"
      class="item"
      :style="seatPosition(item)"
    >
      <div
        v-if="item.host && liveSetting.status.combo > 0"
        class="host-count"
        v-html="liveSetting.status.combo"
      />
      <div
        v-if="liveSetting.status.riichis && item.riichi"
        class="eyeicon"
      />
      <div
        v-if="liveSetting.status.eyes && item.eye"
        class="earicon"
      />
      <div
        class="main"
        :class="item.host ? 'host' : 'player'"
        style="background-color: transparent"
      >
        <div class="user">
          <div
            class="name"
            :class="
              $dataManager.getters.mahjong.liveUpdate.lastChampion ===
                item.teamId
                ? 'king'
                : 'normal'
            "
            v-html="item.player.displayName"
          />
          <div class="point" v-html="item.totalPoint" />
        </div>
        <transition name="fade-up">
          <div
            v-if="pointChange"
            class="pointAni up"
            :class="{
              big: item.changePoint > 8 && item.changePoint < 25,
              large: item.changePoint >= 25,
              hidden: item.changePoint <= 0,
            }"
          >
            <span v-if="item.changePoint !== ''">+</span>{{ item.changePoint }}
          </div>
        </transition>
        <transition name="fade-down">
          <div
            v-if="pointChange"
            class="pointAni down"
            :class="{
              big: item.changePoint < -8 && item.changePoint > -25,
              large: item.changePoint <= -25,
              hidden: item.changePoint >= 0,
            }"
          >
            {{ item.changePoint }}
          </div>
        </transition>
      </div>
    </div>
    <transition name="slide-fade">
      <div v-show="leaderBoardChange" class="leaderboard">
        <div class="content">
          <div v-for="(item, i) in allPlayers" :key="i" class="user">
            <div class="rank" v-text="item.rank" />
            <div class="_card">
              <div
                class="name"
                :class="
                  $dataManager.getters.mahjong.liveUpdate.lastChampion ===
                    item.teamId
                    ? 'king'
                    : 'normal'
                "
                v-html="item.player.displayName"
              />
              <div class="point" v-html="item.team.point" />
            </div>
            <div class="icon" :class="item.status" />
          </div>
        </div>
      </div>
    </transition>
    <transition name="slide-fade">
      <div v-if="detailChange" class="detailboard">
        <div class="content">
          <div v-for="(item, i) in players" :key="i" class="user">
            <div class="i">
              <div class="_card">
                <div
                  class="name"
                  :class="
                    $dataManager.getters.mahjong.liveUpdate.lastChampion ===
                      item.teamId
                      ? 'king'
                      : 'normal'
                  "
                  v-html="item.player.displayName"
                />
                <div class="point" v-html="item.totalPoint" />
              </div>
              <div
                class="todaypoint"
                :class="
                  item.team.point === 0
                    ? '_neutral'
                    : item.team.point > 0
                      ? '_positive'
                      : '_negative'
                "
                v-html="item.team.point"
              />
            </div>
            <div class="i detail">
              <div class="data" v-text="'胡牌: ' + item.player.winCount" />
              <div class="line" />
              <div class="data" v-text="'放槍: ' + item.player.loseCount" />
              <div class="line" />
              <div
                class="data"
                v-text="'自摸: ' + item.player.tsumoCount"
              />
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div class="windshow">
      <div class="round" v-text="liveSetting.status.game" />
      <div
        class="wind"
        v-text="$dataManager.positionName(liveSetting.status.round)"
      />
      <div v-if="liveSetting.status.riichied !== 0" class="eye">
        <i class="icon" />
        <span v-text="liveSetting.status.riichied" />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DailyShow',
  components: { },
  data() {
    return {
      livePlayers: {},
      detail: {},
      lastDetail: {},
      lastHand: {},
      pointChange: false,
      leaderBoardChange: false,
      detailChange: false,
      pointChangeTimeOut: {},
      leaderBoardChangeTrueTimeOut: {},
      leaderBoardChangeFalseTimeOut: {},
      detailChangeTrueTimeOut: {},
      detailChangeFalseTimeOut: {},
      teams: {},
      isLoading: false,
      liveSetting: {}
    }
  },
  computed: {
    allPlayers: function() {
      var data = []
      if (this.isLoading) {
        data = this.$dataManager.formatDataShow(
          this.teams,
          this.liveSetting,
          this.pointChange,
          this.detail,
          this.lastDetail,
          true
        )
      }
      data.sort((a, b) => {
        return b.team.point - a.team.point
      })
      var rank = 0

      data.forEach((i) => {
        rank++
        i.rank = rank
        i.status = ''
        this.tempPlayers.forEach((j) => {
          if (i.player.playerId === j.player.playerId) {
            i.status =
              j.oldRank === i.rank ? '' : j.oldRank - i.rank > 0 ? 'up' : 'low'
          }
        })
      })
      return data
    },
    players: function() {
      var data = []
      if (this.isLoading) {
        data = this.$dataManager.formatDataShow(
          this.teams,
          this.liveSetting,
          this.pointChange,
          this.livePlayers,
          this.lastHand,
          false
        )
      }
      this.detail.forEach((de) => {
        data.forEach((da) => {
          de.players.forEach((i) => {
            if (i.playerId === da.player.playerId) {
              da.totalPoint = i.detail.points
            }
          })
        })
      })
      data.sort((a, b) => {
        return b.team.point - a.team.point
      })
      return data
    },
    tempPlayers: function() {
      var data = []
      if (this.lastDetail.length > 0) {
        data = this.$dataManager.formatDataShow(
          this.teams,
          this.liveSetting,
          this.pointChange,
          this.lastDetail,
          this.lastDetail,
          true
        )
      }
      data.sort((a, b) => {
        return b.team.point - a.team.point
      })
      var rank = 0
      data.forEach((i) => {
        rank++
        i.oldRank = rank
      })
      return data
    }
  },
  watch: {
    liveSetting(val) {
      if (val) {
        this.$dataManager.getLastSeasonChampion(this.liveSetting.seasonId)
      }
    },
    pointChange(val) {
      if (val) {
        clearTimeout(this.pointChangeTimeOut)
        clearTimeout(this.leaderBoardChangeTrueTimeOut)
        clearTimeout(this.leaderBoardChangeFalseTimeOut)
        clearTimeout(this.detailChangeTrueTimeOut)
        clearTimeout(this.detailChangeFalseTimeOut)
        this.setAni()
      }
    }
  },
  mounted() {
    this.$bus.$on('liveSetting', (content) => {
      this.liveSetting = content
      this.leaderBoardChange = false
      this.detailChange = false
      var $this = this
      var ms = this.getRandom(1500, 2500)
      setTimeout(function() {
        $this.getLeaderBoardData()
      }, ms)
    })
  },
  methods: {
    seatPosition(data) {
      return {
        left: data.x + 'px',
        top: data.y + 'px'
      }
    },
    windToCount: function(wind) {
      var data = ''
      switch (wind) {
        case 'e':
          data = '1'
          break
        case 's':
          data = '2'
          break
        case 'w':
          data = '3'
          break
        case 'n':
          data = '4'
          break
      }
      return data
    },
    makeIcon: function(team) {
      return team.icon.replace('fill="none"', 'fill="' + team.color + '"')
    },
    getLeaderBoardData: function() {
      this.$store.commit('OPEN_LOADING')
      this.lastDetail = this.detail
      this.lastHand = this.livePlayers
      this.$store
        .dispatch('getData', {
          type: 'Season',
          credentials: this.liveSetting.seasonId
        })
        .then((res) => {
          if (!res.errorCode) {
            this.teams = res.data.teams
            this.$dataManager
              .getTeamBySeason(this.liveSetting.seasonId)
              .then((res) => {
                this.detail = res.data
                if (this.lastHand.length > 0) this.pointChange = true
                this.$dataManager
                  .getTeamByLiveShow(this.liveSetting.liveShowId)
                  .then((res) => {
                    this.livePlayers = res.data
                    this.isLoading = true
                  })
              })
          }
        })
    },
    setAni: function() {
      var $this = this

      $this.pointChangeTimeOut = setTimeout(function() {
        $this.pointChange = false
        $this.leaderBoardChangeTrueTimeOut = setTimeout(function() {
          $this.leaderBoardChange = true
          $this.leaderBoardChangeFalseTimeOut = setTimeout(function() {
            $this.leaderBoardChange = false
            $this.detailChangeTrueTimeOut = setTimeout(function() {
              $this.detailChange = true
              $this.detailChangeFalseTimeOut = setTimeout(function() {
                $this.detailChange = false
              }, 30 * 1000)
            }, 5000)
          }, 30 * 1000)
        }, 2000)
      }, 3000)
    },
    getRandom: function(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min
    }
  }
}
</script>

<style lang="scss">
@import "../../style/show/dailyShow";
</style>
