<template>
  <div class="liveDetail content">
    <div v-for="(game,i) in formatData" :key="i" class="panel">
      <div class="header">
        <span class="panel-title upper" v-text="'第'+parseInt(formatData.length-i)+'雀'" />
      </div>
      <div class="content text-block">
        <div v-for="(round,j) in game.rounds" :key="j" class="list">
          <h5 v-text="round.dealerWind" />
          <table class="table table-hover table-striped">
            <tr>
              <th>#</th>
              <th>莊</th>
              <th>Win</th>
              <th>Lose</th>
              <th>Action</th>
            </tr>
            <tbody>
              <tr v-for="(hand,idx) in round.hands" :key="idx">
                <td v-text="round.hands.length-idx" />
                <td v-text=" (hand.isDouble)?hand.title +'(豹子)':hand.title" />
                <td class="data">
                  <div v-for="(player,p) in hand.winner" :key="p">
                    <span class="bolder" v-text="player.player" />
                    <span v-text="' ('+ player.type+') '" />
                    <span v-if="player.riichi" v-text="' (眼牌) '" />
                    <span
                      :class="$dataManager.pointColor(player.point)"
                      v-text="(player.point >0)?'+'+player.point: ''"
                    />
                  </div>
                </td>
                <td class="data">
                  <div v-for="(player,p) in hand.loser" :key="p">
                    <span v-text="player.player+' '" />
                    <span v-if="player.riichi" v-text="' (眼牌) '" />
                    <span :class="$dataManager.pointColor(player.point)" v-text="player.point" />
                  </div>
                </td>
                <td>
                  <div class="btn" @click="edit(hand,round,game)"><i class="ti-pencil-alt" /></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LiveDetail',
  props: ['data'],
  computed: {
    formatData() {
      var data = this.$dataManager.getters.mahjong.liveDetail
      data.games.forEach((game) => {
        game.rounds.forEach((round) => {
          round.dealerWind = this.$dataManager.positionName(round.dealerWind)
          round.hands.forEach((hand) => {
            hand.winner = []
            hand.loser = []
            // hand.needReset = false
            hand.title = this.$dataManager.findPlayer(hand.dealerId).player.displayName || this.$dataManager.findPlayer(hand.dealerId).player.name
            hand.title += (hand.combo > 0) ? '連' + hand.combo : ''
            if (hand.handPoints.length > 0) {
              var dealer = false
              hand.handPoints.forEach((item) => {
                dealer = (hand.dealerId === item.playerId) ? true : dealer
              })
              // hand.needReset = dealer
              hand.handPoints.forEach((item) => {
                var i = {}
                i.player = this.$dataManager.findPlayer(item.playerId).player.displayName || this.$dataManager.findPlayer(item.playerId).player.name
                i.team = this.$dataManager.findPlayer(hand.dealerId).team.displayName || this.$dataManager.findPlayer(hand.dealerId).team.name
                i.type = this.findWinType(item.winTypes)
                i.riichi = false
                i.dealer = (hand.dealerId === item.playerId)
                item.dealer = (hand.dealerId === item.playerId)
                i.result = item.result
                hand.riichis.forEach((r) => {
                  if (item.playerId === r.playerId) {
                    i.riichi = true
                  }
                })
                if (hand.dealerId === item.playerId) {
                  i.type += ' 莊家'
                }
                if (dealer && hand.combo > 0) {
                  i.type += ' 連' + hand.combo + '拉' + hand.combo
                }
                i.point = item.points
                if (item.points > 0) {
                  hand.winner.push(i)
                }
                if (item.points < 0) {
                  hand.loser.push(i)
                }
              })
            } else {
              // hand.needReset = true
              var i = {}
              i.player = this.$dataManager.findPlayer(hand.dealerId).player.name
              i.team = this.$dataManager.findPlayer(hand.dealerId).team
              if (hand.combo > 0) {
                i.type = '臭莊 連' + hand.combo
              } else {
                i.type = '臭莊'
              }
              i.point = 0
              hand.winner.push(i)
            }
          })
          round.hands.sort((a, b) => {
            return b.id - a.id
          })
        })

        game.rounds.sort((a, b) => {
          return b.id - a.id
        })
      })
      data.games.sort((a, b) => {
        return b.id - a.id
      })
      return data.games
    }
  },
  methods: {
    edit: function(hand, round, game) {
      var detail = {
        hand: hand,
        gameid: game.id,
        roundid: round.id,
        prevailing: round.prevailingWind,
        gameSeat: {}
      }
      game.positions.forEach((p) => {
        switch (p.position) {
          case 'n':
            detail.gameSeat.n = p.playerId
            break
          case 'e':
            detail.gameSeat.e = p.playerId
            break
          case 'w':
            detail.gameSeat.w = p.playerId
            break
          case 's':
            detail.gameSeat.s = p.playerId
            break
        }
      })
      this.$bus.$emit('editHand', detail)
    },
    findWinType: function(type) {
      var res = ''
      type.forEach((i) => {
        res += i.name + ' '
      })
      return (res === '') ? '屁胡' : res
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../../style/layout/liveDetail';
</style>
