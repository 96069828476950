<!-- eslint-disable vue/no-template-shadow -->
<template>

  <div class="card card-user">
    <div class="card-body">
      <form>
        <div class="form-group row">
          <label class="col-md-2 col-form-label">遊戲類型</label>
          <div class="col-md-10">
            <select v-model="season.gtId" class="custom-select">
              <option v-for="(item,i) in gameType" :key="i" :value="item.id" v-text="item.name" />
            </select>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-2 col-form-label">名稱</label>
          <div class="col-md-10">
            <input v-model="season.name" type="text" class="form-control">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-2 col-form-label">賽季類型</label>
          <div class="col-md-10">
            <select v-model="season.type" class="custom-select">
              <option v-for="(item,i) in type" :key="i" :value="item.key" v-text="item.name" />
            </select>
          </div>
        </div>
        <div v-if="season.type===3" class="form-group row">
          <label class="col-md-2 col-form-label">例行賽隊伍分數第一名</label>
          <div class="col-md-10">
            <input v-model="season.setting.rankingFirst" type="text" class="form-control">
          </div>
        </div>
        <div v-if="season.type===3" class="form-group row">
          <label class="col-md-2 col-form-label">例行賽隊伍分數第二名</label>
          <div class="col-md-10">
            <input v-model="season.setting.rankingSecond" type="text" class="form-control">
          </div>
        </div>
        <div v-if="season.type===3" class="form-group row">
          <label class="col-md-2 col-form-label">例行賽隊伍分數第三名</label>
          <div class="col-md-10">
            <input v-model="season.setting.rankingThird" type="text" class="form-control">
          </div>
        </div>
        <div v-if="season.type===3" class="form-group row">
          <label class="col-md-2 col-form-label">例行賽隊伍分數第四名</label>
          <div class="col-md-10">
            <input v-model="season.setting.rankingFourth" type="text" class="form-control">
          </div>
        </div>
        <div>
          <div class="btn btn-xs btn-primary btn-round" @click="showTeams = !showTeams">
            選擇賽季隊伍
          </div>
          <div v-if="season.type===3" class="btn btn-xs btn-primary btn-round" @click="showLives = !showLives">
            賽季排程直播
          </div>
        </div>

        <div v-if="showTeams" class="form-group row">
          <table class="table">
            <tr>
              <td>系統名稱</td>
              <td>顯示名稱</td>
              <td>隊伍顏色</td>
              <td>隊伍標誌</td>
              <td>#</td>
            </tr>
            <tr v-for="(team,i) in teams" :key="i">
              <td v-text="team.name" />
              <td v-text="team.displayName" />
              <td :style="'color :'+team.color" v-text="team.color" />
              <td class="icon" v-html="makeIcon(team)" />
              <td>
                <input v-model="team.active" type="checkbox">
              </td>
            </tr>
            <tr>
              <td><input v-model="team.name" type="text" class="form-control" placeholder="System name"></td>
              <td><input v-model="team.displayName" type="text" class="form-control" placeholder="Display name"></td>
              <td><input v-model="team.color" type="text" class="form-control" placeholder="#000000"></td>
              <td><input v-model="team.icon" type="text" class="form-control" placeholder="#000000"></td>
              <td>
                <div class="btn btn-xs btn-primary btn-round pull-right">+</div>
              </td>
            </tr>
          </table>
        </div>
        <div class="row">
          <div class="update ml-auto mr-auto">
            <button type="submit" class="btn btn-primary btn-round" @click="submit">送出</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SeasonUpdate',
  data: function() {
    return {
      id: this.$route.params.id,
      showTeams: true,
      showLives: false,
      gameType: [],
      teams: [],
      team: {
        name: '',
        displayName: '',
        color: '',
        icon: ''
      },
      season: {
        gtId: '',
        name: '',
        type: 1,
        setting: {
          rankingFirst: 0,
          rankingSecond: 0,
          rankingThird: 0,
          rankingFourth: 0
        }
      },
      type: [
        { key: 1, name: '日間直播' },
        { key: 2, name: '一般比賽' },
        { key: 3, name: '大型季賽' }
      ]
    }
  },
  computed: {
    teamsArr: function() {
      var arr = []
      this.teams.forEach((item) => {
        if (item.active) {
          arr.push(item.id)
        }
      })
      return arr
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    makeIcon: function(team) {
      var str = ''
      if (team.icon !== '') {
        if (team.icon.indexOf('fill="none"') !== -1) {
          str = team.icon.replace('fill="none"', 'fill="' + team.color + '"')
        } else {
          str = team.icon
        }
      }
      return str
    },
    getData: function() {
      this.$store.commit('CLOSE_LOADING')
      this.$store.dispatch('getList', { type: 'GameType', action: 'list' }).then((res) => {
        if (!res.errorCode) {
          this.gameType = res.data
          this.$store.dispatch('getList', { type: 'Team', action: 'list' }).then((res) => {
            if (!res.errorCode) {
              this.teams = res.data
              this.$store.dispatch('getData', { type: 'Season', credentials: this.id }).then((res) => {
                if (!res.errorCode) {
                  this.season = res.data
                  this.teams.forEach((team) => {
                    team.active = false
                    this.season.teams.forEach((item) => {
                      team.active = (team.id === item.id) ? true : team.active
                      team.icon = (team.id === item.id) ? team.icon : ''
                    })
                  })
                  this.$store.commit('CLOSE_LOADING')
                }
              })
            }
          })
        }
      })
    },
    submit: function() {
      this.season.teams = this.teamsArr
      var data = {
        id: this.id,
        season: this.season
      }
      this.$store.dispatch('seasonUpdate', { credentials: data }).then((res) => {
        if (!res.errorCode) {
          this.$router.push({ name: 'Season.List' })
        }
      })
    }
  }
}
</script>

<style lang="scss">
td.icon {
  svg {
    width: 25px;
    height: 25px;
  }
}
</style>
