<template>
  <div class="print">
    <div class="main">
      <div class="bingo">
        <div class="waterMark waterMark1" />
        <div class="waterMark waterMark2" />
        <div class="top-navigator">
          <div class="gameName"><img src="./images/logo.svg"></div>
          <div class="logo">
            <img
              src="./images/bingoLogo.svg"
            >
          </div>
        </div>
        <div class="board">
          <div v-for="(row, i) in board" :key="i" class="row">
            <div
              v-for="(card, j) in row"
              :key="j"
              class="item"
            >
              <Card :card="card" />
            </div>
          </div>
        </div>
        <div class="info">
          <span v-text="bingo.name" />
          <span v-text="bingo.identity" />
        </div>
      </div>
    </div>
    <div class="idx" v-text="'#'+bingo.id+' '+ bingo.createdTime + ' © boyplaymj'" />
  </div>
</template>
<script>
import Card from '@/components/Show/bingo/cardsComponents/getCard'

export default {
  name: 'Bingo',
  components: { Card },
  props: ['bingo'],
  data: function() {
    return {

    }
  },
  computed: {
    board: function() {
      return JSON.parse(this.bingo.board)
    }
  }
}
</script>
<style lang="scss">
@import './style/_all_variables.scss';
@import './style/bingoHeader';
@import './style/bingoBoard';
@import './style/print';

.bingo {
height: 100%;
width: 100%;
background-color: $bingo-bg-color;
display: flex;
flex-direction: column;
justify-content: center;
}

</style>
