<template>
  <div v-if="$dataManager.getters.loading" class="loading-container">
    <img class="loading" :src="loading" alt="">
  </div>
</template>

<script>
const loading = require('@/assets/loading.gif')
export default {
  name: 'Loading',
  data() {
    return {
      loading: loading
    }
  }

}
</script>

<style scoped>

</style>
