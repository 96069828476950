<template>
  <div>
    <canvas id="canvas-render" />
  </div>
</template>

<script>
export default {
  name: 'TrigonScore',
  props: ['linelength', 'dotwidth', 'borderwidth', 'bordercolor', 'areacolor', 'rates', 'delaytime', 'animationtime', 'bordercover'],
  data: function() {
    return {
      aniStartTime: -1,
      aniEndTime: -1,
      constRadical3: 1.732 // 三角形邊長公式 1:2:√3
    }
  },
  computed: {
    trigonLineLength: function() { return parseInt(this.linelength) + 1 },
    trigonBorderWidth: function() { return parseInt(this.borderwidth) },
    trigonBorderColor: function() { return this.bordercolor },
    trigonDotWidth: function() { return parseInt(this.dotwidth) },
    trigonAreaColor: function() { return this.areacolor },
    trigonSelfRate: function() { return parseFloat(this.rates[0]) },
    trigonWinnerRate: function() { return parseFloat(this.rates[1]) },
    trigonShotRate: function() { return parseFloat(this.rates[2]) },
    trigonDelayTime: function() { return parseInt(this.delaytime) },
    trigonAnimationTime: function() { return parseInt(this.animationtime) },
    flagBorderCover: function() { return !!(this.bordercover === true || this.bordercover === 'true') },
    trigonHeight: function() { return Math.round((this.trigonLineLength / 2) * this.constRadical3) }
  },
  watch: {
    $props: {
      handler: function() {
        this.enableDraw()
      },
      deep: true
    }
  },
  mounted() {
    this.enableDraw()
  },
  methods: {
    calcRealtimePosition: function(base, target, progress = 1) {
      if (progress >= 1) return target
      const x = (target[0] - base[0]) * progress + base[0]
      const y = (target[1] - base[1]) * progress + base[1]
      return [x, y]
    },
    draw: function() {
      var canvas = this.$el.querySelector('canvas')
      var bw = this.trigonBorderWidth
      var canHeight = this.trigonHeight
      var cc = canvas.getContext('2d')
      var nowTime = Date.now()
      var aniStartTime = this.aniStartTime
      var aniEndTime = this.aniEndTime
      var isOverStart = nowTime >= aniStartTime
      var isOVerEnd = nowTime >= aniEndTime

      canvas.width = this.trigonLineLength + (bw * 2)
      canvas.style.width = this.trigonLineLength + 'px'
      canvas.height = canHeight + bw * 2
      canvas.style.height = canvas.height + 'px'
      canvas.style.backgroundColor = 'transparent'

      // 計算三角極點座標
      const leftBottom = [0 + bw, canHeight + bw]
      const rightBottom = [this.trigonLineLength + bw, canHeight + bw]
      const centerTop = [Math.round(this.trigonLineLength / 2) + bw, 0 + bw]
      const centerCore = [centerTop[0], canHeight + bw - Math.round(centerTop[0] / this.constRadical3)]

      // 計算間隔寬度、高度
      const gapWidth = (this.trigonLineLength / 2) / 5
      const gapUpHeight = ((centerCore[1] - bw)) / 5
      const gapDownHeight = ((canHeight + bw) - centerCore[1]) / 5

      const percentWidth = gapWidth / 20
      const percentUpHeight = gapUpHeight / 20
      const percentDownHeight = gapDownHeight / 20

      // 計算自摸、胡牌、放槍座標
      this.trigonSelfRate
      const posSelf = [centerTop[0], centerCore[1] - Math.round(percentUpHeight * this.trigonSelfRate)]
      const posWinner = [centerCore[0] - Math.round(percentWidth * this.trigonWinnerRate), centerCore[1] + Math.round(percentDownHeight * this.trigonWinnerRate)]
      const posShot = [centerCore[0] + Math.round(percentWidth * this.trigonShotRate), centerCore[1] + Math.round(percentDownHeight * this.trigonShotRate)]

      if (isOverStart && !this.flagBorderCover) this.drawAreaAndDot(cc, centerCore, posSelf, posWinner, posShot) // 不覆蓋，先畫區塊

      // 畫最外層三角形
      this.drawTrigon(cc, this.trigonBorderWidth, this.trigonBorderColor, leftBottom, rightBottom, centerTop)
      for (var i = 1; i < 5; i++) {
        var lvLeftBottom = [Math.round(leftBottom[0] + (gapWidth * i)), Math.round(leftBottom[1] - (gapDownHeight * i))]
        var lvRightBottom = [Math.round(rightBottom[0] - (gapWidth * i)), Math.round(rightBottom[1] - (gapDownHeight * i))]
        var lvCenterTop = [centerTop[0], Math.round(centerTop[1] + (gapUpHeight * i))]
        this.drawTrigon(cc, this.trigonBorderWidth, this.trigonBorderColor, lvLeftBottom, lvRightBottom, lvCenterTop)
      }

      // 畫三角形連接線
      this.drawConnectLine(cc, leftBottom, rightBottom, centerTop, centerCore)

      if (isOverStart && this.flagBorderCover) this.drawAreaAndDot(cc, centerCore, posSelf, posWinner, posShot) // 覆蓋，後畫區塊

      if (!isOVerEnd) {
        this.requestAni()
      } else {
        this.$emit('callbackend', true)
      }
    },
    drawAreaAndDot: function(cc, centerCore, origPosSelf, origPosWinner, origPosShot) {
      const nowTime = Date.now()
      const aniStartTime = this.aniStartTime
      const aniEndTime = this.aniEndTime
      let progress = (nowTime - aniStartTime) / (aniEndTime - aniStartTime)
      if (progress > 1) progress = 1

      const posSelf = this.calcRealtimePosition(centerCore, origPosSelf, progress)
      const posWinner = this.calcRealtimePosition(centerCore, origPosWinner, progress)
      const posShot = this.calcRealtimePosition(centerCore, origPosShot, progress)
      // 畫區域範圍三角
      this.drawArea(cc, posSelf, posWinner, posShot)

      // 畫座標點
      this.drawDot(cc, this.trigonDotWidth, this.trigonBorderColor, posSelf)
      this.drawDot(cc, this.trigonDotWidth, this.trigonBorderColor, posWinner)
      this.drawDot(cc, this.trigonDotWidth, this.trigonBorderColor, posShot)
    },
    drawArea: function(cc, posSelf, posWinner, posShot) {
      cc.beginPath()
      cc.lineWidth = 1
      cc.strokeStyle = this.trigonAreaColor
      cc.moveTo(posSelf[0], posSelf[1])
      cc.lineTo(posWinner[0], posWinner[1])
      cc.lineTo(posShot[0], posShot[1])
      cc.lineTo(posSelf[0], posSelf[1])
      cc.fillStyle = this.trigonAreaColor
      cc.fill()
    },
    drawConnectLine: function(cc, leftBottom, rightBottom, centerTop, centerCore) {
      cc.beginPath()
      cc.lineWidth = this.trigonBorderWidth
      cc.strokeStyle = this.trigonBorderColor
      cc.moveTo(leftBottom[0], leftBottom[1])
      cc.lineTo(centerCore[0], centerCore[1])
      cc.moveTo(rightBottom[0], rightBottom[1])
      cc.lineTo(centerCore[0], centerCore[1])
      cc.moveTo(centerTop[0], centerTop[1])
      cc.lineTo(centerCore[0], centerCore[1])
      cc.stroke()
    },
    drawDot: function(cc, dotWidth, borderColor, position) {
      cc.moveTo(position[0], position[1])
      cc.beginPath()
      cc.arc(position[0], position[1], dotWidth, 0, 2 * Math.PI, true)
      cc.fillStyle = borderColor
      cc.fill()
      cc.stroke()
    },
    drawTrigon: function(cc, borderWidth, borderColor, leftBottom, rightBottom, centerTop) {
      cc.beginPath()
      cc.moveTo(leftBottom[0], leftBottom[1])
      cc.lineWidth = borderWidth
      cc.strokeStyle = borderColor
      cc.lineTo(rightBottom[0], rightBottom[1])
      cc.lineTo(centerTop[0], centerTop[1])
      cc.lineTo(leftBottom[0], leftBottom[1])
      cc.stroke()
    },
    enableDraw: function() {
      const nowTime = Date.now()
      const delay = this.trigonDelayTime || 0
      const aniStart = delay + nowTime
      const aniEnd = delay + this.trigonAnimationTime + nowTime

      this.aniStartTime = aniStart
      this.aniEndTime = aniEnd
      this.requestAni()
    },
    requestAni: function() {
      requestAnimationFrame(this.draw)
    }
  }
}
</script>

<style scoped>

</style>
