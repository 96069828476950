<template>
  <div class="sidebar" data-color="white" data-active-color="danger" :class="openSideBar?'active':''">
    <div class="toggle" @click="openSideBar = !openSideBar">
      <SvgIcon :type="'close'" />
    </div>
    <div class="logo">
      <router-link :class="'simple-text logo-normal'" :to="{ name: 'Dashboard'}">
        Live Manager
      </router-link>
    </div>
    <div class="sidebar-wrapper">
      <ul class="nav">
        <li v-if="checkPermission('Role')" :class="{'active': route =='Role'}">
          <router-link :to="{ name: 'Role.List'}">
            <i class="el-icon-warning-outline" />
            <p>角色權限</p>
          </router-link>
        </li>
        <li v-if="checkPermission('User')" :class="{'active': route =='User'}">
          <router-link :to="{ name: 'User.List'}">
            <i class="el-icon-user" />
            <p>使用者</p>
          </router-link>
        </li>
        <li v-if="checkPermission('GameType')" :class="{'active': route =='GameType'}">
          <router-link :to="{ name: 'GameType.List'}">
            <i class="el-icon-first-aid-kit" />
            <p>遊戲類型</p>
          </router-link>
        </li>
        <li v-if="checkPermission('WinningType')" :class="{'active': route =='WinningType'}">
          <router-link :to="{ name: 'WinningType.List'}">
            <i class="el-icon-first-aid-kit" />
            <p>牌型</p>
          </router-link>
        </li>
        <li v-if="checkPermission('Season')" :class="{'active': route =='Season'}">
          <router-link :to="{ name: 'Season.List'}">
            <i class="el-icon-first-aid-kit" />
            <p>賽季</p>
          </router-link>
        </li>
        <li :class="{'active': route =='Show'}">
          <router-link :to="{ name: 'Show.List'}" target="_blank">
            <i class="el-icon-warning-outline" />
            <p>顯示連結</p>
          </router-link>
        </li>
        <!--        <li v-if="checkPermission('Player')" v-bind:class="{'active': this.route =='Player'}">-->
        <!--          <router-link :to="{ name: 'Player.List'}">-->
        <!--            <i class="el-icon-first-aid-kit"></i>-->
        <!--            <p>選手</p>-->
        <!--          </router-link>-->
        <!--        </li>-->

        <li v-if="checkPermission('Vote')" :class="{'active': route =='Vote'}">
          <router-link :to="{ name: 'Vote.List'}">
            <i class="el-icon-first-aid-kit" />
            <p>投票</p>
          </router-link>
        </li>
        <li :class="{'active': route =='Viewer'}">
          <router-link :to="{ name: 'Viewer.List'}">
            <i class="el-icon-first-aid-kit" />
            <p>觀眾</p>
          </router-link>
        </li>
        <li :class="{'active': route =='Registration'}">
          <router-link :to="{ name: 'Registration.List'}">
            <i class="el-icon-avatar" />
            <p>報名表</p>
          </router-link>
        </li>
        <li :class="{'active': route =='UserPost'}">
          <router-link :to="{ name: 'UserPost.List'}">
            <i class="el-icon-present" />
            <p>投稿尬廣</p>
          </router-link>
        </li>
        <li :class="{'active': route =='BingoLiveEvent'}">
          <router-link :to="{ name: 'BingoLiveEvent.List'}">
            <i class="el-icon-guide" />
            <p>現場賓果</p>
          </router-link>
        </li>
        <li :class="{'active': route =='LiveStr'}">
          <router-link :to="{ name: 'LiveStr.Create'}">
            <i class="el-icon-brush" />
            <p>顯示文字</p>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SvgIcon from '@/components/Layout/SvgIcon'

export default {
  name: 'Sidebar',
  components: { SvgIcon },
  data: function() {
    return {
      openSideBar: false
    }
  },
  computed: {
    ...mapGetters([
      'username',
      'permissions'
    ]),
    route() {
      return this.$route.name.split('.')[0]
    }
  },
  mounted() {
    if (!this.$bus._events['toggleSidebar']) {
      this.$bus.$on('toggleSidebar', () => {
        this.openSideBar = !this.openSideBar
      })
    }
  },
  methods: {

    isLogin: function() {
      this.$store.dispatch('isLogin').then((res) => {
        if (!res) {
          this.$router.push('/')
        }
      })
    },
    checkPermission: function(param) {
      if (this.permissions[param].includes('list')) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss">
  .sidebar {
    transition: all .1s ease;
    .toggle {
      display: none;
    }
  }

  @media screen and (max-width: 991px) {
    .sidebar {
      &.active {
        transform: translate3d(0, 0, 0);
      }
      .toggle {
        display: block;
        position: absolute;
        right: 5px;
        top: 5px;
        z-index: 9;

        svg {
          fill: #a3a1a1;
          width: 25px;
          height: 25px;
        }
      }
    }
  }

  .sidebar-wrapper {
    overflow-x: hidden !important;
  }
</style>
