<template>
  <div class="card card-user">
    <div class="card-body">
      <div class="liveDetail content">
        <h4>
          起始:<span
            v-for="(item, i) in basePoint.data"
            :key="i"
            class="badge rounded-pill bg-light text-dark"
            v-text="
              $dataManager.findPlayer(item.playerId).player.name +
                item.historyPoint"
          />
        </h4>
        <div v-for="(game, i) in formatData.games" :key="i" class="panel">
          <div class="header">
            <h3
              class="panel-title upper"
              v-text="'第' + parseInt(i + 1) + '雀'"
            />QQㄆ
          </div>
          <div class="content text-block">
            <div
              v-for="(round, r) in game.rounds"
              :key="r"
              class="list"
              style="margin-top: 24px;"
            >
              <h5 v-text="round.dealerWind" />
              <table class="table table-hover table-striped">
                <tr>
                  <th>#</th>
                  <th class="flex1">莊</th>
                  <th class="flex2">當前</th>
                  <th class="flex3">Win</th>
                  <th class="flex3">Lose</th>
                  <th class="flex2">結果</th>
                  <th class="flex1">ID</th>
                  <th class="flex1">精華數</th>
                </tr>
                <tbody>
                  <tr v-for="(hand, idx) in round.hands" :key="idx">
                    <td v-text="idx + 1" />
                    <td
                      class="flex1"
                      v-text="
                        hand.isDouble ? hand.title + '(豹子)' : hand.title
                      "
                    />
                    <td class="flex2 table-secondary">
                      <div v-for="(player, p) in hand.loser" :key="p">
                        <span
                          v-text="
                            $dataManager.findPlayer(player.playerId).player.name
                          "
                        />
                        <span
                          :class="$dataManager.pointColor(player.prePoint)"
                          v-text="player.prePoint"
                        />
                      </div>
                      <div v-for="(player, p) in hand.winner" :key="p">
                        <span
                          v-text="
                            $dataManager.findPlayer(player.playerId).player.name
                          "
                        />
                        <span
                          :class="$dataManager.pointColor(player.prePoint)"
                          v-text="player.prePoint"
                        />
                      </div>
                    </td>
                    <td class="flex3">
                      <div v-for="(player, p) in hand.winner" :key="p">
                        <span class="bolder" v-text="player.player" />
                        <span v-text="' (' + player.type + ') '" />
                        <span
                          v-if="
                            player.riichi &&
                              (player.point === -1 || player.point === 1)
                          "
                          v-text="' (眼牌) '"
                        />
                        <span
                          :class="$dataManager.pointColor(player.point)"
                          v-text="player.point > 0 ? '+' + player.point : ''"
                        />
                      </div>
                    </td>
                    <td class="flex3">
                      <div v-for="(player, p) in hand.loser" :key="p">
                        <span v-text="player.player + ' '" />
                        <span
                          v-if="
                            player.riichi &&
                              (player.point === -1 || player.point === 1)
                          "
                          v-text="' (眼牌) '"
                        />
                        <span
                          :class="$dataManager.pointColor(player.point)"
                          v-text="player.point"
                        />
                      </div>
                    </td>
                    <td class="flex2 table-secondary">
                      <div v-for="(player, p) in hand.loser" :key="p">
                        <span
                          v-text="
                            $dataManager.findPlayer(player.playerId).player.name
                          "
                        />
                        <span
                          :class="$dataManager.pointColor(player.afterPoint)"
                          v-text="player.afterPoint"
                        />
                      </div>
                      <div v-for="(player, p) in hand.winner" :key="p">
                        <span
                          v-text="
                            $dataManager.findPlayer(player.playerId).player.name
                          "
                        />
                        <span
                          :class="$dataManager.pointColor(player.afterPoint)"
                          v-text="player.afterPoint"
                        />
                      </div>
                    </td>
                    <td class="flex1" v-text="hand.id" />
                    <td class="flex1" v-text="hand.essenceCount" />
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Detail',
  data() {
    return {
      sid: this.$route.params.sid,
      id: this.$route.params.id,
      livePlayers: {},
      detail: {},
      lastDetail: {},
      pointChange: false,
      leaderBoardChange: false,
      detailChange: false,
      teams: {},
      isLoading: false,
      liveSetting: {}
    }
  },
  computed: {
    formatData() {
      var data = this.detail
      var basePoint = {
        key: [],
        data: []
      }
      if (data.history && data.games) {
        data.history.forEach((player) => {
          data.games.forEach((game) => {
            game.positions.forEach((position) => {
              if (player.playerId === position.playerId) {
                var item = {
                  playerId: position.playerId,
                  historyPoint: player.points
                }
                if (basePoint.key.indexOf(position.playerId) === -1) {
                  basePoint.key.push(position.playerId)
                  basePoint.data.push(item)
                }
              }
            })
          })
        })
      }
      if (data.games) {
        data.games.forEach((game) => {
          game.basePoint = basePoint.data
          game.rounds.forEach((round) => {
            round.dealerWind = this.$dataManager.positionName(round.dealerWind)
            round.hands.forEach((hand) => {
              hand.winner = []
              hand.loser = []
              hand.needReset = false
              hand.title =
                this.$dataManager.findPlayer(hand.dealerId).player
                  .displayName ||
                this.$dataManager.findPlayer(hand.dealerId).player.name
              hand.title += hand.combo > 0 ? '連' + hand.combo : ''
              if (hand.handPoints.length > 0) {
                var dealer = false
                hand.handPoints.forEach((item) => {
                  dealer = hand.dealerId === item.playerId ? true : dealer
                })
                hand.needReset = dealer
                hand.handPoints.forEach((item) => {
                  var i = {}
                  i.playerId = item.playerId
                  i.player =
                    this.$dataManager.findPlayer(item.playerId).player
                      .displayName ||
                    this.$dataManager.findPlayer(item.playerId).player.name
                  i.prePoint = findPoint(basePoint.data, item.playerId)
                  i.afterPoint =
                    findPoint(basePoint.data, item.playerId) + item.points
                  setPoint(basePoint.data, item.playerId, item.points)
                  i.team =
                    this.$dataManager.findPlayer(item.playerId).team
                      .displayName ||
                    this.$dataManager.findPlayer(item.playerId).team.name
                  i.type = this.findWinType(item.winTypes)
                  i.riichi = false
                  hand.riichis.forEach((r) => {
                    if (item.playerId === r.playerId) {
                      i.riichi = true
                    }
                  })
                  if (hand.dealerId === item.playerId) {
                    i.type += ' 莊家'
                  }
                  if (
                    hand.dealerId === item.playerId &&
                    dealer &&
                    hand.combo > 0
                  ) {
                    i.type += ' 連' + hand.combo + '拉' + hand.combo
                  }
                  if (
                    hand.dealerId !== item.playerId &&
                    item.result === 1 &&
                    dealer &&
                    hand.combo > 0
                  ) {
                    i.type += ' 莊連' + hand.combo + '拉' + hand.combo
                  }
                  i.point = item.points
                  i.type =
                    item.result === 4
                      ? '眼牌'
                      : i.type.length === 0
                        ? '屁胡'
                        : i.type
                  if (item.points > 0) {
                    hand.winner.push(i)
                  }
                  if (item.points < 0) {
                    hand.loser.push(i)
                  }
                })
              } else {
                // hand.needReset = true
                var i = {}
                i.player = this.$dataManager.findPlayer(
                  hand.dealerId
                ).player.name
                i.team = this.$dataManager.findPlayer(hand.dealerId).team
                if (hand.combo > 0) {
                  i.type = '臭莊 連' + hand.combo
                } else {
                  i.type = '臭莊'
                }
                i.point = 0
                hand.winner.push(i)
              }
            })
          })
        })
      }
      return data

      function findPoint(baseData, playerId) {
        var res
        baseData.forEach((row) => {
          if (row.playerId === playerId) {
            res = row.historyPoint
          }
        })
        return res
      }

      function setPoint(baseData, playerId, point) {
        baseData.forEach((row) => {
          if (row.playerId === playerId) {
            row.historyPoint += +point
          }
        })
      }
    },
    basePoint() {
      var data = this.detail
      var basePoint = {
        key: [],
        data: []
      }
      if (data.history && data.games) {
        data.history.forEach((player) => {
          data.games.forEach((game) => {
            game.positions.forEach((position) => {
              if (player.playerId === position.playerId) {
                var item = {
                  playerId: position.playerId,
                  historyPoint: player.points
                }
                if (basePoint.key.indexOf(position.playerId) === -1) {
                  basePoint.key.push(position.playerId)
                  basePoint.data.push(item)
                }
              }
            })
          })
        })
      }
      return basePoint
    }
  },
  mounted() {
    this.liveDetail()
  },
  methods: {
    liveDetail: function() {
      this.getData()
      this.$dataManager.liveShowDetail(this.id).then((res) => {
        this.detail = res.data
      })
    },
    getData: function() {
      this.$dataManager.getSeasonData(this.sid).then((res) => {
        this.season = res.data
      })
    },
    edit: function(hand, round, game) {
      var detail = {
        hand: hand,
        gameid: game.id,
        roundid: round.id,
        prevailing: round.prevailingWind,
        gameSeat: {}
      }
      game.positions.forEach((p) => {
        switch (p.position) {
          case 'n':
            detail.gameSeat.n = p.playerId
            break
          case 'e':
            detail.gameSeat.e = p.playerId
            break
          case 'w':
            detail.gameSeat.w = p.playerId
            break
          case 's':
            detail.gameSeat.s = p.playerId
            break
        }
      })
      this.$bus.$emit('editHand', detail)
    },

    findWinType: function(type) {
      var res = ''
      type.forEach((i) => {
        res += i.name + ' '
      })
      return res
    }
  }
}
</script>

<style lang="scss" scoped>
table tr {
  display: flex;
  width: 100%;

  * {
    flex: 0 24px;
  }

  .flex1 {
    flex: 1;
    text-align: center;
  }

  .flex2 {
    flex: 2;
  }

  .flex3 {
    flex: 3;
  }
}
</style>
