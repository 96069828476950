<template>
  <div class="floatData">
    <div class="item">
      <div class="title">
        今日得分
      </div>
      <div class="content" v-text="team.data.points||0" />
    </div>
    <div class="item">
      <div class="title">
        單把最高
      </div>
      <div class="content" v-text="team.data.maxPoint||0" />
    </div>
    <div class="item">
      <div class="title">
        自摸次數
      </div>
      <div class="content" v-text="team.data.tsumoCount" />
    </div>
    <div class="item">
      <div class="title">
        胡牌次數
      </div>
      <div class="content" v-text="team.data.winCount" />
    </div>
    <div class="item">
      <div class="title">
        放槍次數
      </div>
      <div class="content" v-text="team.data.loseCount" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FloatData',
  data() {
    return {
      season: {},
      teams: [],
      isLoading: false,
      transparent: 'rgba(0,0,0,0)',
      liveSetting: {},
      thisTeamId: 39
    }
  },
  computed: {
    team: function() {
      var data = {
        data: {
          points: 0,
          maxPoint: 0,
          tsumoCount: 0,
          winCount: 0,
          loseCount: 0,
          allHands: 0
        }
      }
      this.teams.forEach((p) => {
        if (p.teamId === this.thisTeamId) {
          data = p
        }
      })
      return data
    }
  },
  mounted() {
    if (!this.$bus._events['liveSetting']) {
      this.$bus.$on('liveSetting', content => {
        this.liveSetting = content
        this.getData()
      })
    }
    if (!this.$bus._events['playerSetting']) {
      this.$bus.$on('playerSetting', content => {
        this.thisTeamId = content.floatData
      })
    }
    window.addEventListener('keydown', (e) => {
      this.key = e.key
    })
  },
  methods: {
    roundDecimalPercentDraw: function(val, precision) {
      var data = Math.round(Math.round(val * Math.pow(10, (precision || 0) + 1)) / 10) / Math.pow(10, (precision || 0)) || 0
      return data
    },
    roundDecimal: function(val, precision) {
      var data = Math.round(Math.round(val * Math.pow(10, (precision || 0) + 1)) / 10) / Math.pow(10, (precision || 0))
      return data || '-'
    },
    roundDecimalPercent: function(val, precision) {
      var data = Math.round(Math.round(val * Math.pow(10, (precision || 0) + 1)) / 10) / Math.pow(10, (precision || 0)) || '-'
      return (data !== '-') ? data + '%' : data
    },
    getData: function() {
      this.$store.commit('CLOSE_LOADING')
      this.$store.dispatch('getData', { type: 'Season', credentials: this.liveSetting.seasonId }).then((res) => {
        if (!res.errorCode) {
          this.season = res.data
          this.$store.commit('UPDATE_TEAM', res.data.teams)
          this.$store.dispatch('teamDetailByLiveshow', { credentials: this.liveSetting.liveShowId }).then((res) => {
            if (!res.errorCode) {
              this.teams = res.data
              this.isLoading = true
            }
          })
        }
      })
    }

  }
}
</script>

<style lang="scss">
  @import '../../../style/show/floatData';
</style>
