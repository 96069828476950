<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-absolute fixed-top navbar-transparent">
      <div class="container-fluid">
        <div class="navbar-wrapper">
          <div class="navbar-toggle">
            <button type="button" class="navbar-toggler" @click="toggleSidebar">
              <span class="navbar-toggler-bar bar1" />
              <span class="navbar-toggler-bar bar2" />
              <span class="navbar-toggler-bar bar3" />
            </button>
          </div>
          <a class="navbar-brand" href="javascript:;">{{ route }}</a>
        </div>
        <div id="navigation" class="collapse navbar-collapse justify-content-end">
          <ul class="navbar-nav">
            <li v-if="!flag" class="nav-item">
              <a class="btn btn-sm btn-warning" @click="GoogleOAuth()">
                Google <span class="ti-sharethis" />
              </a>
            </li>
            <li v-if="!flag" class="nav-item">
              <a class="btn btn-sm btn-success" @click="setStatus()">
                開始 <span class="ti-comment-alt" />
              </a>
            </li>
            <li v-if="flag" class="nav-item">
              <a class="btn btn-sm btn-danger" @click="setStatus()">
                停止 <span class="ti-comment-alt  " />
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link btn-magnify" href="javascript:;">
                {{ username }}
              </a>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'Login'}" class="nav-link btn-rotate">
                <p>
                  <span class="d-md-block">Logout</span>
                </p>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Topheader',
  data() {
    return {
      flag: false,
      Interval: 30,
      msgInterval: 0
    }
  },
  computed: {
    ...mapGetters([
      'username'
    ]),
    route() {
      var RouteMain = (this.$route.name.indexOf(',') !== -1) ? this.$route.name.split('.')[1] + ' ' + this.$route.name.split('.')[1] : this.$route.name
      return RouteMain
    }
  },
  mounted() {
    this.getStatusInterval()
  },
  methods: {
    GoogleOAuth: function() {
      this.$store.dispatch('GoogleOAuth').then((res) => {
        if (!res.errorCode) {
          const url = res.data.url
          const _config = 'height=660,width=600,toolbar=no'
          window.open(url, 'Google Auth Login', _config)
        }
      })
    },
    getStatusInterval: function() {
      this.getStatus()
      this.msgInterval = setInterval(this.getStatus, this.Interval * 1000)
    },
    getStatus: function() {
      this.$store.dispatch('YoutubeInfo').then((res) => {
        if (!res.errorCode) {
          this.flag = res.data.status
        }
      })
    },
    setStatus: function() {
      this.$store.dispatch('YoutubeSet', { credentials: this.flag ? 0 : 1 }).then((res) => {
        if (!res.errorCode) {
          this.getStatus()
        }
      })
    },
    toggleSidebar() {
      this.$bus.$emit('toggleSidebar')
    }
  }
}
</script>
