import Axios from './_axios'

export default {
  bingoBind(getters, { credentials }) {
    const url = getters.apiUrl + 'Discord/setBingoShow/?id=' + credentials

    return Axios.get(
      url
    ).then(res => {
      return res
    })
  }
}
