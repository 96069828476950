<template>
  <div class="wrapper">
    <sidebar />
    <div class="main-panel">
      <topheader />
      <div class="content">
        <router-view v-if="isRouterAlive" style="width: 100%;" />
      </div>
    </div>
    <vDialog />
    <loading />
  </div>
</template>
<script>
import topheader from '@/components/Layout/Topheader'
import sidebar from '@/components/Layout/Sidebar'
import vDialog from '@/components/Layout/Dialog'
import loading from '@/components/Layout/loading'

export default {
  name: 'App',
  components: { topheader, sidebar, loading, vDialog },
  data() {
    return {
      isRouterAlive: true
    }
  },
  computed: {},
  created() {
    if (localStorage.getItem('store')) {
      this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(localStorage.getItem('store'))))
    } else {
      localStorage.setItem('store', JSON.stringify(this.$store.state))
    }
    if (!this.$bus._events['saveStore']) {
      this.$bus.$on('saveStore', () => {
        localStorage.setItem('store', JSON.stringify(this.$store.state))
      })
    }
  }
}
</script>

<style lang="scss">
@import '../style/admin';
@import '../style/_root';

.wrapper {
  height: 100vh;
}
</style>
