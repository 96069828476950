<template>

  <div class="card card-user">
    <div class="card-body">
      <form>
        <div class="form-group row">
          <label class="col-md-2 col-form-label">Account</label>
          <div class="col-md-10">
            <input v-model="user.account" type="text" class="form-control">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-2 col-form-label">Role</label>
          <div class="col-md-10">
            <select v-model="user.roleID" class="custom-select">
              <option v-for="(role,i) in roles" :key="i" :value="role.id" v-text="role.name" />
            </select>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-2 col-form-label">Name</label>
          <div class="col-md-10">
            <input v-model="user.name" type="text" class="form-control">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-2 col-form-label">Phone</label>
          <div class="col-md-10">
            <input v-model="user.phone" type="text" class="form-control">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-2 col-form-label">Email</label>
          <div class="col-md-10">
            <input v-model="user.email" type="email" class="form-control">
          </div>
        </div>

        <div class="row">
          <div class="update ml-auto mr-auto">
            <div class="btn btn-primary btn-round" @click="ChangePassword">ChangePassword</div>
            <div type="submit" class="btn btn-primary btn-round" @click="submit">Submit</div>
          </div>
        </div>
      </form>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'UserUpdate',
  data: function() {
    return {
      id: this.$route.params.id,
      user: {
        account: '',
        roleID: '',
        name: '',
        phone: '',
        email: ''
      },
      password: '',
      confirmpassword: '',
      passworddif: false,
      roles: {},
      dialogVisible: false
    }
  },
  computed: {
    ...mapGetters([
      'permissions'
    ])
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData: function() {
      var $this = this
      this.$store.commit('OPEN_LOADING')
      this.$store.dispatch('getList', { type: 'Role', action: 'list' }).then((res) => {
        if (!res.errorCode) {
          this.roles = res.data
          this.$store.dispatch('getData', { type: 'User', credentials: this.id }).then((res) => {
            if (!res.errorCode) {
              this.user = res.data
              this.roles.forEach((item) => {
                if (item.id === $this.user.roleID) {
                  $this.user.role = item
                }
              })
              this.$store.commit('CLOSE_LOADING')
            }
          })
        }
      })
    },
    submit: function() {
      this.$store.commit('OPEN_LOADING')
      var data = {
        id: this.id,
        user: this.user
      }
      this.$store.dispatch('userUpdate', { credentials: data }).then((res) => {
        if (!res.errorCode) {
          this.$store.commit('CLOSE_LOADING')
          this.$router.push({ name: 'User.List' })
        }
      })
    },
    ChangePassword: function() {
      var data = {
        visible: true,
        title: '修改密碼',
        type: 'ChangePassword'
      }
      this.$store.commit('UPDATE_DIALOG', data)
    }
  }
}
</script>

<style scoped>

</style>
