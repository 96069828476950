import Axios from './_axios'
export default {
  roleCreate(getters, { credentials }) {
    const formdata = new FormData()

    formdata.append('name', credentials.name)
    formdata.append('permission', JSON.stringify(credentials.permission))

    const url = getters.apiUrl + 'Role/create'
    return Axios.post(
      url, formdata
    ).then(res => {
      return res
    })
  },

  roleUpdate(getters, { credentials }) {
    const formdata = new FormData()

    formdata.append('name', credentials.role.name)
    formdata.append('permission', JSON.stringify(credentials.role.permission))
    const url = getters.apiUrl + 'Role/update/' + credentials.id
    return Axios.post(
      url, formdata
    ).then(res => {
      return res
    })
  }
}

