<template>
  <div v-if="isLoading" class="today-player-points">
    <div v-for="(item,i) in players" :key="i" class="players">
      <div v-if="item.position == nowDealer" class="dealer" :style="'background-color:'+item.team.color" />
      <div class="team-label">
        <div class="icon" v-html="makeIcon(item.team)" />
      </div>
      <div class="name" :style="'color:'+ item.team.color" v-text="item.player.displayName" />
      <div
        class="point"
        :style="'color:'+ item.team.color"
        v-text="(item.team.point>0)?'+'+item.team.point:item.team.point"
      />
    </div>
    <div class="item game">
      <div class="team-label">
        <svgIcon :type="'team'" :text="liveSetting.status.game" />
      </div>
      <div class="name">
        <svgIcon :type="liveSetting.status.round" />
        <svgIcon :type="'wind'" />
      </div>
      <div class="point ">
        <svgIcon :type="windToCount(liveSetting.status.wind)" />
      </div>
    </div>
  </div>
</template>

<script>
import svgIcon from '@/components/Layout/SvgIcon'

export default {
  name: 'TodayPlayerPoint',
  components: { svgIcon },
  data() {
    return {
      detail: {},
      teams: {},
      isLoading: false,
      liveSetting: {}
    }
  },
  computed: {
    players: function() {
      var data = []
      if (this.isLoading) {
        this.teams.forEach((team) => {
          this.detail.forEach((item) => {
            var i = {
              team: {},
              player: {}
            }
            if (team.id === item.teamId) {
              i.team.name = team.name
              i.team.displayName = team.displayName || team.name
              i.team.color = team.color
              i.team.icon = team.icon
              i.team.point = 0
              team.players.forEach((player) => {
                item.players.forEach((p) => {
                  if (player.id === p.playerId) {
                    i.team.point += p.detail.points
                    if (p.nowPlaying) {
                      i.position = p.position
                      i.player = p.detail
                      i.player.name = player.name
                      i.player.displayName = player.displayName || player.name
                      data.push(i)
                    }
                  }
                })
              })
            }
          })
        })
      }

      return data
    },
    nowDealer: function() {
      var dealer = ''
      switch (this.liveSetting.status.wind) {
        case '1':
        case 'e':
        case 1:
          dealer = 'e'
          break
        case '2':
        case 's':
        case 2:
          dealer = 's'
          break
        case '3':
        case 'w':
        case 3:
          dealer = 'w'
          break
        case '4':
        case 'n':
        case 4:
          dealer = 'n'
          break
      }
      return dealer
    }
  },
  mounted() {
    this.$bus.$on('liveSetting', content => {
      this.liveSetting = content
      this.getData()
    })
  },
  methods: {
    windToCount: function(wind) {
      var data = ''
      switch (wind) {
        case 'e':
          data = '1'
          break
        case 's':
          data = '2'
          break
        case 'w':
          data = '3'
          break
        case 'n':
          data = '4'
          break
      }
      return data
    },
    makeIcon: function(team) {
      var str = (team.icon.indexOf('fill="none"') !== -1) ? team.icon.replace('fill="none"', 'fill="' + team.color.replace(' ', '') + '"') : team.icon
      str += '<span>' + team.displayName + '</span>'
      str = (str.indexOf('fill="none"') !== -1) ? str.replace('fill="none"', 'fill="' + team.color.replace(' ', '') + '"') : str
      return str
    },
    getData: function() {
      this.$store.commit('CLOSE_LOADING')
      this.$store.dispatch('getData', { type: 'Season', credentials: this.liveSetting.seasonId }).then((res) => {
        if (!res.errorCode) {
          this.teams = res.data.teams
          this.$dataManager.getTeamByLiveShow(this.liveSetting.liveShowId).then((res) => {
            this.detail = res.data
            this.isLoading = true
          })
        }
      })
    }
  }

}
</script>

<style lang="scss">
@import '../../../style/show/todayPlayerPoint';
</style>
