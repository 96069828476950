import Vue from 'vue'
import VueRouter from 'vue-router'

import Root from '@/components/Root'
import Login from '@/components/Login'
import Dashboard from '@/components/Dashboard/Dashboard'

import createRoute from './create'
import listRoute from './list'
import updateRoute from './update'
import DetailRoute from './detail'
import showRoute from './show'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  ...listRoute,
  ...createRoute,
  ...updateRoute,
  ...showRoute,
  ...DetailRoute,
  {
    path: '/app/dashboard',
    component: Root,
    children: [
      {
        path: '',
        name: 'Dashboard',
        component: Dashboard
      }
    ]
  }
]

const router = new VueRouter({

  routes
})

export default router
