<template>
  <div class="card card-user">
    <div class="card-body">
      <div v-for="(item,i) in urls" :key="i" class="form-group row">
        <label class="col-md-2 col-form-label" v-text="item.name" />
        <div class="col-md-10">
          <input :id="i" v-model="item.url" class="form-control" type="text" @click="copy(item,i)">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlayerList',
  data() {
    return {
      id: this.$route.params.id,
      seasonId: this.$route.params.seasonId
    }
  },
  computed: {
    urls() {
      var data = {
        PlayerSeasonPointHistory: {
          name: 'Daily用 當月&上月積分折線圖',
          url: document.domain + '/#/show/player/PlayerDailyPointHistory/' + this.seasonId + '/' + this.id
        },
        PlayerSeasonPoint: {
          name: '當季積分折線圖',
          url: document.domain + '/#/show/player/PlayerSeasonPointHistory/' + this.seasonId + '/' + this.id
        },
        PlayerSeasonDetail: {
          name: '當季積分數據',
          url: document.domain + '/#/show/player/PlayerSeasonDetail/' + this.seasonId + '/' + this.id
        }
      }
      return data
    }
  },
  mounted() {
    this.$store.commit('CLOSE_LOADING')
    this.$store.commit('CLEAR_DIALOG')
  },
  methods: {
    copy(url, i) {
      const testingCodeToCopy = document.querySelector('#' + i)
      testingCodeToCopy.setAttribute('type', 'text') // 不是 hidden 才能複製
      testingCodeToCopy.select()

      document.execCommand('copy')

      /* unselect the range */
      window.getSelection().removeAllRanges()
    }
  }
}
</script>

<style scoped>

</style>
